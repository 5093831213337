import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Card,
  CardContent,
  Checkbox,
  Grid,
  Typography,
  Button,
  Divider,
  Avatar,
  IconButton,
  Box,
  TextField,
  Toolbar,
  Autocomplete,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
// import {
//   FormatBold,
//   FormatItalic,
//   FormatUnderlined,
//   FormatAlignLeft,
//   FormatAlignCenter,
//   FormatAlignRight,
//   FormatListBulleted,
//   FormatListNumbered,
// } from "@mui/icons-material";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import {
  createConversation,
  createMessage,
  deleteMessage,
  fetchAllMessages,
  fetchNewEndUsers,
} from "./messagesApiService";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import AddIcon from "@mui/icons-material/Add";
import { getWSService } from "./services/WebSocket";
import { format } from "date-fns";
import { useAuth } from "../../../AuthContext";

interface Props {
  conversationId: string;
  conversations: any;
  handleUserClick: (conversationId: any) => void;
  groupName: string;
}

const ButtonWrapper = styled("div")`
  display: flex;
  justify-content: flex-end;
`;

const DeleteButton = styled(IconButton)``;

// const token = localStorage.getItem("accessToken");
// const { userId: loggedInUserId } = decodeToken(token!);

export const ChatClient = (props: Props) => {
  const { conversationId } = props;

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  interface Message {
    content: string;
    contentType: string;
    conversation: string;
  }

  const { user } = useAuth();
  const loggedInUserId = user?.userId;

  // State variables to manage chat messages
  const [messageList, updateMessageList] = useState<Message[]>([]);
  const [isNewMessage, setIsNewMessage] = useState(false);
  // const [isBold, setIsBold] = useState(false);
  // const [isItalic, setIsItalic] = useState(false);
  // const [isUnderlined, setIsUnderlined] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<any[]>([]);
  //const [lastUpdatedMessage, setLastUpdatedMessage] = useState("");
  const [endUsers, setEndUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [currentMessage, setCurrentMessage] = useState<any>({
    groupName: "",
    content: "",
    contentType: "",
    conversation: "",
    userId: "",
  });
  const [isChatHovered, setIsChatHovered] = useState(false);
  const [chatHoveredId, setIsChatHoveredId] = useState("");
  const [error, setError] = useState({
    groupFlag: false,
    groupMessage: "",
    participantFlag: false,
    participantMessage: "",
    contentFlag: false,
    contentMessage: "",
  });

  // const conversations = props?.conversations?.map((x: any) => {
  //   let groupName: any;
  //   if (x?.groupName?.includes("@")) {
  //     groupName = x?.groupName?.split("@");
  //     groupName = groupName?.[0];

  //     return {
  //       ...x,
  //       groupName: `${formatDate(groupName, "DD-MM-YYYY")} @ ${formatDate(
  //         groupName,
  //         "HH:mm"
  //       )}`,
  //     };
  //   } else {
  //     return {
  //       ...x,
  //       groupName: x?.groupName,
  //     };
  //   }
  // });

  const conversations = props?.conversations;

  // const [conversations, setConversations] = useState(props.conversations);
  const [filteredConversations, setFilteredConversations] =
    useState(conversations);

  const lastMessageRef: any = useRef(null);
  const containerRef: any = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  // styles for the elements
  const brownButtonStyle = {
    backgroundColor: "#333333",
    color: "white",
    borderRadius: 20,
    padding: "10px 20px",
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const apiClient = useCallback(
    (currentPage: any) => {
      dispatch(
        fetchAllMessages(
          {
            pageSize: 10,
            page: currentPage || 1,
            sort: "timestamp|DESC",
            conversation: conversationId,
            contentType: "contentType|text",
          },
          (data: any) => {
            setTotalPageCount(data.totalCount);
            const chatRecords =
              data?.data?.map((message: any) => {
                return {
                  _id: message._id,
                  user: message.user,
                  content: message.content,
                  contentType: message.contentType,
                  conversation: message.conversation,
                  messageId: message._id,
                  isDeleted: message.isDeleted,
                  timestamp: message.timestamp,
                };
              }) || [];

            const updatedList = messageList.concat(chatRecords);

            updateMessageList(updatedList);
          }
        )
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [dispatch]
  );

  const userApiClient = useCallback(() => {
    dispatch(
      fetchNewEndUsers(
        {
          pageSize: 10000,
          page: 1,
          limit: 10,
          sort: "createdAt|DESC",
          status: "status|active",
          userType: "userType|customer",
        },
        (data: any) => {
          const userNameList = data.map((user: any) => {
            return {
              label: `${user?.firstName} ${user?.lastName}`,
              value: user?._id,
              profilePic: user?.profilePic,
            };
          });

          setEndUsers(userNameList);
        }
      )
    );
  }, [dispatch]);

  useEffect(() => {
    if (conversationId) {
      apiClient(currentPage);
      //Add message listener for this chat room
      getWSService(conversationId).addMessageListener(
        conversationId,
        "all",
        chatMessageHandler
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationId]);

  // Function to handle toggling formatting options
  // const handleToggleFormat = (format: string) => {
  //   switch (format) {
  //     case "bold":
  //       setIsBold(!isBold);
  //       break;
  //     case "italic":
  //       setIsItalic(!isItalic);
  //       break;
  //     case "underline":
  //       setIsUnderlined(!isUnderlined);
  //       break;
  //     default:
  //       break;
  //   }
  // };

  const handleAutocompleteChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: any,
    reason: string
  ) => {
    setSelectedUsers(value);
    setError({
      ...error,
      participantFlag: false,
      participantMessage: "",
    });
  };

  const chatMessageHandler = (message: any) => {
    //setLastUpdatedMessage(message._id);
    apiClient(currentPage);

    let updatedList = [...messageList];
    updatedList.push(message);

    updateMessageList(updatedList);
  };

  const handleSendMessage = (e: any) => {
    e.preventDefault();

    let conversationData = {};

    if (isNewMessage) {
      if (selectedUsers.length === 0) {
        setError({
          ...error,
          participantFlag: true,
          participantMessage: "Please select a participant.",
        });
        return false;
      } else {
        setError({
          ...error,
          participantFlag: false,
          participantMessage: "",
        });
      }

      if (!currentMessage.groupName) {
        setError({
          ...error,
          groupFlag: true,
          groupMessage: "Please select a group name.",
        });
        return false;
      } else {
        setError({
          ...error,
          groupFlag: false,
          groupMessage: "",
        });
      }

      if (!currentMessage.content || currentMessage.content.trim() === "") {
        setError({
          ...error,
          contentFlag: true,
          contentMessage: "Please insert a content message",
        });
        return false;
      } else {
        setError({
          ...error,
          contentFlag: false,
          contentMessage: "",
        });
      }

      // Create a new conversation
      const conversationParticipants = selectedUsers.map((user: any) => {
        return {
          user: user.value,
          unreadMsgCount: 0,
        };
      });

      const participantsData = conversationParticipants.concat({
        user: loggedInUserId,
        unreadMsgCount: 1,
      });

      const conversationData = {
        groupName:
          currentMessage.groupName ||
          selectedUsers.map((user: any) => user.label).join(", "),
        participants: participantsData?.filter((x: any) => x?.user),
        lastSentMsg: {
          content: currentMessage.content,
          contentType: currentMessage.contentType || "text",
        },
        isPrivate: participantsData.length === 2 ? true : false,
      };

      dispatch(
        createConversation(conversationData, (data: any) => {
          window.location.href = "/messages";
        })
      );
    } else {
      conversationData = {
        content: currentMessage.content,
        contentType: currentMessage.contentType || "text",
        conversation: conversationId,
      };
      dispatch(
        createMessage(conversationData, (data: any) => {
          //apiClient(1);
        })
      );
    }

    // Clear the message input field after sending the message
    setCurrentMessage({
      ...currentMessage,
      content: "",
      contentType: "",
      conversation: "",
    });
  };

  const handleGroupInputChange = (event: React.FormEvent<EventTarget>) => {
    let eventTarget = event.target as HTMLInputElement;
    let updatedMsg = { ...currentMessage };
    updatedMsg.groupName = eventTarget.value;
    setCurrentMessage(updatedMsg);
  };

  const handleInputChange = (event: React.FormEvent<EventTarget>) => {
    let eventTarget = event.target as HTMLInputElement;
    let updatedMsg = { ...currentMessage };
    updatedMsg.content = eventTarget.value;
    setCurrentMessage(updatedMsg);
  };

  const handleChatMouseEnter = (e: any) => {
    setIsChatHovered(false);
    setIsChatHoveredId(e.target.id);
  };

  const handleChatMouseLeave = () => {
    setIsChatHovered(true);
  };

  const handleChatDelete = () => {
    dispatch(
      deleteMessage({ id: chatHoveredId }, (data: any) => {
        apiClient(currentPage);
      })
    );
  };

  // const scrollToLastMessage = () => {
  //   if (lastMessageRef.current) {
  //     lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
  //   }

  //   setLastUpdatedMessage('');
  // };

  const handleScroll = () => {
    if (Math.abs(containerRef?.current.scrollTop) > 1100 && !isLoading) {
      setCurrentPage((prevPage) => prevPage + 1);
      // Load more items when scrolled to the top
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);

      if (Math.ceil(totalPageCount / 10) <= currentPage) {
        apiClient(currentPage);
      }
    }
  };

  const contentLanChange = (content: string) => {
    if (
      content &&
      content?.includes("joined the conversation") &&
      i18n.language !== "en"
    ) {
      return content?.replace(
        "joined the conversation",
        t("JoinedTheConversation")
      );
    } else return content;
  };

  return (
    <>
      <Grid item xs={12} sm={6}>
        <Typography
          style={{
            fontWeight: 600,
            color: "#45464E",
            fontSize: "26px",
          }}
        >
          {t("messages")}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} justifyContent={"flex-end"}>
        <ButtonWrapper>
          <Button
            variant="contained"
            sx={brownButtonStyle}
            startIcon={<AddIcon />}
            onClick={() => {
              setIsNewMessage(true);
              updateMessageList([]);
              userApiClient();
            }}
            style={{ marginBottom: "14px" }}
          >
            {t("addNewMessage")}
          </Button>
        </ButtonWrapper>
      </Grid>
      <Card style={{ width: "100%" }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              {/* Left panel - User list and search bar */}
              <TextField
                fullWidth
                variant="standard"
                margin="normal"
                label={t("SearchByConversation")}
                onChange={(event) => {
                  const searchValue = event.target.value;
                  if (searchValue) {
                    const filteredConversations = conversations.filter(
                      (conversation: any) => {
                        return conversation.groupName
                          .toLowerCase()
                          .includes(searchValue.toLowerCase());
                      }
                    );
                    setFilteredConversations(filteredConversations);
                  } else {
                    setFilteredConversations(conversations);
                  }
                }}
              />
              <List
                sx={{
                  maxHeight: "calc(100vh - 300px)",
                  overflow: "scroll",
                }}
              >
                {filteredConversations?.map(
                  (conversation: any, key: number) => (
                    <ListItem
                      button
                      key={`${conversation._id}-${key}`}
                      onClick={() => props.handleUserClick(conversation)}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.backgroundColor =
                          props.groupName === conversation.groupName
                            ? "black"
                            : "#e0e0e0")
                      }
                      onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundColor =
                          props.groupName === conversation.groupName
                            ? "black"
                            : "white";
                        e.currentTarget.style.color =
                          props.groupName === conversation.groupName
                            ? "white"
                            : "black";
                      }}
                      style={{
                        backgroundColor:
                          props.groupName === conversation.groupName
                            ? "black"
                            : "white",
                        color:
                          props.groupName === conversation.groupName
                            ? "white"
                            : "black",
                        padding: "20px",
                      }}
                    >
                      <Avatar
                        src={conversation._id}
                        style={{ marginRight: "8px" }}
                      />
                      <ListItemText
                        primary={
                          <Typography variant="body2" fontWeight="bold">
                            {conversation?.groupName}
                          </Typography>
                        }
                      />{" "}
                    </ListItem>
                  )
                )}
              </List>
            </Grid>
            <Grid item xs={12} md={9}>
              {/* Right panel - New user chat content */}
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={11}>
                  <Typography variant="h6" fontWeight="bold">
                    {isNewMessage ? (
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        label="Enter your group name here"
                        multiline
                        fullWidth
                        value={currentMessage.groupName}
                        onChange={(event) => handleGroupInputChange(event)}
                        className="msg-box"
                        margin="normal"
                        style={{
                          border: "none",
                          outline: "none",
                          width: "900px",
                        }}
                        error={!!error.groupFlag}
                        helperText={error.groupMessage}
                      />
                    ) : (
                      `${props.groupName}`
                    )}
                  </Typography>
                </Grid>
              </Grid>
              {/* <Divider style={{ marginTop: "5px" }} /> */}
              {isNewMessage && (
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  style={{ marginTop: "5px" }}
                >
                  <Grid item xs={1}>
                    {/* Label for "To" */}
                    <Typography>To:</Typography>
                  </Grid>
                  <Grid item xs={11}>
                    {/* Autocomplete for selecting users */}
                    <Autocomplete
                      multiple
                      fullWidth
                      id="tags-standard"
                      options={endUsers}
                      disableCloseOnSelect
                      onChange={handleAutocompleteChange}
                      getOptionLabel={(option: any) => option.label}
                      renderOption={(
                        props,
                        option,
                        { selected, index: optionIndex }
                      ) => (
                        <li {...props} key={optionIndex}>
                          {" "}
                          <Box display="flex" alignItems="center">
                            {/* Display the user's profile picture in the Autocomplete dropdown */}
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            <Avatar
                              src={option.profilePic}
                              style={{ marginRight: "8px" }}
                            />
                            <Typography variant="subtitle1">
                              {option.label}
                            </Typography>
                          </Box>
                        </li>
                      )}
                      style={{ width: 500 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          placeholder="Select Users"
                          error={!!error.participantFlag}
                          helperText={error.participantMessage}
                          InputProps={{
                            ...params.InputProps,
                            style: {
                              border: "none",
                              outline: "none",
                              top: "2px",
                              width: "800px",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              )}
              <Divider style={{ marginTop: "20px" }} />
              {isLoading && <div>Loading...</div>}
              {/* Chat history content area */}
              <div
                ref={containerRef}
                style={{
                  height: "55vh",
                  overflowY: "auto",
                  padding: "40px",
                  display: "flex",
                  flexDirection: "column-reverse",
                }}
                onScroll={handleScroll}
              >
                {/* Display chat history here */}
                {messageList.map((message: any) => (
                  <div
                    key={message._id}
                    style={{
                      display: "flex",
                      flexDirection:
                        message.user?._id === loggedInUserId
                          ? "row-reverse"
                          : "row",
                      marginBottom: "30px",
                    }}
                  >
                    <div
                      id={message._id}
                      style={{
                        backgroundColor:
                          message.user?._id === loggedInUserId
                            ? "#007bff"
                            : "#e5e5ea",
                        color:
                          message.user?._id === loggedInUserId
                            ? "#fff"
                            : "#333",
                        padding: "10px",
                        borderRadius: "10px",
                        minWidth: "20%",
                        maxWidth: "40%",
                        wordWrap: "break-word",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        position: "relative",
                        flex: "0 1 auto",
                      }}
                      onMouseEnter={handleChatMouseEnter}
                      onMouseLeave={handleChatMouseLeave}
                    >
                      <div
                        style={{
                          position: "absolute",
                          top: "-35px",
                          right: "-15px",
                        }}
                      >
                        {!isChatHovered &&
                          chatHoveredId === message._id &&
                          message.user?._id === loggedInUserId && (
                            <DeleteButton onClick={handleChatDelete}>
                              <DeleteIcon />
                            </DeleteButton>
                          )}
                      </div>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Avatar
                            src={message?.user?.profilePic}
                            alt="Profile Picture"
                            style={{}}
                          />
                          <p
                            style={{
                              margin: "0",
                              fontWeight: "bold",
                              padding: "10px 10px",
                            }}
                          >
                            {message?.user?.firstName}
                          </p>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                          }}
                        >
                          <p
                            style={{
                              margin: "0",
                              fontSize: "16px",
                              color:
                                message?.user?._id === loggedInUserId
                                  ? "#fff"
                                  : "#666",
                              padding: "20px 30px",
                            }}
                          >
                            {/* {message?.content} */}
                            {contentLanChange(message?.content)}
                          </p>
                        </div>
                      </div>
                      <p
                        style={{
                          margin: "0",
                          fontSize: "10px",
                          color:
                            message.user?._id === loggedInUserId
                              ? "#99c2ff"
                              : "#999",
                          position: "absolute",
                          bottom: "10px",
                          right: "8px",
                        }}
                      >
                        {format(
                          new Date(message.timestamp),
                          "dd-MM-yyyy HH:mm"
                        )}
                      </p>
                    </div>
                  </div>
                ))}
                <div ref={lastMessageRef} />
              </div>
              <Divider />
              {/* Conversation / Message submission */}
              <form onSubmit={handleSendMessage} style={{ marginTop: "5px" }}>
                <TextField
                  id="standard-multiline-flexible"
                  label={t("EnterYourMessageHere")}
                  multiline
                  fullWidth
                  value={currentMessage.content}
                  onChange={(event) => handleInputChange(event)}
                  className="msg-box"
                  margin="normal"
                  error={!!error.contentFlag}
                  helperText={error.contentMessage}
                />
                <Divider />
                <Toolbar
                  style={{
                    display: "flex",
                    paddingLeft: "0px",
                    justifyContent: "flex-end",
                  }}
                >
                  {/* Text formatting and alignment icons */}
                  {/* <div>
                    <IconButton
                      onClick={() => handleToggleFormat("bold")}
                      color={isBold ? "primary" : "default"}
                    >
                      <FormatBold />
                    </IconButton>
                    <IconButton
                      onClick={() => handleToggleFormat("italic")}
                      color={isItalic ? "primary" : "default"}
                    >
                      <FormatItalic />
                    </IconButton>
                    <IconButton
                      onClick={() => handleToggleFormat("underline")}
                      color={isUnderlined ? "primary" : "default"}
                    >
                      <FormatUnderlined />
                    </IconButton>
                    <IconButton>
                      <FormatAlignLeft />
                    </IconButton>
                    <IconButton>
                      <FormatAlignCenter />
                    </IconButton>
                    <IconButton>
                      <FormatAlignRight />
                    </IconButton>
                    <IconButton>
                      <FormatListBulleted />
                    </IconButton>
                    <IconButton>
                      <FormatListNumbered />
                    </IconButton>
                  </div> */}
                  <div className="flex padding-right:10px">
                    {/* {lastUpdatedMessage.length > 0 && <Chip label="Show new message ⬇️" color='success' onClick={scrollToLastMessage} />} */}
                    {/* Send button */}
                    <span style={{ padding: 10 }}></span>
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      style={brownButtonStyle}
                      endIcon={<SendIcon />}
                    >
                      {t("send")}
                    </Button>
                  </div>
                </Toolbar>
              </form>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};
