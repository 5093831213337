/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';
import { forEach, minBy, groupBy, map } from 'lodash';
import {
	Accordion,
	AccordionDetails,
	Modal,
	Box,
	Button,
	Divider,
	Paper,
	Slide,
	Grid,
	Typography,
	IconButton,
	FormControl,
	FormHelperText,
	AccordionSummary,
	Select,
	MenuItem,
} from '@mui/material';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import 'dayjs/locale/es'; // Import the Spanish locale
import 'dayjs/locale/fr'; // Import the French locale
import 'dayjs/locale/en'; // Import the English locale

import { fetchSearchUsers } from 'app/entities/Users/usersApiService';
import { ClubsMultiSelect, AutoCompleteMultiSelect } from 'app/shared/molecules';
import { FormTextField, FormSelectField } from 'app/shared/atoms';
import { showToast } from 'app/shared/util/toastHelper';
import ConfirmDialog from 'app/shared/components/ConfirmDialog';

import { Form, Formik, FieldArray, FormikProps } from 'formik';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {
	couponAppliedAfterCalculateTotal,
	changeSizeAndVariant,
	calculateTotal,
	changeQuantity,
	calculateSubtotalTax,
} from './AddUserPurchaseMethods';
import PaginatedAutocomplete from 'app/shared/molecules/PaginatedAutocomplete';

import { useAuth } from '../../../../AuthContext';
import { AppDispatch } from '../../../redux/store';
import { fetchEndUsers } from 'app/entities/Users/usersApiService';
import { defaultExtraProductPurchaseValue } from '../../../model/extra-model';
import { AddUserPurchaseValidationSchema } from 'app/shared/validations/ExtraValidationSchema';
import {
	createApparelPurchase,
	updateApparelPurchase,
	fetchApparelPurchaseList,
} from '../userPurchaseApiService';
import { fetchExtraProductsList } from 'app/entities/Extras/extrasApiService';
import { verifyCoupon } from 'app/entities/Coupons/couponsApiService';
import COUNTRY_CODES from 'app/shared/data/CountryCodes.json';
import { BookingPaymentType } from 'app/config/constants';
import moment from 'moment';
import { SubmitButton } from 'app/shared/molecules';

interface ModalFormProps {
	open: boolean;
	onClose: () => void;
	type?: any;
	action?: any;
	formData?: any;
	page?: any;
	refreshClubsGrid?: any;
	refresh?: any;
	setRefresh?: any;
	callback?: any;
}
interface User {
	_id: string;
	firstName: string;
	lastName: string;
	profilePic: string;
	email: string;
	phone: string;
	countryCode: number;
	isProfileComplete: boolean;
	role: string;
	userType: string;
	status: string;
	type: string;
	playerLevel: any;
}

const BoldTypography = styled(Typography)(({ theme }) => ({
	fontWeight: 'bold', // Set the font weight to bold
}));
const currentDate = moment();

const AddUserPurchaseModalForm: React.FC<ModalFormProps> = ({
	open,
	onClose,
	formData,
	callback,
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch<AppDispatch>();
	const formikRef = useRef<FormikProps<any>>(null);
	const [confirmOpen, setConfirmOpen] = useState(false);

	const { user } = useAuth();
	const [editData, setEditData] = useState<any>();
	const [showSizeOption, setSizeOption] = useState<any>();
	const [productsList, setProductsList] = useState([]);
	const [selectedUser, setSelectedUser] = useState<User | null>(null);
	const [showAddUserFields, setShowAddUserFields] = useState(false);
	const [appliedCoupon, setAppliedCoupon] = useState<any>();
	const [datePickerOrderOpen, setDatePickerOrderOpen] = useState(false);

	useEffect(() => {
		const fetchUsersData = async () => {
			try {
				await fetchEndUsers(0, 1000, true); // Call the data fetching function
			} catch (error) {}
		};
		fetchUsersData();
	}, []);

	// Set all the form data used for building the request object to the API finally
	useEffect(() => {
		if (formData?._id) {
			const checkShowOption =
				formData?.variants?.[0]?.size !== undefined || formData?.variants?.[0]?.size !== '';
			setSizeOption(checkShowOption);
			setSelectedUser(formData?.user);

			dispatch(
				fetchExtraProductsList(
					{
						filters: `status|active`,
						pageSize: 1000,
						page: 1,
						clubs: [formData?.club?._id],
						filter_type: 'extras',
						filter_status: 'active',
					},
					(response: any) => {
						setProductsList(response || []);

						const formattedData = map(
							groupBy(formData?.orderDetails, 'product'),
							(group: any) => ({
								name: group[0]?.name,
								_id: group[0]?.product,
								orderList: group?.map((x: any) => ({ ...x })),
							})
						);

						let newListedData: any = [];

						formattedData?.forEach((element: any) => {
							let getProductDetail = response?.find(
								(x: any) => x?._id === element?._id
							);
							newListedData.push({
								...element,
								...getProductDetail,
								isMultipleVariantsAvailable: element?.variants?.[0]?.size !== '',
							});
						});

						let CResult = calculateSubtotalTax(
							{ ...formData, orderDetails: newListedData },
							appliedCoupon
						);
						setEditData({
							...formData,
							products: formData?.orderDetails?.map((x: any) => x?.product),
							club: formData?.club?._id,
							user: formData?.user?._id,
							paymentType: formData?.paymentType,
							showSizeOption: checkShowOption,
							orderDetails: newListedData,
							totalOfSubTotalAmount: formData?.totalOfSubTotalAmount || 0,
							totalPayableAmount: formData?.totalPayableAmount || 0,
							totalTaxAmount: formData?.totalTaxAmount || 0,
							actualTotalOfSubTotalAmount: CResult?.actualTotalOfSubTotalAmount || 0,
							actualTotalPayableAmount: CResult?.actualTotalPayableAmount || 0,
							actualTotalTaxAmount: CResult?.actualTotalTaxAmount || 0,
							...(formData?.coupon && {
								finalTotalPayableAmount:
									formData?.totalPayableAmount - formData?.totalTaxAmount,
							}),
						});
					}
				)
			);
		}
	}, [formData]);

	/**
	 * Handle Next on button click to load the next step if second page call the api
	 *
	 * @function
	 * @returns void
	 */
	const handleSubmit = async (values?: any, actions?: any) => {
		try {
			if (formData?._id) {
				delete values._id;
				const response = await dispatch(
					updateApparelPurchase(formData?._id, { ...values })
				);

				if (response) {
					if (typeof callback === 'function') {
						callback();
					}
					onClose();
				}
			} else {
				const response = await dispatch(createApparelPurchase({ ...values }));
				if (response) {
					onClose();
					dispatch(fetchApparelPurchaseList({ page: 1 }));
				}
			}
		} catch (error) {
			console.error('Error during submission:', error);
		} finally {
			actions.setSubmitting(false);
		}
	};

	/**
	 * Handle close on button click
	 *
	 * @function
	 * @returns void
	 */
	const handleCloseModal = (event?: any, reason?: any) => {
		if (formikRef?.current?.dirty) {
			// Show confirmation dialog if there are unsaved changes
			setConfirmOpen(true);
		} else {
			onClose();
		}
	};

	// Handle close confirmation dialog
	const handleCancelClose = () => {
		setConfirmOpen(false);
	};

	// Handle confirm close action
	const handleConfirmClose = () => {
		setConfirmOpen(false);
		onClose();
	};

	const handleAddUserClick = () => {
		setShowAddUserFields(true);
	};

	const fetchUserOptions = async (inputValue: string, page: number) => {
		try {
			// Fetch the options from the API based on inputValue and page number
			const response: any = await fetchSearchUsers(inputValue, page, true);

			// Map the response data to include the correct `id` field
			const mappedData = response?.data?.map((x: any) => ({
				...x,
				id: x?.user?._id || x?._id,
			}));

			// Return the updated response or an empty array if data is not available
			return {
				...response,
				data: mappedData || [],
			};
		} catch (error) {
			console.error('Error fetching user options:', error);
			// Return an empty response object if an error occurs
			return { data: [] };
		}
	};

	return (
		<>
			<Modal
				open={open}
				onClose={handleCloseModal}
				closeAfterTransition
				slotProps={{
					backdrop: {
						style: {
							backgroundColor: 'rgba(0,0,0,0.5)',
						},
					},
				}}
			>
				<Slide in={open} direction="left">
					<Paper
						style={{
							position: 'absolute',
							right: '0',
							transform: 'translateY(-50%)',
							width: '35%',
							padding: '25px',
							height: '100vh',
							overflow: 'auto',
						}}
					>
						<Box>
							{/* Content of the sliding modal */}
							<BoldTypography variant="h6" style={{ marginBottom: '1rem' }}>
								{formData?._id ? t('editNewPurchase') : t('addNewPurchase')}
							</BoldTypography>
							<Divider />
							<IconButton
								edge="end"
								color="inherit"
								onClick={handleCloseModal}
								aria-label="close"
								sx={{
									position: 'absolute',
									top: '15px',
									right: '24px',
									cursor: 'pointer',
								}}
							>
								<CloseIcon />
							</IconButton>
							<div>
								<Formik
									innerRef={formikRef}
									initialValues={
										formData?._id
											? editData
											: {
													...defaultExtraProductPurchaseValue,
													orderDate: currentDate,
											  }
									}
									onSubmit={(values, actions) => {
										if (
											values?.coupon &&
											(values?.finalTotalPayableAmount === null ||
												values?.finalTotalPayableAmount === undefined)
										) {
											showToast('Please click apply coupon', 'Error', {
												position: 'top-right',
											});
										} else {
											const newVariants: any = [];
											const couponManipulateOrderDetails: any = [];
											const manipulateOrderDetails: any = [];

											forEach(values?.orderDetails, (element: any) => {
												if (element?.orderList) {
													forEach(element.orderList, (element1: any) => {
														let newData = {
															...element1,
															product: element?._id,
															quantity: parseInt(element1?.quantity),
															taxPercentage:
																element1?.taxPercentage || 0,
														};
														if (
															!element?.isMultipleVariantsAvailable &&
															!element1?.variant
														) {
															newData.variant =
																element?.variants?.[0]?._id;
															newData.name = element?.name;
														}
														manipulateOrderDetails.push(newData);
													});
												}
											});

											const productPriceToShow = minBy(
												newVariants,
												(item: any) => item?.unitPrice
											)?.unitPrice;

											//let result;
											//Coupon applied calculation
											if (values?.coupon) {
												forEach(
													values?.couponOrderDetails,
													(element: any) => {
														if (element?.orderList) {
															forEach(
																element.orderList,
																(element1: any) => {
																	let newData = {
																		...element1,
																		product: element?._id,
																		quantity: parseInt(
																			element1?.quantity
																		),
																		taxPercentage:
																			element1?.taxPercentage ||
																			0,
																	};
																	if (
																		!element?.isMultipleVariantsAvailable &&
																		!element1?.variant
																	) {
																		newData.variant =
																			element?.variants?.[0]?._id;
																	}
																	couponManipulateOrderDetails.push(
																		newData
																	);
																}
															);
														}
													}
												);
											}

											let manipulateData:any = {
												...(values?.coupon && { coupon: values?.coupon }),
												orderDate: values?.orderDate,
												user: values?.user,
												club: values?.club,
												productPriceToShow,
												orderDetails: values?.coupon
													? manipulateOrderDetails
													: manipulateOrderDetails,
												totalOfSubTotalAmount:
													values?.actualTotalOfSubTotalAmount,
												totalTaxAmount: values?.totalTaxAmount,
												totalPayableAmount: values?.totalPayableAmount,
												paymentType: values?.paymentType,
											};

											if(showAddUserFields && manipulateData?.firstName) {
												manipulateData = {
													...manipulateData,
													firstName: manipulateData?.firstName?.trim(),
													lastName: manipulateData?.lastName?.trim(),
													email: manipulateData?.email?.trim()
												}
											}

											handleSubmit(
												{
													...manipulateData,
													createdBy: user?.userId,
												},
												actions
											);
										}
									}}
									enableReinitialize
									validationSchema={AddUserPurchaseValidationSchema(
										showSizeOption
									)}
								>
									{({
										values,
										errors,
										setFieldValue,
										touched,
										submitCount,
										isSubmitting,
									}: any) => {
										let SubTotalPayableAmount: any =
											calculateTotal(values) || 0;
										let CResult = calculateSubtotalTax(values, appliedCoupon);

										if (!values?._id && SubTotalPayableAmount && CResult) {
											/* Actual value setting start */

											values.actualTotalOfSubTotalAmount =
												SubTotalPayableAmount || 0;

											values.actualTotalTaxAmount =
												CResult?.actualTotalTaxAmount || 0;

											values.actualTotalPayableAmount =
												CResult?.actualTotalPayableAmount || 0;
											/* Actual value setting start */

											values.totalOfSubTotalAmount =
												CResult?.totalOfSubTotalAmount || 0;

											values.totalTaxAmount = CResult?.totalTaxAmount || 0;

											values.totalPayableAmount =
												CResult?.totalPayableAmount || 0;
										}

										return (
											<>
												<Form>
													<Grid
														container
														spacing={2}
														style={{ marginTop: '10px' }}
													>
														<Grid item xs={12}>
															<Typography
																variant="body2"
																gutterBottom
																className="uppercase label"
															>
																{t('date')}
															</Typography>

															<LocalizationProvider
																dateAdapter={AdapterDayjs}
																adapterLocale={
																	localStorage.getItem(
																		'language'
																	) || 'en'
																}
															>
																<DateTimePicker
																	//timezone="UTC"
																	value={
																		values?.orderDate
																			? dayjs(
																					values?.orderDate
																			  )
																			: null
																	}
																	onChange={(
																		newValue: any,
																		neww: any
																	) => {
																		setFieldValue(
																			'orderDate',
																			newValue.toISOString()
																		);
																	}}
																	//format="DD/MM/YYYY"
																	format="DD/MM/YYYY HH:mm"
																	ampm={false}
																	sx={{ width: '100%' }}
																	open={datePickerOrderOpen}
																	onOpen={() =>
																		setDatePickerOrderOpen(true)
																	}
																	onClose={() =>
																		setDatePickerOrderOpen(
																			false
																		)
																	}
																	slotProps={{
																		textField: {
																			onClick: () =>
																				setDatePickerOrderOpen(
																					true
																				),
																		},
																	}}
																/>
															</LocalizationProvider>
															<FormHelperText>
																{touched?.orderDate &&
																errors?.orderDate ? (
																	<span
																		className="MuiFormHelperText-root Mui-error"
																		style={{ color: '#d32f2f' }}
																	>
																		{errors?.orderDate}
																	</span>
																) : (
																	''
																)}
															</FormHelperText>
														</Grid>

														<Grid item xs={12}>
															<ClubsMultiSelect
																label="Club"
																multiple={false}
																value={
																	values?.club ? values?.club : []
																} // Pass the value from Formik's state
																onChange={(
																	club: any,
																	newValue: any
																) => {
																	setFieldValue('club', club);

																	if (!club) {
																		setFieldValue(
																			'products',
																			[]
																		);
																	} else {
																		dispatch(
																			fetchExtraProductsList(
																				{
																					filters: `status|active`,
																					pageSize: 1000,
																					page: 1,
																					clubs: [club],
																					filter_type:
																						'extras',
																					filter_status:
																						'active',
																				},
																				(response: any) => {
																					setProductsList(
																						response ||
																							[]
																					);
																				}
																			)
																		);
																	}
																}}
															/>
															<FormHelperText>
																{submitCount &&
																touched?.club &&
																errors?.club ? (
																	<span
																		className="MuiFormHelperText-root Mui-error"
																		style={{ color: '#d32f2f' }}
																	>
																		{errors?.club}
																	</span>
																) : (
																	''
																)}
															</FormHelperText>
														</Grid>

														<Grid item xs={12}>
															<AutoCompleteMultiSelect
																apiName="products"
																apiCall={false}
																label={t('ProductsPurchased')}
																multiple={true}
																value={
																	values?.products
																		? values?.products
																		: []
																} // Pass the value from Formik's state
																onChange={(
																	products: any,
																	newValue: any
																) => {
																	setFieldValue(
																		'products',
																		products
																	);
																	setAppliedCoupon(null);
																	setFieldValue('coupon', '');

																	const newData = newValue?.map(
																		(x: any) => {
																			let isMultipleVariantsAvailable =
																				x?.variants?.[0]
																					?.size !== '';

																			const checkAlreadyExists: any =
																				values?.orderDetails?.find(
																					(y: any) =>
																						y?._id ===
																						x?._id
																				);

																			if (
																				checkAlreadyExists
																			) {
																				return {
																					...x,
																					isMultipleVariantsAvailable,
																					orderList:
																						checkAlreadyExists?.orderList,
																				};
																			} else {
																				return {
																					...x,
																					isMultipleVariantsAvailable,
																					expandAccordion:
																						true,
																					orderList: [
																						{
																							size: undefined,
																							unitPrice:
																								undefined,
																							availableUnits:
																								undefined,
																						},
																					],
																				};
																			}
																		}
																	);

																	setFieldValue(
																		'orderDetails',
																		newData
																	);
																}}
																disabled={!values?.club}
																options={productsList?.map(
																	(x: any) =>
																		(x = {
																			...x,
																			label: x?.name,
																			value: x?._id,
																		})
																)}
															/>
															<FormHelperText>
																{touched?.products &&
																errors?.products ? (
																	<span
																		className="MuiFormHelperText-root Mui-error"
																		style={{ color: '#d32f2f' }}
																	>
																		{errors?.products}
																	</span>
																) : (
																	''
																)}
															</FormHelperText>
														</Grid>

														<Grid item xs={12}>
															<FieldArray
																name="orderDetails"
																render={(arrayHelpers: any) => (
																	<div>
																		{values?.orderDetails?.map(
																			(
																				x: any,
																				index: number
																			) => {
																				let findAvailableUnitsLength =
																					x?.variants?.filter(
																						(
																							variant: any
																						) =>
																							variant?.club ===
																								values?.club &&
																							variant?.availableUnits
																					)?.length;

																				return (
																					<div
																						key={`variants-${index}-orderDetails-isChecked`}
																						style={{
																							marginBottom:
																								'1rem',
																							border:
																								submitCount &&
																								touched
																									?.orderDetails?.[
																									index
																								]
																									?.orderList &&
																								errors
																									?.orderDetails?.[
																									index
																								]
																									?.orderList
																									? '1px solid red'
																									: '1px solid #d0d4d7',
																						}}
																					>
																						<Accordion
																							elevation={
																								0
																							}
																							defaultExpanded={
																								x?.expandAccordion
																							}
																						>
																							<AccordionSummary>
																								<Grid
																									container
																									spacing={
																										2
																									}
																								>
																									<Grid
																										item
																										xs={
																											11
																										}
																									>
																										<Typography
																											sx={{
																												fontWeight: 600,
																												color:
																													submitCount &&
																													touched
																														?.orderDetails?.[
																														index
																													]
																														?.orderList &&
																													errors
																														?.orderDetails?.[
																														index
																													]
																														?.orderList
																														? 'red'
																														: '',
																											}}
																										>
																											{`${x?.name}`}{' '}
																											<Typography
																												component={
																													'span'
																												}
																												sx={{
																													color: 'red',
																													marginLeft:
																														'1rem',
																													fontWeight: 600,
																													fontSize:
																														'14px',
																												}}
																											>
																												{findAvailableUnitsLength ===
																												0
																													? 'Out of Stock'
																													: ''}
																											</Typography>
																										</Typography>
																									</Grid>

																									<Grid
																										item
																										xs={
																											1
																										}
																										style={{
																											justifyContent:
																												'flex-end',
																										}}
																									></Grid>
																								</Grid>
																							</AccordionSummary>

																							<AccordionDetails
																								sx={{
																									background:
																										findAvailableUnitsLength ===
																										0
																											? 'rgba(0, 0, 0, 0.08)'
																											: 'unset',
																								}}
																							>
																								<Grid
																									container
																									spacing={
																										2
																									}
																								>
																									{x
																										?.orderList
																										?.length >
																										0 && (
																										<Grid
																											item
																											xs={
																												12
																											}
																										>
																											<FieldArray
																												name={`orderDetails.${index}.orderList`}
																												render={(
																													sizeArrayHelpers: any
																												) => (
																													<div>
																														{values?.orderDetails?.[
																															index
																														]?.orderList?.map(
																															(
																																y: any,
																																sizeIndex: number
																															) => {
																																let selectedRowBasedAvailableUnitsVariantObject =
																																	x?.variants?.find(
																																		(
																																			variant: any
																																		) =>
																																			variant?.club ===
																																				values?.club &&
																																			values
																																				?.orderDetails?.[
																																				index
																																			]
																																				?.orderList?.[
																																				sizeIndex
																																			]
																																				?.variant ===
																																				variant?._id
																																	);

																																let variantBasedAvailableLength =
																																	selectedRowBasedAvailableUnitsVariantObject?.availableUnits;

																																return (
																																	<div
																																		key={`side-${sizeIndex}`}
																																	>
																																		<Grid
																																			container
																																			spacing={
																																				2
																																			}
																																			style={{
																																				marginLeft:
																																					'0px',
																																				marginBottom:
																																					'1rem',
																																			}}
																																		>
																																			{x
																																				?.variants
																																				?.length >
																																				0 &&
																																				x
																																					?.variants?.[0]
																																					?.size !==
																																					'' && (
																																					<Grid
																																						item
																																						xs={
																																							4
																																						}
																																					>
																																						<FormSelectField
																																							name={`orderDetails.${index}.orderList.${sizeIndex}.variant`}
																																							label={t(
																																								'availableSizes(OP)'
																																							)}
																																							value={
																																								values
																																									?.variants?.[
																																									index
																																								]
																																									?.availableSizes?.[
																																									sizeIndex
																																								]
																																									?.variant
																																							}
																																							options={x?.variants
																																								?.filter(
																																									(
																																										variant: any
																																									) =>
																																										variant?.club ===
																																										values?.club
																																								)
																																								.map(
																																									(
																																										variant: any
																																									) =>
																																										(variant =
																																											{
																																												label: `${
																																													variant?.size
																																												} (${
																																													variant?.availableUnits ||
																																													0
																																												})`,
																																												value: variant?._id,
																																											})
																																								)}
																																							onChangeCallback={(
																																								selectedVal: any
																																							) => {
																																								setFieldValue(
																																									`orderDetails.${index}.orderList.${sizeIndex}.quantity`,
																																									undefined
																																								);
																																								changeSizeAndVariant(
																																									selectedVal,
																																									x,
																																									index,
																																									sizeIndex,
																																									values,
																																									setFieldValue
																																								);
																																							}}
																																							disabled={
																																								findAvailableUnitsLength ===
																																								0
																																							}
																																						/>
																																					</Grid>
																																				)}
																																			<Grid
																																				item
																																				xs={
																																					3
																																				}
																																			>
																																				<FormTextField
																																					name={`orderDetails.${index}.orderList.${sizeIndex}.quantity`}
																																					label={t(
																																						'QTY'
																																					)}
																																					disabled={
																																						findAvailableUnitsLength ===
																																							0 ||
																																						variantBasedAvailableLength ===
																																							0
																																					}
																																					customCallback={(
																																						value
																																					) => {
																																						setFieldValue(
																																							`orderDetails.${index}.orderList.${sizeIndex}.quantity`,
																																							value
																																						);

																																						changeQuantity(
																																							value,
																																							x,
																																							index,
																																							sizeIndex,
																																							values,
																																							setFieldValue
																																						);
																																					}}
																																				/>
																																			</Grid>
																																			<Grid
																																				item
																																				xs={
																																					3
																																				}
																																			>
																																				<FormTextField
																																					name={`orderDetails.${index}.orderList.${sizeIndex}.unitPrice`}
																																					disabled={
																																						true
																																					}
																																					label={t(
																																						'sizePrice'
																																					)}
																																					value={
																																						values
																																							?.orderDetails?.[
																																							index
																																						]
																																							?.orderList?.[
																																							sizeIndex
																																						]
																																							?.unitPrice
																																					}
																																					showPriceSymbol={
																																						true
																																					}
																																				/>
																																			</Grid>
																																			{values
																																				?.orderDetails?.[
																																				index
																																			]
																																				?.orderList
																																				?.length >
																																				1 && (
																																				<Grid
																																					item
																																					xs={
																																						2
																																					}
																																					sx={{
																																						position:
																																							'relative',
																																					}}
																																				>
																																					<IconButton
																																						edge="end"
																																						color="inherit"
																																						onClick={() => {
																																							sizeArrayHelpers.remove(
																																								sizeIndex
																																							);
																																						}}
																																						aria-label="close"
																																						sx={{
																																							position:
																																								'absolute',
																																							top: '50px',
																																							right: '24px',
																																							cursor: 'pointer',
																																						}}
																																					>
																																						<CloseIcon />
																																					</IconButton>
																																				</Grid>
																																			)}
																																		</Grid>

																																		{values
																																			?.orderDetails?.[
																																			index
																																		]
																																			?.orderList
																																			?.length ===
																																			sizeIndex +
																																				1 &&
																																			values
																																				?.orderDetails?.[
																																				index
																																			]
																																				?.variants?.[0]
																																				?.size !==
																																				'' &&
																																			!formData?._id &&
																																			findAvailableUnitsLength >
																																				0 && (
																																				<>
																																					<Divider
																																						style={{
																																							marginTop:
																																								'1rem',
																																						}}
																																					>
																																						{' '}
																																						<AddCircleOutlineIcon
																																							onClick={() => {
																																								sizeArrayHelpers.push(
																																									{
																																										unitPrice:
																																											'',
																																										availableUnits:
																																											'',
																																									}
																																								);
																																							}}
																																							style={{
																																								cursor: 'pointer',
																																							}}
																																						/>
																																					</Divider>
																																				</>
																																			)}
																																	</div>
																																);
																															}
																														)}
																													</div>
																												)}
																											></FieldArray>
																										</Grid>
																									)}
																								</Grid>
																							</AccordionDetails>
																						</Accordion>
																					</div>
																				);
																			}
																		)}
																	</div>
																)}
															></FieldArray>
														</Grid>
													</Grid>

													{!showAddUserFields && (
														<Grid item xs={12}>
															<Typography
																className="uppercase label"
																sx={{
																	marginTop: '25px',
																	marginBottom: '.8rem',
																	fontSize: '12px',
																}}
															>
																{t('purchasedBy')}
															</Typography>

															<PaginatedAutocomplete
																//label={t("user")}
																handleUserSelect={(
																	event: any,
																	value: any
																) => {
																	setSelectedUser(value);
																	setFieldValue(
																		'user',
																		value?._id
																	);
																}}
																fetchOptions={fetchUserOptions}
																selectedUser={selectedUser}
																handleAddUserClick={
																	handleAddUserClick
																}
																t={t}
																//disabled={formData?._id}
															/>
															<FormHelperText>
																{touched?.user && errors?.user ? (
																	<span
																		className="MuiFormHelperText-root Mui-error"
																		style={{ color: '#d32f2f' }}
																	>
																		{errors?.user}
																	</span>
																) : (
																	''
																)}
															</FormHelperText>
														</Grid>
													)}

													{/* First Name and Last Name */}
													{showAddUserFields && (
														<Grid
															container
															spacing={2}
															style={{ marginLeft: '0px' }}
														>
															<Grid item xs={6}>
																{' '}
																<BoldTypography variant="h6">
																	{t('newUser')}
																</BoldTypography>
															</Grid>
															<Grid item xs={6}></Grid>
															<Grid item xs={6}>
																<FormTextField
																	name="firstName"
																	label="First Name"
																/>
															</Grid>
															<Grid item xs={6}>
																<FormTextField
																	name="lastName"
																	label="Last Name"
																/>
															</Grid>
														</Grid>
													)}

													{/* Phone, Email, and Country Code */}
													{showAddUserFields && (
														<Grid
															container
															spacing={2}
															style={{
																marginLeft: '0px',
																marginTop: '15px',
															}}
															alignItems="top"
														>
															<Grid item xs={6}>
																<Grid container spacing={2}>
																	<Grid item xs={4}>
																		<FormSelectField
																			label="Phone"
																			name="countryCode"
																			options={COUNTRY_CODES?.map(
																				(x: any) =>
																					(x = {
																						...x,
																						label: x?.name,
																						value: x?.name,
																					})
																			)}
																		/>
																	</Grid>
																	<Grid item xs={8}>
																		<FormControl
																			fullWidth
																			sx={{
																				marginTop: '2.4rem',
																			}}
																		>
																			<FormTextField
																				name="phone"
																				label=""
																			/>
																		</FormControl>
																	</Grid>
																</Grid>
															</Grid>

															<Grid item xs={6}>
																{' '}
																<FormControl
																	fullWidth
																	//sx={{ marginTop: "2.4rem" }}
																>
																	<FormTextField
																		name="email"
																		label="Email"
																	/>
																</FormControl>
															</Grid>
														</Grid>
													)}

													{showAddUserFields && (
														<Grid item xs={12}>
															<Box
																sx={{
																	cursor: 'pointer',
																	marginTop: '1rem',
																	fontSize: '14px',
																	fontWeight: 600,
																	marginLeft: '1rem',
																}}
																onClick={() =>
																	setShowAddUserFields(false)
																}
															>
																Cancel user
															</Box>
														</Grid>
													)}

													<Box sx={{ marginTop: '25px' }}>
														<FormTextField
															name="actualTotalOfSubTotalAmount"
															fullWidth
															label={t('purchasedPrice')}
															value={
																values?.actualTotalOfSubTotalAmount ||
																0
															}
															disabled={true}
															showPriceSymbol={true}
														/>
													</Box>

													<Grid item xs={12} sx={{ marginTop: '25px' }}>
														<FormTextField
															name="coupon"
															label={t('couponName')}
															placeholder={t('enterCoupon')}
															fullWidth
															disabled={
																formData?._id || appliedCoupon
															}
														/>
														{!formData?._id && (
															<Box textAlign={'right'}>
																<Button
																	variant="text"
																	sx={{
																		fontSize: '12px',
																		marginTop: '5px',
																	}}
																	onClick={() => {
																		if (appliedCoupon) {
																			setAppliedCoupon(null);
																			setFieldValue(
																				'coupon',
																				''
																			);
																		} else {
																			dispatch(
																				verifyCoupon(
																					values?.coupon,
																					(data: any) => {
																						setAppliedCoupon(
																							data
																						);

																						let oldValue: any =
																							calculateTotal(
																								values
																							);

																						if (
																							data?.type ===
																							'flat-off'
																						) {
																							oldValue =
																								oldValue -
																									data?.value ||
																								0;
																						} else {
																							let percentage =
																								oldValue *
																								(data?.value /
																									100);

																							oldValue =
																								oldValue -
																									percentage ||
																								0;
																						}

																						setFieldValue(
																							'finalTotalPayableAmount',
																							oldValue
																						);

																						couponAppliedAfterCalculateTotal(
																							values,
																							data,
																							setFieldValue
																						);
																					}
																				)
																			);
																		}
																	}}
																	disabled={!values?.coupon}
																>
																	{appliedCoupon
																		? 'Remove'
																		: 'Apply'}{' '}
																	Coupon
																</Button>
															</Box>
														)}
													</Grid>

													<Box sx={{ marginTop: '25px' }}>
														<FormTextField
															name="totalPayableAmount"
															fullWidth
															label={t('payableAmount')}
															value={values?.totalPayableAmount}
															disabled={true}
															showPriceSymbol={true}
														/>
													</Box>

													<Grid item xs={12}>
														<FormControl
															fullWidth
															style={{ marginTop: '15px' }}
														>
															<Typography
																className="uppercase"
																sx={{
																	fontSize: '12px',
																	color: '#818181',
																	marginBottom: '0.8rem',
																}}
															>
																{t('paymentType')}
															</Typography>
															<Select
																name="paymentType"
																value={
																	values?.paymentType ||
																	formData?.paymentType
																}
																onChange={(e: any) => {
																	setFieldValue(
																		'paymentType',
																		e?.target?.value || ''
																	);
																}}
															>
																<MenuItem
																	value={BookingPaymentType?.CASH}
																>
																	{t('cash')}
																</MenuItem>
																<MenuItem
																	value={BookingPaymentType?.CARD}
																>
																	{t('creditCard')}
																</MenuItem>
															</Select>
															<FormHelperText>
																{submitCount > 0 &&
																errors?.paymentType ? (
																	<span
																		className="MuiFormHelperText-root Mui-error"
																		style={{ color: '#d32f2f' }}
																	>
																		{t('paymentTypeRequired')}
																	</span>
																) : (
																	''
																)}
															</FormHelperText>
														</FormControl>
													</Grid>

													<div
														style={{
															display: 'flex',
															justifyContent: 'space-between',
														}}
													>
														<Grid
															container
															spacing={2}
															style={{ marginTop: '15px' }}
														>
															<Grid
																item
																xs={12}
																container
																justifyContent="flex-end"
															>
																<SubmitButton
																	type="submit"
																	disabled={isSubmitting}
																>
																	{formData?._id
																		? t('saveChanges')
																		: t('saveNewPurchase')}
																</SubmitButton>
															</Grid>
														</Grid>
													</div>
												</Form>
											</>
										);
									}}
								</Formik>
							</div>
						</Box>
					</Paper>
				</Slide>
			</Modal>

			{/* Confirmation dialog for unsaved changes */}
			<ConfirmDialog
				open={confirmOpen}
				onClose={handleCancelClose}
				onConfirm={handleConfirmClose}
			/>
		</>
	);
};

export default AddUserPurchaseModalForm;
