import * as yup from 'yup';
import { useCommonTranslations } from './CommonTranslations';
import { VALIDATION } from 'app/config/constants';

const patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/;

export const AddOrganisationValidationSchema = () => {
	// eslint-disable-next-line
	const { t } = useCommonTranslations();

	return yup.object().shape({
		name: yup
			.string()
			.required(`${t('organisationNameRequired')}`)
			.min(3, `${t('minimum3LettersAllowed')}`)
			.max(25, `${t('maximum25LettersAllowed')}`),
		contactName: yup
			.string()
			.required(`${t('contactPersonRequired')}`)
			.min(3, `${t('minimum3LettersAllowed')}`)
			.max(25, `${t('maximum25LettersAllowed')}`)
			.test('alphabets-only', t('AlphabetsOnly'), (val) => {
				return val === undefined || VALIDATION.ALPHABETS_SPACE.test(val);
			}),
		email: yup
			.string()
			.email(`${t('invalidEmail')}`)
			.required(`${t('emailRequired')}`),
		countryCode: yup.string().required(`${t('countryCodeRequired')}`),
		phone: yup
			.string()
			.required(t('phoneNumberRequired'))
			.max(13, `${t('maximum13LettersAllowed')}`)
			.matches(/^\d+$/, t('invalidPhoneNumber')),
	});
};

export const AddCreditValidationSchema = (type?: any, showAddUserFields?:any) => {
	// eslint-disable-next-line
	const { t } = useCommonTranslations();

	let validationData: any = {
		packageid: yup.string().required(`${t('packageRequired')}`),
		paidAmount: yup
			.string()
			.required(`${t('paidAmountRequired')}`)
			.test(
				'is-decimal',
				`${t('thePriceShouldbeDecimalwithMaximumTwoDigitsAfterComma')}`,
				(val: any) => {
					if (val !== undefined) {
						return patternTwoDigisAfterComma.test(val);
					}
					return true;
				}
			)
			.typeError('Enter a valid number'),
		balanceAmount: yup
			.string()
			.required(`${t('balanceAmountRquired')}`)
			.test(
				'is-decimal',
				`${t('thePriceShouldbeDecimalwithMaximumTwoDigitsAfterComma')}`,
				(val: any) => {
					if (val !== undefined) {
						return patternTwoDigisAfterComma.test(val);
					}
					return true;
				}
			)
			.typeError('Enter a valid number'),
	};

	if (type === 'corporate') {
		validationData.corporate = yup.string().required(`${t('corporateRequired')}`);
	} else if(!showAddUserFields) {
		validationData.user = yup.string().required(`${t('userRequired')}`);
	}

	return yup.object().shape(validationData);
};
