/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';
import {
	Modal,
	Box,
	Divider,
	Paper,
	Slide,
	Grid,
	Typography,
	IconButton,
	InputAdornment,
	Checkbox,
	FormControlLabel,
} from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';

import { FormTextField } from 'app/shared/atoms';
import { AppDispatch } from '../../../redux/store';
import { defaultGeneralInformationValue } from '../../../model/session-package-model';
import { showToast } from 'app/shared/util/toastHelper';
import {
	createSessionPackage,
	updateSessionPackage,
	fetchSessionPackagesList,
} from '../sessionPackagesApiService';
import ConfirmDialog from 'app/shared/components/ConfirmDialog';
import { AddSessionPackageValidationSchema } from 'app/shared/validations/SessionPackageValidationSchema';
import { SubmitButton } from 'app/shared/molecules';

interface ModalFormProps {
	open: boolean;
	onClose: () => void;
	type?: any;
	action?: any;
	formData?: any;
	page?: any;
	refreshClubsGrid?: any;
	refresh?: any;
	setRefresh?: any;
	callback?: any;
}

const StyledFormControlLabel = styled(FormControlLabel)`
	.MuiTypography-root {
		color: #727272;
	}
`;

const BoldTypography = styled(Typography)(({ theme }) => ({
	fontWeight: 'bold', // Set the font weight to bold
}));

const AddSessionPackageModalForm: React.FC<ModalFormProps> = ({
	open,
	onClose,
	formData,
	callback,
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch<AppDispatch>();
	const formikRef = useRef<FormikProps<any>>(null);

	const [editData, setEditData] = useState<any>();
	const [confirmOpen, setConfirmOpen] = useState(false);

	// Set all the form data used for building the request object to the API finally
	useEffect(() => {
		if (formData?._id) {
			setEditData(formData);
		}
	}, [formData]);

	/**
	 * Handle Next on button click to load the next step if second page call the api
	 *
	 * @function
	 * @returns void
	 */
	const handleSubmit = async (values?: any, actions?: any) => {
		if (formData?._id) {
			delete values._id;

			dispatch(
				await updateSessionPackage(formData?._id, { ...values }, () => {
					if (typeof callback === 'function') {
						callback();
					}
					onClose();
				})
			);
		} else {
			dispatch(
				await createSessionPackage({ ...values }, () => {
					onClose();
					dispatch(fetchSessionPackagesList({ page: 1 }));
				})
			);
		}

		actions.setSubmitting(false);
	};

	/**
	 * Handle close on button click
	 *
	 * @function
	 * @returns void
	 */
	const handleCloseModal = (event?: any, reason?: any) => {
		if (formikRef?.current?.dirty) {
			// Show confirmation dialog if there are unsaved changes
			setConfirmOpen(true);
		} else {
			onClose();
		}
	};

	// Handle close confirmation dialog
	const handleCancelClose = () => {
		setConfirmOpen(false);
	};

	// Handle confirm close action
	const handleConfirmClose = () => {
		setConfirmOpen(false);
		onClose();
	};

	const handleOnChange = (objectKey: string, setFieldValue?: any) => (e: any) => {
		const val = e?.target?.value;
		setFieldValue(`${objectKey}`, val);
	};

	return (
		<>
			<Modal
				open={open}
				onClose={handleCloseModal}
				closeAfterTransition
				slotProps={{
					backdrop: {
						style: {
							backgroundColor: 'rgba(0,0,0,0.5)',
						},
					},
				}}
			>
				<Slide in={open} direction="left">
					<Paper
						style={{
							position: 'absolute',
							right: '0',
							transform: 'translateY(-50%)',
							width: '35%',
							padding: '25px',
							height: '100vh',
							overflow: 'auto',
						}}
					>
						<Box>
							{/* Content of the sliding modal */}
							<BoldTypography variant="h6" style={{ marginBottom: '1rem' }}>
								{formData?._id ? t('editNewSessionPackage') : t('addNewSessionPackage')}
							</BoldTypography>
							<Divider />
							<IconButton
								edge="end"
								color="inherit"
								onClick={handleCloseModal}
								aria-label="close"
								sx={{
									position: 'absolute',
									top: '15px',
									right: '24px',
									cursor: 'pointer',
								}}
							>
								<CloseIcon />
							</IconButton>
							<div>
								<Formik
									innerRef={formikRef}
									initialValues={formData?._id ? editData : defaultGeneralInformationValue}
									onSubmit={(values, actions) => {
										if (!values?.isCorporateApplicable && !values?.isUserApplicable) {
											showToast(
												'At least one option should be selected (User or Corporate)',
												'Error',
												{
													position: toast.POSITION.TOP_RIGHT,
												}
											);
										} else {
											let newValue = {
												...values,
												price: parseFloat(values?.price),
												noOfCredits: parseInt(values?.noOfCredits, 10) || 0,
												noOfFreeCredits: parseInt(values?.noOfFreeCredits, 10) || 0,
											};
											handleSubmit(newValue, actions);
										}
									}}
									enableReinitialize
									validationSchema={AddSessionPackageValidationSchema()}
								>
									{({ values, setFieldValue, isSubmitting }: any) => {
										return (
											<>
												<Form>
													<Grid container spacing={2} style={{ marginTop: '10px' }}>
														{' '}
														<Grid item xs={12}>
															<Typography variant="body2" gutterBottom className="uppercase label">
																{t('packageName')}
															</Typography>
															<FormTextField
																fullWidth
																name="name"
																value={values?.name || ''}
																onChange={handleOnChange('name', setFieldValue)}
																margin="normal"
																placeholder={t('name')}
															/>
														</Grid>
														<Grid item xs={12}>
															<Typography className="uppercase label">{t('price')}</Typography>
															<FormTextField
																fullWidth
																name="price"
																value={values?.price || ''}
																onChange={handleOnChange('price', setFieldValue)}
																margin="normal"
																placeholder={t('price')}
																InputProps={{
																	startAdornment: (
																		<InputAdornment position="start">€</InputAdornment>
																	),
																	inputMode: 'numeric',
																}}
															/>
														</Grid>
														<Grid item xs={12}>
															<Typography variant="body2" gutterBottom className="uppercase label">
																{t('numberOfSessionCredits')}
															</Typography>
															<FormTextField
																fullWidth
																name="noOfCredits"
																value={values?.noOfCredits || ''}
																onChange={handleOnChange('noOfCredits', setFieldValue)}
																margin="normal"
																placeholder={t('numberOfSessionCredits')}
															/>
														</Grid>
														<Grid item xs={12}>
															<Typography variant="body2" gutterBottom className="uppercase label">
																{t('freeCredits')}
															</Typography>
															<FormTextField
																fullWidth
																name="noOfFreeCredits"
																value={values?.noOfFreeCredits || ''}
																onChange={handleOnChange('noOfFreeCredits', setFieldValue)}
																margin="normal"
																placeholder={t('freeCredits')}
															/>
														</Grid>
														<Grid item xs={12}>
															<Typography variant="body2" className="uppercase label" gutterBottom>
																{t('applicableTo')}
															</Typography>
															<StyledFormControlLabel
																control={
																	<Checkbox
																		checked={!!values?.isUserApplicable}
																		value={values?.isUserApplicable}
																		onChange={(e) =>
																			setFieldValue('isUserApplicable', e.target.checked)
																		}
																	/>
																}
																label={t('user')}
															/>
															<StyledFormControlLabel
																control={
																	<Checkbox
																		checked={!!values?.isCorporateApplicable}
																		value={values?.isCorporateApplicable}
																		onChange={(e) =>
																			setFieldValue('isCorporateApplicable', e.target.checked)
																		}
																	/>
																}
																label={t('corporate')}
															/>{' '}
														</Grid>
													</Grid>

													<div
														style={{
															display: 'flex',
															justifyContent: 'space-between',
														}}
													>
														<Grid container spacing={2} style={{ marginTop: '15px' }}>
															<Grid item xs={12} container justifyContent="flex-end">
																<SubmitButton type="submit" disabled={isSubmitting}>
																	{formData?._id
																		? t('saveChanges')
																		: `${t('save')} ${t('new')} ${t('sessionPackage')}`}
																</SubmitButton>
															</Grid>
														</Grid>
													</div>
												</Form>
											</>
										);
									}}
								</Formik>
							</div>
						</Box>
					</Paper>
				</Slide>
			</Modal>

			{/* Confirmation dialog for unsaved changes */}
			<ConfirmDialog
				open={confirmOpen}
				onClose={handleCancelClose}
				onConfirm={handleConfirmClose}
			/>
		</>
	);
};

export default AddSessionPackageModalForm;
