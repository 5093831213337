import * as yup from 'yup';
import { useCommonTranslations } from './CommonTranslations';

const patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/;

export const AddSessionPackageValidationSchema = () => {
	// eslint-disable-next-line
	const { t } = useCommonTranslations();

	let validationData = {
		name: yup
			.string()
			.required(`${t('packageNameRequired')}`)
			.min(2, `${t('minimum2LettersAllowed')}`)
			.max(15, `${t('maximum15LettersAllowed')}`),
		price: yup
			.number()
			.required(`${t('priceRequired')}`)
			.test(
				'is-decimal',
				`${t('thePriceShouldbeDecimalwithMaximumTwoDigitsAfterComma')}`,
				(val: any) => {
					if (val !== undefined) {
						return patternTwoDigisAfterComma.test(val);
					}
					return true;
				}
			)
			.typeError('Enter a valid number'),
		noOfCredits: yup
			.number()
			.required(t('noOfCreditsRequired'))
			.integer(t('mustBeWholeNumber'))
			.min(1, t('minimumOneCreditRequired'))
			.typeError('Enter a valid number'),
		noOfFreeCredits: yup
			.number()
			.nullable() // Makes it optional
			.integer(t('mustBeWholeNumber'))
			.max(yup.ref('noOfCredits'), t('freeCreditsMustBeLessThanOrEqualToCredits'))
			.test(
				'is-lower',
				t('freeCreditsMustBeLessThanOrEqualToCredits'),
				function (value: any) {
					// Allow null or undefined values
					if (value === null || value === undefined) return true;
					return value <= this.parent.noOfCredits;
				}
			)
			.typeError('Enter a valid number'),
	};

	return yup.object().shape(validationData);
};
