/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import "react-toastify/dist/ReactToastify.css";
import {
  Modal,
  Box,
  Checkbox,
  Divider,
  Paper,
  Slide,
  Grid,
  Typography,
  IconButton,
  FormControl,
  FormHelperText,
  FormControlLabel,
} from "@mui/material";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";

import { Form, Formik, FormikProps } from "formik";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { sortBy } from "lodash";

import { useAuth } from "../../../../AuthContext";
import { AppDispatch } from "../../../redux/store";
import { defaultMembershipProductValue } from "../../../model/membership-model";
import { FormTextField, FormSelectField } from "app/shared/atoms";
import {
  createMembership,
  updateSubscription,
  fetchMembershipsList,
} from "../membershipsApiService";
import { fetchTagsList } from "app/entities/Tags/tagsApiService";
import { fetchBenefits } from "../membershipsApiService";
import { cycleTypes } from "app/config/constants";
import { ClubsMultiSelect, SubmitButton } from "app/shared/molecules";
import { AddMembershipProductValidationSchema } from "app/shared/validations/MembershipValidationSchema";
import ConfirmDialog from "app/shared/components/ConfirmDialog"; 

interface ModalFormProps {
  open: boolean;
  onClose: () => void;
  formData?: any;
  callback?: any;
  action?: any;
}

const BoldTypography = styled(Typography)(({ theme }) => ({
  fontWeight: "bold", // Set the font weight to bold
}));

const AddMembershipProductModalForm: React.FC<ModalFormProps> = ({
  open,
  onClose,
  formData,
  callback,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const formikRef = useRef<FormikProps<any>>(null);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const { user } = useAuth();

  const [editData, setEditData] = useState<any>();
  const [categories, setCategories] = useState<any>([]);
  const [benefits, setBenefits] = useState<any>([]);

  useEffect(() => {
    if (open) {
      dispatch(
        fetchTagsList(
          {
            filter_status: "active",
            filter_type: "membership",
            pageSize: 1000,
          },
          (response) => {
            setCategories(sortBy(response, "name") || []);
          }
        )
      );

      dispatch(
        fetchBenefits({}, (response) => {
          setBenefits(sortBy(response, "name") || []);
        })
      );
    }

    return () => {
      setCategories([]);
      setBenefits([]);
    };
  }, [open, dispatch]);

  // Set all the form data used for building the request object to the API finally
  useEffect(() => {
    if (formData?._id) {
      const cycleTypes = [
        formData?.monthlyPrice ? 1 : null,
        formData?.yearlyPrice ? 2 : null,
      ].filter(Boolean);

      setEditData({
        ...formData,
        category: formData?.category?._id,
        benefit: formData?.benefit?._id || formData?.benefit,
        clubs: formData?.clubs?.map((x: any) => x?._id || x),
        cycleTypes,
      });
    }
  }, [formData]);

  /**
   * Handle Next on button click to load the next step if second page call the api
   *
   * @function
   * @returns void
   */
  const handleSubmit = async (values?: any, actions?: any) => {
    actions.setSubmitting(true); 
    if (values?.description === "") {
      delete values.description; // Remove empty description
    }
  
    try {
      if (formData?._id) {
        delete values._id; 
          const response = await dispatch(updateSubscription(formData?._id, { ...values }));
  
        if (response) {
          if (typeof callback === "function") {
            callback(); 
          }
            onClose();
        }
      } else {
        const response = await dispatch(createMembership({ ...values }));
        if (response) {
          onClose(); 
          dispatch(fetchMembershipsList({ page: 1 })); 
        }
      }
    } catch (error) {
      console.error("Error during submission:", error);
    } finally {
      actions.setSubmitting(false); 
    }
  };

  // const handleSubmit = async (values?: any, actions?: any) => {
  //   actions.setSubmitting(true);
  //   if (values?.description === "") {
  //     delete values.description;
  //   }
  //   if (formData?._id) {
  //     delete values._id;

  //     dispatch(
  //       await updateSubscription(formData?._id, { ...values }, () => {
  //         if (typeof callback === "function") {
  //           callback();
  //         }
  //         onClose();
  //       })
  //     );
  //   } else {
  //     dispatch(
  //       await createMembership({ ...values }, () => {
  //         onClose();
  //         dispatch(fetchMembershipsList({ page: 1 }));
  //       })
  //     );
  //   }

  //   actions.setSubmitting(false);
  // };

  /**
   * Handle close on button click
   *
   * @function
   * @returns void
   */
  const handleCloseModal = (event?: any, reason?: any) => {
    if (formikRef?.current?.dirty) {
      // Show confirmation dialog if there are unsaved changes
      setConfirmOpen(true);
    } else {
      onClose();
    }
  };

    // Handle close confirmation dialog
    const handleCancelClose = () => {
      setConfirmOpen(false);
    };
  
    // Handle confirm close action
    const handleConfirmClose = () => {
      setConfirmOpen(false);
      onClose();
    };

  const handleOnChange =
    (objectKey: string, setFieldValue?: any) => (e: any) => {
      const val = e?.target?.value;
      setFieldValue(`${objectKey}`, val);
    };

  const handleCheckboxChange = (
    e: any,
    labelId: any,
    values: any,
    setFieldValue: any
  ) => {
    e.stopPropagation();
    const isChecked = e.target.checked;
    let newCycleTypes: any = [];

    if (isChecked) {
      newCycleTypes = [...(values?.cycleTypes || []), labelId];
    } else {
      newCycleTypes = values?.cycleTypes?.filter((id: any) => id !== labelId);
    }

    setFieldValue("cycleTypes", newCycleTypes);
  };

  const CycleTypesCheckbox = ({
    label,
    labelId,
    values,
    setFieldValue,
  }: any) => {
    return (
      <Grid item xs={4}>
        <FormControlLabel
          control={
            <Checkbox
              checked={values?.cycleTypes?.includes(labelId) ? true : false}
              onChange={(e) =>
                handleCheckboxChange(e, labelId, values, setFieldValue)
              }
            />
          }
          label={label}
        />
      </Grid>
    );
  };

  return (
    <><Modal
      open={open}
      onClose={handleCloseModal}
      closeAfterTransition
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: "rgba(0,0,0,0.5)",
          },
        },
      }}
    >
      <Slide in={open} direction="left">
        <Paper
          style={{
            position: "absolute",
            right: "0",
            transform: "translateY(-50%)",
            width: "35%",
            padding: "25px",
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Box>
            {/* Content of the sliding modal */}
            <BoldTypography variant="h6" style={{ marginBottom: "1rem" }}>
              {formData?._id ? t("editMembership") : t("addNewMembership")}
            </BoldTypography>
            <Divider />
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleCloseModal}
              aria-label="close"
              sx={{
                position: "absolute",
                top: "15px",
                right: "24px",
                cursor: "pointer",
              }}
            >
              <CloseIcon />
            </IconButton>
            <div>
              <Formik
                innerRef={formikRef}
                initialValues={
                  formData?._id ? editData : defaultMembershipProductValue
                }
                onSubmit={(values, actions) => {
                  handleSubmit(
                    {
                      ...values,
                      monthlyPrice: values?.cycleTypes?.includes(1)
                        ? parseFloat(values?.monthlyPrice)
                        : 0,
                      yearlyPrice: values?.cycleTypes?.includes(2)
                        ? parseFloat(values?.yearlyPrice)
                        : 0,
                      clubs: values?.clubs || [],
                      createdBy: user?.userId,
                    },
                    actions
                  );
                }}
                enableReinitialize
                validationSchema={AddMembershipProductValidationSchema()}
              >
                {({ values, errors, setFieldValue, isSubmitting }: any) => {
                  return (
                    <>
                      <Form>
                        <Grid
                          container
                          spacing={2}
                          style={{ marginTop: "10px" }}
                        >
                          {" "}
                          <Grid item xs={12}>
                            <FormTextField
                              name="name"
                              label={t("PlanName")}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormSelectField
                              label={t("category")}
                              name="category"
                              options={categories?.map(
                                (x: any) =>
                                  (x = {
                                    ...x,
                                    label: x?.name,
                                    value: x?._id,
                                  })
                              )}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormSelectField
                              label={t("benefit")}
                              name="benefit"
                              value={values?.benefit}
                              options={benefits?.map(
                                (x: any) =>
                                  (x = {
                                    ...x,
                                    label: x?.name,
                                    value: x?._id,
                                  })
                              )}
                              onChangeCallback={(value) => {
                                const findBenefit: any = benefits?.find(
                                  (x: any) => x?._id === value
                                );
                                setFieldValue(
                                  "membershipSessionMin",
                                  findBenefit?.freeSessions
                                );
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Grid
                              container
                              spacing={2}
                              alignItems="center"
                              style={{ marginBottom: "10px" }}
                            >
                              <Grid item xs={4}>
                                <Typography
                                  variant="body2"
                                  className="uppercase label"
                                  gutterBottom
                                >
                                  {t("cycleType")}
                                </Typography>
                              </Grid>
                              <Grid item xs={7.5}>
                                <Grid
                                  container
                                  spacing={2}
                                  alignItems="center"
                                  sx={{ justifyContent: "flex-end" }}
                                >
                                  {cycleTypes &&
                                    cycleTypes.map(({ label, id: labelId }) => (
                                      <CycleTypesCheckbox
                                        key={labelId}
                                        label={t(label)}
                                        labelId={labelId}
                                        values={values}
                                        setFieldValue={setFieldValue}
                                      />
                                    ))}
                                </Grid>
                              </Grid>
                              <Grid item xs={12}>
                                <FormHelperText>
                                  {errors?.cycleTypes ? (
                                    <span
                                      className="MuiFormHelperText-root Mui-error"
                                      style={{ color: "#d32f2f" }}
                                    >
                                      {errors?.cycleTypes}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </FormHelperText>
                              </Grid>
                            </Grid>
                          </Grid>
                          {values?.cycleTypes?.includes(1) && (
                            <Grid item xs={12}>
                              <FormTextField
                                name="monthlyPrice"
                                label={t("monthlyPrice")}
                                fullWidth
                                showPriceSymbol={true}
                              />
                            </Grid>
                          )}
                          {values?.cycleTypes?.includes(2) && (
                            <Grid item xs={12}>
                              <FormTextField
                                name="yearlyPrice"
                                label={t("annualPrice")}
                                fullWidth
                                showPriceSymbol={true}
                              />
                            </Grid>
                          )}
                          <Grid item xs={12}>
                            <Box style={{ marginBottom: "25px" }}>
                              <ClubsMultiSelect
                                label="Club"
                                multiple={true}
                                value={values?.clubs ? values?.clubs : []} // Pass the value from Formik's state
                                onChange={(clubs: any) => {
                                  setFieldValue("clubs", clubs);
                                }}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl fullWidth>
                              <Typography
                                variant="body2"
                                className="uppercase label"
                                gutterBottom
                              >
                                {t("description")} {t("optional")}
                              </Typography>
                              <TextareaAutosize
                                value={values?.description}
                                onChange={handleOnChange(
                                  "description",
                                  setFieldValue
                                )}
                                minRows={5}
                                style={{ marginTop: "15px" }}
                              />
                              <FormHelperText>
                                {errors?.description ? (
                                  <span
                                    className="MuiFormHelperText-root Mui-error"
                                    style={{ color: "#d32f2f" }}
                                  >
                                    {errors?.description}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                        </Grid>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Grid
                            container
                            spacing={2}
                            style={{ marginTop: "15px" }}
                          >
                            <Grid
                              item
                              xs={12}
                              container
                              justifyContent="flex-end"
                            >
                              <SubmitButton
                                type="submit"
                                disabled={isSubmitting}
                              >
                                {formData?._id
                                  ? t("saveChanges")
                                  : t("saveNewMembership")}
                              </SubmitButton>
                            </Grid>
                          </Grid>
                        </div>
                      </Form>
                    </>
                  );
                }}
              </Formik>
            </div>
          </Box>
        </Paper>
      </Slide>
    </Modal>
    
     {/* Confirmation dialog for unsaved changes */}
     <ConfirmDialog
        open={confirmOpen}
        onClose={handleCancelClose}
        onConfirm={handleConfirmClose}
      />
    </>
  );
};

export default AddMembershipProductModalForm;
