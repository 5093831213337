export interface OrganisationInformation {
  name: string;
  contactName:string;
  email: string;
  countryCode: string;
  phone: string;
}

export interface CreditInformation {
  user: string;
  type: string;
  noOfPackages: number;
  paidAmount: any;
  balanceAmount: any;
  packageid: string;
  paymentType: string;
}

export const defaultOrganisationValue: Readonly<OrganisationInformation> =
  {
    name: "",
    contactName: "",
    email: "",
    countryCode: "",
    phone: ""
  };

export const defaultCreditValue: Readonly<CreditInformation> = {
  user: "",
  type: 'corporate',
  noOfPackages: 1,
  paidAmount: undefined,
  balanceAmount: undefined,
  packageid: "",
  paymentType: "card"
};
