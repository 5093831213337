/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, createSearchParams } from 'react-router-dom';
import {
	Modal,
	Box,
	Button,
	Divider,
	Paper,
	Slide,
	Grid,
	Typography,
	IconButton,
} from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import moment from 'moment';
import { sortBy } from 'lodash';

import CloseIcon from '@mui/icons-material/Close';
import ArrowBack from '@mui/icons-material/ArrowBack';

import CustomStepper from '../../../shared/components/CustomStepper';
import GeneralInformation from './Steps/GeneralInformation';
import Schedule from './Steps/Schedule';
import { AppDispatch } from '../../../redux/store';
import { defaultGeneralInformationValue } from '../../../model/coaching-model';
import { useAuth } from '../../../../AuthContext';

import 'react-toastify/dist/ReactToastify.css';
import { ActionTypes, dayNames } from '../../../config/constants';
import {
	generateTimeSlots,
	generateIntermediateTimeSlots,
} from '../../../shared/util/GenerateTimeSlots';
import { createCoaching, updateCoaching, fetchCoachingList } from '../coachingApiService';
//import { checkPhoneNumberValidity } from 'app/entities/Users/usersApiService';
import { fetchTagsList } from 'app/entities/Tags/tagsApiService';
import { fetchClubsList } from '../../Clubs/clubsApiService';
import { AddCoachingValidationSchema } from 'app/shared/validations/CoachingValidationSchema';
import getCountryNameByDialCode from 'app/shared/components/CountryNameExtractor';
import { extractDialCode } from 'app/shared/components/CountryCodeExtractor';
import ConfirmDialog from 'app/shared/components/ConfirmDialog';
import { SubmitButton } from "app/shared/molecules";

interface ModalFormProps {
	open: boolean;
	onClose: () => void;
	type?: any;
	action?: any;
	formData?: any;
	page?: any;
	refreshClubsGrid?: any;
	refresh?: any;
	setRefresh?: any;
	callback?: any;
	searchParams?: any;
}

interface ImageData {
	attachments: any[];
}

const BoldTypography = styled(Typography)(({ theme }) => ({
	fontWeight: 'bold', // Set the font weight to bold
}));

export const getOverallWorkingHours = (courts: any) => {
	const overallHours: any = {};

	courts?.forEach((court: any) => {
		if (court?.status === 'active') {
			court?.workingHours?.forEach((workingHour: any) => {
				const day: any = workingHour.day;

				if (!overallHours[day]) {
					overallHours[day] = {
						startTime: workingHour.startTime,
						endTime: workingHour.endTime,
						slots: generateIntermediateTimeSlots(
							workingHour.startTime,
							workingHour.endTime
						),
					};
				} else {
					if (workingHour.startTime < overallHours[day].startTime) {
						overallHours[day].startTime = workingHour.startTime;
						overallHours[day].slots = generateIntermediateTimeSlots(
							overallHours[day].startTime,
							workingHour.endTime
						);
					}
					if (workingHour.endTime > overallHours[day].endTime) {
						overallHours[day].endTime = workingHour.endTime;
						overallHours[day].slots = generateIntermediateTimeSlots(
							workingHour.startTime,
							overallHours[day].endTime
						);
					}
				}
			});
		}
	});

	return overallHours;
};

const AddCoachingModalForm: React.FC<ModalFormProps> = ({
	open,
	onClose,
	type,
	action,
	formData,
	page,
	callback,
	searchParams,
}) => {
	const { t } = useTranslation();
	const formikRef = useRef<FormikProps<any>>(null);
	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();
	const [activeStep, setActiveStep] = useState(0); // Step active step
	const [confirmOpen, setConfirmOpen] = useState(false);
	const { user } = useAuth();

	let isCoachPortal = user?.isCoachPortal || localStorage.getItem('userType') === 'coach';

	const [timeSlots, setTimeSlots] = useState([]);
	// Each form data default value created through the data models
	const [editData, setEditData] = useState<any>();
	const [categories, setCategories] = useState([]);

	const steps = [t('generalInformation'), t('availability')];
	const [imgData, setImgData] = useState<ImageData[]>([{ attachments: [] }]);

	const clubsData: any = useSelector((state: RootState) => state.clubs.clubsData);

	useEffect(() => {
		const x = {
			slotInterval: 30,
			openTime: '21:00',
			closeTime: '05:00',
		};

		//Format the time
		const startTime = moment(x.openTime, 'HH:mm');

		//Format the end time and the next day to it
		const endTime = moment(x.closeTime, 'HH:mm').add(1, 'days');

		//Times
		const allTimes: any = [];

		//Loop over the times - only pushes time with 30 minutes interval
		while (startTime < endTime) {
			//Push times
			allTimes.push(startTime.format('HH:mm'));
			//Add interval of 30 minutes
			startTime.add(x.slotInterval, 'minutes');
		}

		setTimeSlots(allTimes);
	}, []);

	useEffect(() => {
		if (open && !isCoachPortal)
			dispatch(
				fetchTagsList(
					{
						filter_status: 'active',
						filter_type: 'coaching-product',
						pageSize: 1000,
					},
					(response) => {
						setCategories(response || []);
					}
				)
			);
	}, [open, dispatch]);

	useEffect(() => {
		if (open && !isCoachPortal) dispatch(fetchClubsList({ limit: 1000, page: 1 }));
	}, [open, dispatch]);

	// Set all the form data used for building the request object to the API finally
	useEffect(() => {
		if ((formData?._id || formData?.id) && action === ActionTypes?.EDIT) {
			const getClubDetails =
				Array.isArray(clubsData) && clubsData?.length > 0
					? clubsData?.find((x: any) => x?._id === formData?.club)
					: {};

			const manipulateData: any = dayNames?.map((x: any) => {
				const findDayObject = getClubDetails?.courts?.find((y: any) => y?.day === x);

				const availabilityData =
					formData?.availability?.find((avail: any) => x === avail.day) || {};

				const getDayBaseMinMaxTime = getOverallWorkingHours(getClubDetails?.courts);

				const timingData =
					availabilityData?.timing?.length > 0
						? availabilityData.timing.map((timing: any) => ({
								...timing,
								isChecked: timing?.slots?.length > 0,
								slots: timing?.slots || [],
						  }))
						: [
								{
									start: '',
									end: '',
									isPrivate: true,
									isChecked: false,
									slots: [],
								},
						  ];

				return getDayBaseMinMaxTime[x] || findDayObject || availabilityData
					? {
							...availabilityData,
							...findDayObject,
							...getDayBaseMinMaxTime[x],
							day: x,
							timing: timingData,
							isChecked: timingData?.[0]?.start !== '',
					  }
					: {
							day: x,
							isChecked: false,
							isPeak: false,
							peakHours: [],
							peakSlots: [],
							slots: [],
							startTime: '00:00',
							endTime: '00:00',
							...getDayBaseMinMaxTime[x],
					  };
			});

			setEditData({
				...formData,
				...formData.userDetail,
				countryCode: getCountryNameByDialCode(formData?.userDetail?.countryCode),
				availability: manipulateData,
				holidays:
					formData?.holidays?.length > 0 ? formData?.holidays : [{ from: '', to: '' }],
			});
		}
	}, [formData, formData?.club, clubsData, activeStep, action]);

	/**
	 * Handle Next on button click to load the next step if second page call the api
	 *
	 * @function
	 * @returns void
	 */
	const handleNext = async (values?: any, actions?: any) => {
		if (formData?._id || activeStep === steps.length - 1) {
			try {
				// Start the submitting process
				actions.setSubmitting(true);

				// Disable further clicks by adding a short delay (e.g., 1 second)
				await new Promise((resolve) => setTimeout(resolve, 2000));

				let payload = {
					...values,
					firstName: values?.firstName?.trim(),
					lastName: values?.lastName?.trim(),
					email: values?.email?.trim(),
					countryCode: extractDialCode(values?.countryCode),
				};

				payload.countryCode = parseInt(payload?.countryCode);

				// Phone validation can be handled here (commented out as in the original code)

				if (formData?._id) {
					// If updating an existing coaching
					delete values._id;
					delete payload._id;

					if (activeStep === 1) {
						payload.availability = payload?.availability || [];
					} else {
						payload = {
							...values,
							countryCode: extractDialCode(values?.countryCode),
						};
						delete payload.availability;
					}

					await dispatch(
						updateCoaching(
							formData?._id, payload,
							(data) => {
								actions.setSubmitting(false);
								if (typeof callback === 'function') {
									callback(data);
								}
								if (activeStep === 1) {
									onClose();
								} else {
									setActiveStep((prevStep) => prevStep + 1);
								}
							}
						)
					);
				} else {
					let payload = {
						...values,
						firstName: values?.firstName?.trim(),
						lastName: values?.lastName?.trim(),
						email: values?.email?.trim(),
						countryCode: extractDialCode(values?.countryCode),
					};

					// If creating new coaching
					await dispatch(
						createCoaching(
							payload,
							(data) => {
								actions.setSubmitting(false);
								setActiveStep(0);

								// Refetch coaching list and navigate after creating the new coach
								dispatch(
									fetchCoachingList({
										page: 1,
										pageSize: searchParams?.pageSize || 10,
									})
								);

								navigate({
									pathname: '/coachings',
									search: createSearchParams({
										...searchParams,
										page: 1,
									}).toString(),
								});

								onClose();
							}
						)
					);
				}
			} catch (error) {
				console.error('Error during form submission:', error);
			} finally {
				// Ensure submitting is stopped in both success and failure cases
				actions.setSubmitting(false);
			}
		} else {
			setActiveStep((prevStep) => prevStep + 1);
			actions.setSubmitting(false);
		}
	};

	/**
	 * Handle back on button click to load the previous step
	 *
	 * @function
	 * @returns void
	 */
	const handleBack = () => {
		setActiveStep((prevStep) => prevStep - 1);
	};

	/**
	 * Handle close on button click
	 *
	 * @function
	 * @returns void
	 */
	const handleCloseModal = (event?: any, reason?: any) => {
		if (formikRef?.current?.dirty) {
			// Show confirmation dialog if there are unsaved changes
			setConfirmOpen(true);
		} else {
			onClose();
		}
	};

	// Handle close confirmation dialog
	const handleCancelClose = () => {
		setConfirmOpen(false);
	};

	// Handle confirm close action
	const handleConfirmClose = () => {
		setConfirmOpen(false);
		onClose();
	};

	/**
	 * Render the forms on each step
	 *
	 * @function
	 * @returns void
	 */
	const renderStep = (step: number, formProps: any) => {
		switch (step) {
			case 0:
				return (
					<GeneralInformation
						type={type}
						imgData={imgData}
						setImgData={setImgData}
						formProps={formProps}
						clubsList={sortBy(clubsData, 'name') || []}
						categories={sortBy(categories, 'name')}
						isCoachPortal={isCoachPortal}
					/>
				);
			case 1:
				return <Schedule page={page} formProps={formProps} timeSlots={timeSlots} />;
			default:
				return null;
		}
	};

	const filterEmptyTimings = (availability: any) =>
		availability
			?.map((x: any) => ({
				...x,
				timing: x?.timing?.filter((y: any) => y?.start !== '' && y?.end !== ''),
			}))
			.filter((x: any) => x?.timing?.length > 0 && x?.isChecked);

	return (
		<>
			<Modal
				open={open}
				onClose={handleCloseModal}
				closeAfterTransition
				slotProps={{
					backdrop: {
						style: {
							backgroundColor:
								action === ActionTypes?.CREATE
									? 'none'
									: page !== 'coachDetail'
									? 'rgba(0,0,0,0.1)'
									: 'rgba(0,0,0,0.5)',
						},
					},
				}}
			>
				<Slide in={open} direction="left">
					<Paper
						style={{
							position: 'absolute',
							right: '0',
							transform: 'translateY(-50%)',
							width: '35%',
							padding: '25px',
							height: '100vh',
							overflow: 'auto',
						}}
					>
						<Box>
							{/* Content of the sliding modal */}
							<BoldTypography variant="h6" style={{ marginBottom: '1rem' }}>
								{action === ActionTypes?.CREATE ? t('addNewCoach') : t('editCoach')}
							</BoldTypography>
							<Divider />
							<IconButton
								edge="end"
								color="inherit"
								onClick={handleCloseModal}
								aria-label="close"
								sx={{
									position: 'absolute',
									top: '15px',
									right: '24px',
									cursor: 'pointer',
								}}
							>
								<CloseIcon />
							</IconButton>
							<div>
								<p style={{ marginBottom: '20px' }}></p>
								<CustomStepper activeStep={activeStep} steps={steps} />
								<p style={{ marginBottom: '20px' }}></p>
								<Divider />
								<p style={{ marginBottom: '20px' }}></p>

								{activeStep < steps.length && (
									<Formik
										innerRef={formikRef}
										initialValues={
											formData?._id
												? editData
												: defaultGeneralInformationValue
										}
										onSubmit={(values, actions) => {
											actions.setSubmitting(true);

											//Check holidays array from & to not equal to empty, If empty remove the key
											const checkHolidays = values?.holidays?.filter(
												(x: any) => x?.from && x?.to
											);

											//Check availablity array from & to not equal to empty, If empty remove the key
											let newAvailablility: any = filterEmptyTimings(
												values?.availability
											);

											newAvailablility = newAvailablility?.map(
												(x: any) =>
													(x = {
														...x,
														timing: x?.timing?.map(
															(y: any) =>
																(y = {
																	...y,
																	slots: generateTimeSlots(
																		y?.start,
																		y?.end
																	).slice(0, -1),
																})
														),
													})
											);

											handleNext(
												{
													...values,
													holidays: checkHolidays,
													availability: newAvailablility,
												},
												actions
											);
										}}
										enableReinitialize
										validationSchema={AddCoachingValidationSchema(activeStep)}
									>
										{(formProps: any) => {
											return (
												<>
													<Form>
														<div>
															{/* Content for each step */}
															{activeStep === 0 &&
																renderStep(activeStep, formProps)}
															{activeStep === 1 &&
																renderStep(activeStep, formProps)}
														</div>
														<div
															style={{
																display: 'flex',
																justifyContent: 'space-between',
															}}
														>
															<Grid
																container
																spacing={2}
																alignItems="center"
																style={{ marginTop: '15px' }}
															>
																<Grid item xs={6}>
																	<Button
																		disabled={activeStep === 0}
																		onClick={handleBack}
																	>
																		<ArrowBack /> {t('back')}
																	</Button>
																</Grid>
																<Grid
																	item
																	xs={6}
																	container
																	justifyContent="flex-end"
																>
																	<SubmitButton
																		type="submit"
																		disabled={
																			formProps?.isSubmitting
																		}
																	>
																		{activeStep === 0
																			? t(
																					'continueToAvailability'
																			  )
																			: formData?._id
																			? t('saveChanges')
																			: t('saveNewCoach')}
																	</SubmitButton>
																</Grid>
															</Grid>
														</div>
													</Form>
												</>
											);
										}}
									</Formik>
								)}
							</div>
						</Box>
					</Paper>
				</Slide>
			</Modal>

			{/* Confirmation dialog for unsaved changes */}
			<ConfirmDialog
				open={confirmOpen}
				onClose={handleCancelClose}
				onConfirm={handleConfirmClose}
			/>
		</>
	);
};

export default AddCoachingModalForm;
