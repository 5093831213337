import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, Typography } from "@mui/material";

// import UserResponsibilities from "./UserResponsibilities";
// import PurchaseProcess from "./PurchaseProcess";
// import PremiumPlan from "./PremiumPlan";
// import Availability from "./Availability";
// import PricesPayment from "./PricesPayment";
// import Promotions from "./Promotions";
// import Errors from "./Errors";
// import PurchaseBonus from "./PurchaseBonus";
// import Cancellations from "./Cancellations";
// import DisclaimerLiability from "./DisclaimerLiability";
// import Indemnity from "./Indemnity";
// import CommunicationsNotifications from "./CommunicationsNotifications";
// import Waiver from "./Waiver";
// import Nullity from "./Nullity";
// import CompleteAgreement from "./CompleteAgreement";
// import DataProtection from "./DataProtection";
// import LegislationJurisdiction from "./LegislationJurisdiction";
// import ComplaintsClaims from "./ComplaintsClaims";

// import { TermsConditionsEN } from "app/languages/en/terms-conditions-translation";
// import { TermsConditionsES } from "app/languages/es/terms-conditions-translation";
import { TermsConditionsFR } from "app/languages/fr/terms-conditions-translation";
// import MentionsLegales from "./MentionsLegales";
// import ConfidentialityAndPersonalData from "./ConfidentialityAndPersonalData";
import GeneralConditionsData from "./GeneralConditionsData";

const GeneralConditions: React.FC = () => {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const { lang } = useParams<{ lang: string }>();

  const selectedLanguage: string = localStorage.getItem('accessToken') ? i18n.language || lang || "en": lang || "en";

  useEffect(() => {
    // Set the i18n language if it's not the same as the selected language
    if (selectedLanguage && i18n.language !== selectedLanguage) {
      i18n.changeLanguage(selectedLanguage);
    }

    // Navigate to a route with the selected language if needed
    // navigate(`/termsConditions/${selectedLanguage}`);
  }, [selectedLanguage, i18n, navigate]);

  // let termsConditionsContent =
  //   selectedLanguage === "es"
  //     ? TermsConditionsES
  //     : selectedLanguage === "fr"
  //     ? TermsConditionsFR
  //     : TermsConditionsEN;

      let termsConditionsContent =TermsConditionsFR

  return (
    <>
      {" "}
      <Container maxWidth="md" sx={{ marginTop: "2rem", marginBottom: "2rem" }}>
        <Typography variant="h5" sx={{ fontWeight: 500, marginBottom: "1rem", textTransfrom: 'capitalize' }}>
          {t("generalConditionsScreen.generalConditions")}
        </Typography>
        <GeneralConditionsData termsConditionsContent={termsConditionsContent} />
        {/* <PurchaseProcess
          title={termsConditionsContent?.purchaseProcess?.title}
          content={termsConditionsContent?.purchaseProcess?.content}
        />
        <PremiumPlan
          title={termsConditionsContent?.premiumPlan?.title}
          content={termsConditionsContent?.premiumPlan?.content}
        />
        <Availability
          title={termsConditionsContent?.availability?.title}
          content={termsConditionsContent?.availability?.content}
        />{" "}
        <PricesPayment
          title={termsConditionsContent?.pricesPayment?.title}
          content={termsConditionsContent?.pricesPayment?.content}
        />
        <Promotions
          title={termsConditionsContent?.promotions?.title}
          content={termsConditionsContent?.promotions?.content}
        />
        <PurchaseBonus
          title={termsConditionsContent?.purchaseBonus?.title}
          content={termsConditionsContent?.purchaseBonus?.content}
        />
        <Errors
          title={termsConditionsContent?.errors?.title}
          content={termsConditionsContent?.errors?.content}
        />{" "}
        <Cancellations
          title={termsConditionsContent?.cancellations?.title}
          content={termsConditionsContent?.cancellations?.content}
        />
        <DisclaimerLiability
          title={termsConditionsContent?.disclaimerLiability?.title}
          content={termsConditionsContent?.disclaimerLiability?.content}
        />
        <Indemnity
          title={termsConditionsContent?.indemnity?.title}
          content={termsConditionsContent?.indemnity?.content}
        />
        <CommunicationsNotifications
          title={termsConditionsContent?.communications?.title}
          content={termsConditionsContent?.communications?.content}
        />
        <Waiver
          title={termsConditionsContent?.waiver?.title}
          content={termsConditionsContent?.waiver?.content}
        />
        <Nullity
          title={termsConditionsContent?.nullity?.title}
          content={termsConditionsContent?.nullity?.content}
        />
        <CompleteAgreement
          title={termsConditionsContent?.completeAgreement?.title}
          content={termsConditionsContent?.completeAgreement?.content}
        />
        <DataProtection
          title={termsConditionsContent?.dataProtection?.title}
          content={termsConditionsContent?.dataProtection?.content}
        />
        <LegislationJurisdiction
          title={termsConditionsContent?.legislation?.title}
          content={termsConditionsContent?.legislation?.content}
        />
        <ComplaintsClaims
          title={termsConditionsContent?.complaintClaims?.title}
          content={termsConditionsContent?.complaintClaims?.content}
        /> */}
      </Container>
    </>
  );
};

export default GeneralConditions;
