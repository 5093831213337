import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Dispatch, AnyAction } from 'redux';
import { isEmpty } from 'lodash';
import { transformFilters } from 'app/shared/util/buildQuery';

import { CUSTOM_ERROR_MSG, HANDLE_ERROR, HTTP_CALL } from '../../config/http';

import { GET, PUT, POST, EXTRAS_MANAGEMENT, PATCH } from '../../config/API.constants';

import { showToast } from '../../shared/util/toastHelper';
import {
	updateExtraProductData,
	extrasPurchaseReceived,
	extrasPurchaseRequested,
	extrasReset,
	purchasesReset,
} from 'app/redux/extrasReducer';

type AppDispatch = Dispatch<AnyAction>;

/**
 * Fetches data from the API.
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */
export const fetchApparelPurchaseList =
	(params: any, callback?: (data: any) => void) =>
	async (dispatch: AppDispatch, getState: () => any) => {
		let response: any = {};

		const qParams = {
			...params,
			limit: params?.pageSize || 10,
			sort: params?.sort || 'createdAt|DESC',
		};
		// // Construct the filters object from the query parameter
		const filtersString: any = await transformFilters(qParams);
		const filteredSearchParams = new URLSearchParams(filtersString);

		try {
			dispatch(extrasPurchaseRequested());
			response = await HTTP_CALL(
				`${EXTRAS_MANAGEMENT.GET_ALL_PURCHASES}?${filteredSearchParams.toString()}`,
				GET
			);

			const {
				data: { data: apiData, statusCode, message },
			} = response;

			if (typeof callback === 'function') {
				callback(apiData);
			}

			dispatch(extrasPurchaseReceived(response?.data));

			if (statusCode === 200) {
				if (!isEmpty(apiData)) {
					return apiData;
				}
			} else {
				CUSTOM_ERROR_MSG(message || statusCode);
			}
		} catch (error: any) {
			HANDLE_ERROR(error);
		}
	};

export const getSingleApparelPurchase =
	(id?: string, callback?: (data: any) => void) =>
	async (dispatch: AppDispatch, getState: () => any) => {
		let response: any = {};
		try {
			response = await HTTP_CALL(`${EXTRAS_MANAGEMENT.GET_ALL_PURCHASES}/${id}`, GET, '');

			const {
				data: { data: apiData, statusCode, message },
			} = response;

			//dispatch(singleExtraPurchaseReceived(apiData));

			if (typeof callback === 'function') {
				callback(apiData);
			}

			if (statusCode === 200) {
				if (!isEmpty(apiData)) {
					return apiData;
				}
			} else {
				CUSTOM_ERROR_MSG(message || statusCode);
			}
		} catch (error: any) {
			HANDLE_ERROR(error);
		}
	};

/**
 * Fetches data from the API.
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const createApparelPurchase =
	(values: any, callback?: (data: any) => void) =>
	async (dispatch: AppDispatch, getState: () => any) => {
		let response: any = {};
		try {
			response = await HTTP_CALL(EXTRAS_MANAGEMENT.ADD_PURCHASE, POST, '', values);

			showToast('Product created successfully', 'Success', {
				position: toast.POSITION.TOP_RIGHT,
			});

			const {
				data: { data: apiData, statusCode, message },
			} = response;

			if (typeof callback === 'function') {
				callback(response);
			}

			if (statusCode === 200) {
				if (!isEmpty(apiData)) {
					return apiData;
				} else {
					showToast('No Data', 'Error', {
						position: toast.POSITION.TOP_RIGHT,
					});
				}
			} else {
				CUSTOM_ERROR_MSG(message || statusCode);
			}
		} catch (error: any) {
			HANDLE_ERROR(error);
		}
	};

/**
 * Fetches data from the API.
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const updateApparelPurchase =
	(id: string, values: any, callback?: (data: any) => void) =>
	async (dispatch: AppDispatch, getState: () => any) => {
		let response: any = {};

		try {
			response = await HTTP_CALL(
				`${EXTRAS_MANAGEMENT.UPDATE_PURCHASE}/${id}`,
				PUT,
				'',
				values
			);

			const {
				data: { data: apiData, statusCode, message },
			} = response;

			if (statusCode === 200) {
				showToast('Product updated successfully', 'Success', {
					position: toast.POSITION.TOP_RIGHT,
				});

				if (!isEmpty(apiData)) {
					dispatch(updateExtraProductData(apiData));

					if (typeof callback === 'function') {
						callback(apiData);
					}

					return apiData;
				} else {
					showToast('No Data', 'Error', {
						position: toast.POSITION.TOP_RIGHT,
					});
				}
			} else {
				CUSTOM_ERROR_MSG(message || statusCode);
			}
		} catch (error: any) {
			HANDLE_ERROR(error);
		}
	};

/**
 * Update the coach status and cancel the sessions.
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const changeApparelPurchaseStatus =
	(id: string, values: any, callback?: (data: any) => void) =>
	async (dispatch: AppDispatch, getState: () => any) => {
		let response: any = {};
		try {
			response = await HTTP_CALL(
				`${EXTRAS_MANAGEMENT.CANCEL_PURCHASE}/${id}`,
				PATCH,
				'',
				values
			);

			showToast(`Product refunded successfully`, 'Success', {
				position: toast.POSITION.TOP_RIGHT,
			});

			const {
				data: { data: apiData, statusCode, message },
			} = response;

			if (!isEmpty(apiData)) {
				dispatch(updateExtraProductData(apiData));
			}

			if (typeof callback === 'function') {
				callback(response);
			}

			if (statusCode === 200) {
				if (!isEmpty(apiData)) {
					return apiData;
				} else {
					showToast('No Data', 'Error', {
						position: toast.POSITION.TOP_RIGHT,
					});
				}
			} else {
				CUSTOM_ERROR_MSG(message || statusCode);
			}
		} catch (error: any) {
			HANDLE_ERROR(error);
		}
	};

/**
 * Fetches data from the API.
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */
export const bookingBasedPurchaseList =
	(params: any, callback?: (data: any) => void) =>
	async (dispatch: AppDispatch, getState: () => any) => {
		let response: any = {};

		try {
			response = await HTTP_CALL(
				`${EXTRAS_MANAGEMENT.GET_BOOKING_BASED}/${params?.bookingId}/purchase`,
				GET
			);

			const {
				data: { data: apiData, statusCode, message },
			} = response;

			if (typeof callback === 'function') {
				callback(apiData);
			}

			if (statusCode === 200) {
				if (!isEmpty(apiData)) {
					return apiData;
				}
			} else {
				CUSTOM_ERROR_MSG(message || statusCode);
			}
		} catch (error: any) {
			HANDLE_ERROR(error);
		}
	};

export const resetData = () => async (dispatch: AppDispatch) => {
	dispatch(extrasReset());
	dispatch(purchasesReset());
};
