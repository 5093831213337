import { Avatar, Box } from '@mui/material';
import { capitalize } from 'lodash';
import { useCommonTranslations } from 'app/shared/validations/CommonTranslations';

const UserAvatarWithCircularText = ({ user }: { user: any }) => {
  const { t } = useCommonTranslations();
  const hasProfilePic = !!user?.profilePic; // Check if profilePic exists

  const currentDate = new Date();
  const membershipStart = new Date(user?.membershipDetail?.membershipExpiry);


  return (
    <Box sx={{ position: 'relative', display: 'inline-block' }}>
      {/* Avatar with profile pic or initials */}
      <Avatar
        src={hasProfilePic ? user?.profilePic : undefined} // Use profile pic if available
        alt={`${capitalize(user?.firstName) || ''} ${capitalize(user?.lastName) || ''}`}
        sx={{
          color: '#ffffff',
          border: user?.membershipDetail ? '4px solid #333333' : '',
          width: 64, // Adjust size as needed
          height: 64, // Adjust size as needed
          fontSize: '1.5rem', // Adjust text size for initials
          fontWeight: 'bold',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {/* Show initials if no profile pic */}
        {!hasProfilePic && `${capitalize(user?.firstName?.[0]) || ''}${capitalize(user?.lastName?.[0]) || ''}`}
      </Avatar>

      {/* "Member" badge */}
      { user?.membershipDetail && user?.membershipDetail?.membershipExpiry && currentDate < membershipStart && (
        <Box
          sx={{
            position: 'absolute',
            bottom: -8,
            left: '50%',
            transform: 'translateX(-50%)',
            bgcolor: '#fff',
            color: '#333333',
            border: '1px solid #333333',
            borderRadius: '12px',
            padding: '2px 8px',
            fontSize: '0.65rem',
            fontWeight: 'semibold',
          }}
        >
          {t('membership')}
        </Box>
      )}
    </Box>
  );
};

export default UserAvatarWithCircularText;
