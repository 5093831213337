import React, { useState, useEffect, useRef } from "react";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  Modal,
  Typography,
  FormControl,
  Chip,
  Checkbox,
} from "@mui/material";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import ReactSlickSlider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { isEmpty, isEqual } from "lodash";
import { useQueryClient } from "@tanstack/react-query";

import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { Mention } from "primereact/mention";
import ConfirmDialog from "app/shared/components/ConfirmDialog";
import { Form, Formik } from "formik";

import {
  ImageUpload,
  EmojiIcon,
  ORIGINAL_MEDIA_BUCKET_URL,
  FEED_ITEMS_LIST,
  CDN_URL,
  FeedEditModalAttachmentSliderSettings,
  PersonDefaultImage,
} from "../../../../config/constants";
import ModalFeedMedia from "./ModalFeedMedia";
import EmojiSelector from "./EmojiSelector";
import { CUSTOM_ERROR_MSG } from "../../../../config/http";
import { showToast } from "../../../../shared/util/toastHelper";
import useFetchClubsLean from "../../hooks/useFetchClubsLean";
import useFetchAllUsers from "../../hooks/useFetchAllUsers";
import useFetchLocalityLean from "../../hooks/useFetchLocalityLean";
import {
  IClubSummary,
  IFeedCreateModalProps,
  IFeedInputPayload,
  IMedia,
  IConsolidatedUser,
} from "../../models/FeedPageModels";
import {
  createFeedItem,
  updateFeedItem,
} from "../../apiService/feedAPIService";
import { getCurrentUser } from "../../../../shared/components/authentication";
import { FeedPostFilterDialog } from "../../../../shared/molecules/FeedPostFilterDialog";
import { SubmitButton} from 'app/shared/molecules';

interface IUsers extends IConsolidatedUser {
  nickname: string;
}

const ModalFeedUpdate: React.FC<IFeedCreateModalProps> = ({
  open,
  onClose,
  postId,
  postDetails,
  setFeedLoading,
}) => {
  const formikRef = useRef<any>(null);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const [postContent, setPostContent] = useState("");
  const [hashTags, setHashTags] = useState<string[]>([]);
  const [openImageUpload, setOpenImageUpload] = useState(false);
  const [openEmojiSelector, setOpenEmojiSelector] = useState(false);
  const [selectedImages, setSelectedImages] = useState<string[]>([]);
  const [mediaRecords, setMediaRecords] = useState<Partial<IMedia>[]>([]);
  const [isSubmitting, setSubmitting] = useState<any>(false);
  

  const [values, setValues] = useState<any>({
    ageRange: [0, 0],
  });

  const [mentions, setMentions] = useState<string[]>([]);
  const imageRef = useRef<HTMLImageElement>(null);
  const queryClient = useQueryClient();
  const { data: clubsList } = useFetchClubsLean();
  const { data: allUsersList } = useFetchAllUsers();
  const { data: localityList } = useFetchLocalityLean();

  const [users, setUsers] = useState<IUsers[]>([]);
  const [suggestions, setSuggestions] = useState<IUsers[]>([]);
  const [currentUser, setCurrentUser] = useState<IUsers | undefined>(undefined);

  useEffect(() => {
    if (!allUsersList) return;

    const curUser: any = getCurrentUser();
    let locUsers: IUsers[] = [];

    allUsersList.forEach((user: IUsers) => {
      const { firstName, lastName, userId, profilePic, userType } = user;
      const fullName = `${firstName}${lastName}`
        .replace(/\s+/g, "")
        .toLowerCase();

      locUsers.push({
        firstName,
        lastName,
        userId,
        profilePic,
        userType,
        nickname: `${fullName}__${userId}^`,
      });
    });

    setUsers(locUsers);

    if (curUser?.userId) {
      let curUserDetail = locUsers.find(
        (user: IUsers) => user.userId === curUser.userId
      );
      setCurrentUser(curUserDetail);
    }
  }, [allUsersList]);

  const { t } = useTranslation();

  // Load post content on feed item edit
  useEffect(() => {
    if (!isEmpty(postDetails)) {
      const {
        ageRange: ageRangeGroup,
        message: feedContent,
        attachments: feedAttachments,
        hashTags: feedHashtags,
        clubs: feedClubs,
      }: any = postDetails;

      const feedClubIds = feedClubs?.map((club: IClubSummary) => club?._id);

      setValues({
        clubs: feedClubIds || [],
        locality: postDetails?.locality || [],
        gender: postDetails?.gender || [],
        ageRange: ageRangeGroup || [0, 90],
        isProEmails: postDetails?.isProEmails,
        isNotificationSend: postDetails?.isNotificationSend
      });

      if (Array.isArray(feedAttachments))
        updateMediaRecords(feedAttachments, true);
      if (Array.isArray(feedHashtags)) setHashTags(feedHashtags);

      const content = feedContent ?? "";
      setPostContent(content);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postDetails, clubsList]);

  // Inside the component, update the useEffect hook for postContent
  useEffect(() => {
    updateHashTags(postContent);
    extractMentions(postContent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postContent]);

  // create a function that will take postContent as parameter. Find all the userId from the postContent which will have the format of @userName__userId^ and extract the userId from it, and set it to the mentions state
  const extractMentions = (text: string) => {
    const regex = /@[a-zA-Z0-9_]+__([0-9][0-9a-f]{23})\^/g;
    const matches = text.matchAll(regex);
    const userIds: string[] = [];

    for (const match of matches) {
      userIds.push(match[1]);
    }

    setMentions(userIds);
  };

  // Add a function to detect and update hashtags
  const updateHashTags = (content: string) => {
    const extractedHashTags = extractHashtags(content);

    // Update the hashTags state with the extracted hashtags
    setHashTags(extractedHashTags);
  };

  const extractHashtags = (content: string) => {
    const regex = /#[a-zA-Z]+/g;
    const hashtags = content.match(regex);
    return hashtags
      ? hashtags.map((hashtag: string) => hashtag.replace(",", "").trim())
      : [];
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    const emojiIconClick =
      e.target.tagName === "IMG" &&
      e.target.getAttribute("alt") === "Emoji icons";
    if (emojiIconClick) {
      setOpenEmojiSelector(true);
    }
    setPostContent(value);
  };

  const generatePayload = () => {
    // Form the request payload and send it to the create post API
    const payload: IFeedInputPayload =
      postContent !== ""
        ? {
          message: postContent,
          attachments: [],
          hashTags: hashTags,
          isPublic: true,
        }
        : {
          attachments: [],
          hashTags: hashTags,
          isPublic: true,
        };

    // Check if age has a value before including it in the payload
    if (!isEqual(values?.ageRange, [0, 0])) {
      payload.ageRange = values?.ageRange;
      payload.isPublic = false;
    }
    // Check if selectedGender has a value before including it in the payload
    if (!isEmpty(values?.gender)) {
      payload.gender = values?.gender;
      payload.isPublic = false;
    }

    // Check if clubId has a value before including it in the payload
    if (!isEmpty(values?.clubs)) {
      payload.clubs = values?.clubs;
      payload.isPublic = false;
    }

    if (!isEmpty(values?.locality)) {
      payload.locality = values?.locality?.map((x: any) => x?.value);
      payload.isPublic = false;
    }

    if (values?.isProEmails) {
      payload.isProEmails = values?.isProEmails;
      payload.isPublic = false;
    }

    if (!isEmpty(hashTags)) {
      payload.hashTags = hashTags;
    }

    if (!isEmpty(mentions)) {
      payload.mentions = mentions;
    }

    payload.isNotificationSend = values?.isNotificationSend || false;

    // check if mediaRecords has a value before including it in the payload
    if (!isEmpty(mediaRecords)) {
      payload.attachments = mediaRecords.map((media) => {
        return {
          mediaId: media.mediaId as string, // Type assertion to ensure mediaId is of type string
        };
      });
    }

    return payload;
  };

  // Save the post
  const handleCreateEditPost = async () => {
    setSubmitting(true);
    // Check if postId exists to determine if it's an edit or create operation
    const isEdit = !!postId;

    const payload = generatePayload();

    try {
      let apiResponse;
      if (isEdit) {
        apiResponse = await updateFeedItem(payload, postId);
      } else {
        apiResponse = await createFeedItem(payload);
      }

      const {
        data: { statusCode, message },
      } = apiResponse;

      if (statusCode === 200) {
        showToast(
          isEdit
            ? t("postUpdatedSuccessfully")
            : t("newFeedCreatedSuccessfully"),
          "Success",
          {
            position: toast.POSITION.BOTTOM_CENTER,
          }
        );
        setFeedLoading && setFeedLoading(true);
        // invalidate the feedItemsList query to refetch the data
        setTimeout(async () => {
          await queryClient.invalidateQueries({ queryKey: [FEED_ITEMS_LIST] });
          setFeedLoading && setFeedLoading(false);
        }, 10 * 1000); // Giving 10 seconds for the feed item to be processed

        setSubmitting(false);
        // Close the modal after creating the post
        handleClose();
      } else {
        setSubmitting(false);
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      setSubmitting(false);
      showToast(
        error?.response?.data?.message[0]?.message ||
        error?.response?.data?.message,
        "Error",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };

  const updateMediaRecords = (
    mediaRecords: Partial<IMedia>[],
    isEdit = false
  ) => {
    // update mediaRecords state
    setMediaRecords((prevMediaRecords) => [
      ...prevMediaRecords,
      ...mediaRecords,
    ]);

    const mediaUrl = isEdit ? CDN_URL : ORIGINAL_MEDIA_BUCKET_URL;

    // extract filePath from mediaRecords
    const filePaths = mediaRecords.map(
      (media) => `${mediaUrl}/${media.filePath}`
    );

    // Update selectedImages state
    setSelectedImages((prevImages: string[]) => [...prevImages, ...filePaths]);
  };

  // Update the states based on media uploads
  const handleImageUpload = (mediaRecords: Partial<IMedia>[]) => {
    updateMediaRecords(mediaRecords);

    setOpenImageUpload(false);
  };

  const handleEmojiSelect = (emoji: string) => {
    setPostContent((prevContent: any) => prevContent + emoji);
    setOpenEmojiSelector(false);
  };

  const getFileExtension = (filename: string): string => {
    const match = /\.[0-9a-z]+$/i.exec(filename);
    return match ? match[0].toLowerCase() : "";
  };

  const handleClose = () => {
    onClose();
    setPostContent("");
    setHashTags([]);
    setOpenImageUpload(false);
    setOpenEmojiSelector(false);
    setSelectedImages([]);
    setValues({ ageRange: [0, 0] });
  };

  const handleCloseModal = (event?: any, reason?: any) => {
    let dirty = false;
    if(postContent && postContent !== postDetails?.message){
      dirty = true;
    } else if(postDetails?.isNotificationSend !== values?.isNotificationSend) {
      dirty = true;
    } else if(postDetails?.clubs && postDetails?.clubs?.length > 0 && values?.clubs?.length > 0 && postDetails?.clubs !== values?.clubs) {
      dirty = true;
    } else if(postDetails?.isProEmails !== values?.isProEmails) {
      dirty = true;
    } else if(postDetails?.locality !== values?.locality) {
      dirty = true;
    } else if(postDetails?.isProEmails && postDetails?.isProEmails !== values?.isProEmails) {
      dirty = true;
    } else if(postDetails?.ageRange && postDetails?.ageRange !== values?.ageRange) {
      dirty = true;
    } else if(postDetails?.attachments && postDetails?.attachments?.length !== mediaRecords?.length) {
      dirty = true;
    } else if(postDetails?.gender && postDetails?.gender !== values?.gender) {
      dirty = true;
    }

    if (dirty) {
      // Show confirmation dialog if there are unsaved changes
      setConfirmOpen(true);
    } else {
      onClose();
      setPostContent("");
      setHashTags([]);
      setOpenImageUpload(false);
      setOpenEmojiSelector(false);
      setSelectedImages([]);
      setValues({ ageRange: [0, 0] });
    }
  };

  // Handle close confirmation dialog
  const handleCancelClose = () => {
    setConfirmOpen(false);
  };

  // Handle confirm close action
  const handleConfirmClose = () => {
    setConfirmOpen(false);
    onClose();
  };


  const handleRemoveClubGenderAgeChips = (fieldName: any, value: any) => {
    // Implement logic to remove the chips based on tagType and value
    if (fieldName === "clubs") {
      // Remove club tag
      setValues({
        ...values,
        clubs: values?.clubs?.filter((x: any) => x !== value),
      });
    } else if (fieldName === "gender") {
      // Remove gender tag
      setValues({ ...values, gender: null });
    } else if (fieldName === "age" || fieldName === "ageRange") {
      // Remove age tag
      setValues({ ...values, ageRange: [0, 0], age: [0, 0] });
    } else if (fieldName === "locality") {
      setValues({
        ...values,
        locality: values?.locality?.filter((x: any) => x?.value !== value),
      });
    }
  };

  const handleRemoveUploadedMedia = (index: number) => {
    const updatedMediaRecords = mediaRecords.filter((_, i) => i !== index);
    setMediaRecords(updatedMediaRecords);

    const updatedSelectedImages = selectedImages.filter((_, i) => i !== index);
    setSelectedImages(updatedSelectedImages);
  };

  const onSearch = (event: any) => {
    setTimeout(() => {
      const query = event.query;
      let suggestions: IUsers[]; // Declare suggestions as an array of IUsers

      if (!query.trim().length) {
        suggestions = [...users];
      } else {
        suggestions = users.filter((user) => {
          return (user?.nickname ?? "")
            .toLowerCase()
            .includes(query.toLowerCase());
        });
      }

      setSuggestions(suggestions);
    }, 250);
  };

  const itemTemplate = (suggestion: IUsers) => {
    const src = suggestion?.profilePic;

    return (
      <div className="flex align-items-center">
        <img
          alt={`${suggestion?.firstName} ${suggestion?.lastName}`}
          src={src}
          onError={(e) =>
            ((e.target as HTMLImageElement).src = PersonDefaultImage)
          }
          width="32"
          style={{ verticalAlign: "middle" }}
        />
        <span className="flex flex-column ml-2">
          {`${suggestion?.firstName} ${suggestion?.lastName}`}
          <small
            style={{ fontSize: ".75rem", color: "var(--text-secondary-color)" }}
          >
            @{suggestion?.nickname}
          </small>
        </span>
      </div>
    );
  };

  const removeMentionedUser = (userId: string, index: number) => {
    const regex = new RegExp(`@[a-zA-Z0-9_]+__${userId}\\^`, "g");
    const matches = [...postContent.matchAll(regex)]; // Convert iterator to array using spread operator

    if (matches.length === 0) {
      return;
    }

    let count = 0;
    let result = postContent;

    for (const match of matches) {
      if (count === index && match.index !== undefined) {
        result =
          result.slice(0, match.index) +
          result.slice(match.index + match[0].length);
        break;
      }
      count++;
    }
    setPostContent(result);
  };

  const getMentionedUser = (userId: string, userIndex: number) => {
    const foundUser = users.find((user) => user.userId === userId);
    if (!foundUser) return null;

    const { firstName, lastName } = foundUser;

    return (
      <Chip
        key={userIndex}
        label={`${firstName} ${lastName}`}
        onDelete={() => removeMentionedUser(userId, userIndex)}
        style={{ margin: "4px" }}
      />
    );
  };

  return (
    <><Modal open={open} onClose={handleCloseModal}>
      <div>
        <Formik
          innerRef={formikRef}
          initialValues={{}}
          onSubmit={(values, actions) => {
            //handleNext(values, actions);
          }}
          enableReinitialize
        >
          {(formProps: any) => {
            return (
              <>
                <Form>
                  <div
                    style={{
                      display: "flex",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: "80%",
                      height: "85vh",
                      maxHeight: "90vh",
                      backgroundColor: "white",
                      borderRadius: "8px",
                    }}
                  >
                    {selectedImages?.length > 0 && (
                      <div style={{ width: "50%" }}>
                        <ReactSlickSlider {...FeedEditModalAttachmentSliderSettings}>
                          {selectedImages?.map((content, index) => (
                            <div key={content[index]}>
                              {RegExp(/(jpeg|jpg|png)$/i).exec(
                                getFileExtension(content)
                              ) && (
                                  <img
                                    ref={imageRef}
                                    src={content}
                                    alt={`Post ${index + 1}`}
                                    style={{
                                      objectFit: "cover",
                                      height: "68vh",
                                      borderTopLeftRadius: "5px",
                                      borderBottomLeftRadius: "5px",
                                      cursor: "pointer",
                                    }}
                                  />
                                )}
                              {/* Display uploaded video */}
                              {RegExp(/(mp4|mov|mpeg)$/i).exec(
                                getFileExtension(content)
                              ) && (
                                  <video
                                    src={content}
                                    controls
                                    style={{
                                      width: "100%",
                                      maxHeight: "100%",
                                      height: "55vh",
                                      borderTopLeftRadius: "5px",
                                      borderBottomLeftRadius: "5px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <track kind="captions" />
                                  </video>
                                )}
                              {/* Button to remove uploaded media */}
                              <IconButton
                                style={{
                                  position: "absolute",
                                  top: "8px",
                                  display: selectedImages.length > 0 ? "block" : "none",
                                }}
                                onClick={() => handleRemoveUploadedMedia(index)}
                              >
                                <CloseIcon style={{ fill: "#FFF" }} />
                              </IconButton>
                            </div>
                          ))}
                        </ReactSlickSlider>
                      </div>
                    )}

                    <div
                      style={{
                        flex: selectedImages?.length > 0 ? "1" : "none",
                        padding: "16px",
                        width: selectedImages?.length > 0 ? "50%" : "100%",
                        height: "90vh",
                        // maxHeight: modalHeightNew,
                        maxHeight: "90vh",
                      }}
                    >
                      {/* Display current user's information - post creator */}
                      <Box display="flex" alignItems="center">
                        {/* TODO: refactor - add current user image and name */}
                        <Avatar src={currentUser?.profilePic || PersonDefaultImage} />
                        <Typography variant="subtitle1" style={{ marginLeft: "8px" }}>
                          {localStorage.getItem("username")}
                        </Typography>
                        <IconButton
                          style={{
                            position: "absolute",
                            top: "15px",
                            right: "15px",
                          }}
                          onClick={handleCloseModal}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Box>
                      <Divider style={{ margin: "16px 0" }} />

                      {/* Post Content Area */}
                      <div style={{ padding: "10px", maxHeight: "45%" }}>
                        <Mention
                          suggestions={suggestions}
                          onSearch={onSearch}
                          field="nickname"
                          placeholder={t("WhatWouldYouLikeToPostAbout")}
                          rows={12}
                          itemTemplate={itemTemplate}
                          onChange={handleTextChange}
                          style={{ width: "100%" }}
                          inputStyle={{
                            width: "100%",
                            maxHeight: "32vh",
                            height: "30vh",
                            overflowY: "auto",
                          }}
                          panelStyle={{
                            minWidth: "40%",
                            maxHeight: "350px",
                          }}
                          value={postContent}
                        />
                      </div>

                      <Box sx={{ zIndex: 333 }}>
                        {/* Display selected club names as chips */}
                        {values?.clubs?.length > 0 && (
                          <div style={{ marginTop: "8px", width: "100%" }}>
                            <strong>{t("clubs")}:</strong>
                            {values?.clubs?.map((clubId: any) => (
                              <Chip
                                key={clubId}
                                label={
                                  clubsList?.find((club: any) => club.id === clubId)?.name
                                }
                                onDelete={() => {
                                  handleRemoveClubGenderAgeChips("clubs", clubId);
                                }}
                                style={{ margin: "4px" }}
                              />
                            ))}
                          </div>
                        )}
                        {/* Display selected gender as a tag */}
                        {!isEmpty(values?.gender) && (
                          <Chip
                            label={`${t("gender")}: ${values?.gender}`}
                            onDelete={() => {
                              handleRemoveClubGenderAgeChips("gender", null);
                            }}
                            style={{ margin: "4px" }}
                          />
                        )}
                        {/* Display selected age as a tag */}

                        {!isEqual(values?.ageRange, [0, 0]) ? (
                          <Chip
                            label={`${t("age")}: ${values?.ageRange?.[0]}-${values?.ageRange?.[1]
                              }`}
                            onDelete={() => {
                              handleRemoveClubGenderAgeChips("age", [0, 0]);
                              handleRemoveClubGenderAgeChips("ageRange", [0, 0]);
                            }}
                            style={{ margin: "4px" }}
                          />
                        ):null}
                        {/* Display proemails as a tag */}
                        {values?.isProEmails && (
                          <Chip
                            label={`${t("proEmailsOnly")}`}
                            onDelete={() => setValues({ ...values, isProEmails: false })}
                            style={{ margin: "4px" }}
                          />
                        )}
                        {/* Display locality as a tag */}
                        {values?.locality?.filter((x: any) => x)?.length > 0 && (
                          <div style={{ marginTop: "8px", width: "100%" }}>
                            <strong>{t("locality")}:</strong>
                            {values?.locality
                              ?.sort((a: any, b: any) => {
                                return a?.name?.localeCompare(b?.name);
                              })
                              ?.filter((x: any) => x)
                              ?.map((localityId: any) => (
                                <Chip
                                  key={localityId}
                                  label={
                                    localityList?.find(
                                      (locality: any) => locality.id === localityId?.value
                                    )?.name
                                  }
                                  onDelete={() => {
                                    handleRemoveClubGenderAgeChips(
                                      "locality",
                                      localityId?.value
                                    );
                                  }}
                                  style={{ margin: "4px" }}
                                />
                              ))}
                          </div>
                        )}

                        {/* Display mentioned users */}
                        <div style={{ marginTop: "8px", width: "100%" }}>
                          {mentions?.length > 0 && <strong>Mentioned Users:</strong>}
                          {mentions?.map((userId, index) =>
                            getMentionedUser(userId, index)
                          )}
                        </div>
                        <div style={{ flex: "1", paddingTop: "40px" }}>
                          {/* Icons above the divider */}
                          {/* Emoji selection button */}
                          <FormControl>
                            <IconButton onClick={() => setOpenEmojiSelector(true)}>
                              <img src={EmojiIcon} alt="Emoji icons" />
                            </IconButton>
                          </FormControl>{" "}
                          {/* Images upload selection button */}
                          <FormControl>
                            <IconButton onClick={() => setOpenImageUpload(true)}>
                              <img src={ImageUpload} alt="attachments upload" />
                            </IconButton>
                          </FormControl>
                          <FeedPostFilterDialog
                            callback={(filterValues: any) => {
                              setValues({ ...values, ...filterValues });
                            }}
                            defaultValue={{
                              isProEmails:
                                values?.isProEmails !== null ? values?.isProEmails : null,
                              gender: values?.gender || [],
                              ageRange:
                                values?.ageRange !== null ? values?.ageRange : null,
                              clubs: values?.clubs !== null ? values?.clubs : null,
                              locality:
                                values?.locality !== null ? values?.locality : null,
                            }}
                            clubsList={clubsList}
                            localityList={localityList}
                          />
                          {openEmojiSelector && (
                            <EmojiSelector onSelect={handleEmojiSelect} />
                          )}
                        </div>
                      </Box>
                      <Divider style={{ margin: "10px 0" }} />
                      <Box
                        display="flex"
                        justifyContent="flex-end"
                        sx={{ marginBottom: "20px" }}
                      >
                        {((!postDetails?.isNotificationSend && postDetails?._id) ||
                          !postDetails?._id) && (
                            <Box
                              sx={{
                                paddingTop: "3px",
                                marginLeft: 1,
                                marginRight: 2,
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Checkbox
                                checked={values?.isNotificationSend}
                                onChange={(e) => {
                                  setValues((prevValues: any) => ({
                                    ...prevValues,
                                    isNotificationSend: !prevValues?.isNotificationSend,
                                  }));
                                }}
                              />
                              <Typography variant="subtitle1" sx={{ marginLeft: 1 }}>
                                {t("sendNotification")}
                              </Typography>
                            </Box>
                          )}
                        <SubmitButton  onClick={handleCreateEditPost} disabled={isSubmitting}>
                          {postId ? t("UpdatePost") : t("Post")}
                        </SubmitButton>
                        {/* <Button
                          variant="contained"
                          color="primary"
                          onClick={handleCreateEditPost}
                          style={{
                            width: "30%",
                            backgroundColor: "#333333",
                            color: "white", 
                          }}
                          disabled={isSubmitting}
                        >
                          {postId ? t("UpdatePost") : t("Post")}
                        </Button> */}
                      </Box>
                      <ModalFeedMedia
                        open={openImageUpload}
                        onClose={() => setOpenImageUpload(false)}
                        onUpload={handleImageUpload}
                      />
                    </div>
                  </div>
                </Form>
              </>)
          }}</Formik>
      </div>
    </Modal>

      {/* Confirmation dialog for unsaved changes */}
      <ConfirmDialog
        open={confirmOpen}
        onClose={handleCancelClose}
        onConfirm={handleConfirmClose}
      />

    </>
  );
};

export default ModalFeedUpdate;
