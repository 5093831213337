/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import {
	Avatar,
	Autocomplete,
	Radio,
	Modal,
	Box,
	Button,
	Divider,
	Paper,
	Slide,
	Grid,
	Link,
	Typography,
	IconButton,
	TextField,
	FormControl,
	FormHelperText,
	Select,
	MenuItem,
	InputAdornment,
	FormControlLabel,
	RadioGroup,
} from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CloseIcon from '@mui/icons-material/Close';
import { AppDispatch } from 'app/redux/store';

import { Form, Formik, FormikProps } from 'formik';
import { styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import dayjs from 'dayjs';
import { sortBy, uniqBy, debounce } from 'lodash';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/es'; // Import the Spanish locale
import 'dayjs/locale/fr'; // Import the French locale
import 'dayjs/locale/en'; // Import the English locale

import COUNTRY_CODES from 'app/shared/data/CountryCodes.json';
import { extractDialCode } from 'app/shared/components/CountryCodeExtractor';
import { toCamelCase } from 'app/shared/util/ToCamelCase';
import { FormTextField, FormSelectField } from 'app/shared/atoms';

import { defaultGeneralInformationValue } from '../../../model/session-model';
import { types, BookingPaymentType, BookingMinutes, VALIDATION } from 'app/config/constants';
import { formatTime, formatDate } from 'app/shared/util/dateHelpers';
import { generateIntermediateTimeSlots } from 'app/shared/util/GenerateTimeSlots';
import { useAuth } from '../../../../AuthContext';
import { getSingleCoaching, fetchCoachingList } from '../../Coaching/coachingApiService';
import { checkPhoneNumberValidity, fetchSearchUsers } from 'app/entities/Users/usersApiService';
import PaginatedAutocomplete from 'app/shared/molecules/PaginatedAutocomplete';

import 'react-toastify/dist/ReactToastify.css';
import { createCoachingSession, coachAvailabilityCheck } from '../sessionApiService';
import ConfirmDialog from 'app/shared/components/ConfirmDialog';
import { SubmitButton } from 'app/shared/molecules';

dayjs.extend(utc);
dayjs.extend(timezone);

interface ModalFormProps {
	open: boolean;
	onClose: () => void;
	page?: any;
	refresh?: any;
	formData?: any;
	isCoachPortal?: boolean;
	isCalendarFlow?: boolean;
	action?: any;
	isOverviewPage?: any;
	selectedClub?: any;
}

const BoldTypography = styled(Typography)(({ theme }) => ({
	fontWeight: 'bold', // Set the font weight to bold
}));

const StyledTextField = styled(TextField)({
	'& label.Mui-focused': {
		color: 'black', // Change the label color when focused
	},
	'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
		borderColor: 'black', // Change the outline border color when focused
	},
	'& .MuiTextField-root': {
		marginTop: 0, // Set top margin to 0
		marginBottom: 0, // Set bottom margin as needed
	},
});

const AddSessionModalForm: React.FC<ModalFormProps> = ({
	open,
	onClose,
	refresh,
	formData,
	isCoachPortal,
	isCalendarFlow,
	action,
	isOverviewPage,
	selectedClub,
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch<AppDispatch>();
	const { user } = useAuth();

	const formikRef = useRef<FormikProps<any>>(null);
	const [confirmOpen, setConfirmOpen] = useState(false);
	const [timeSlots, setTimeSlots] = useState([]);
	const [editData, setEditData] = useState<any>(); // When calender view available card that time edit mode need
	const [changeCoach, setChangeCoach] = useState<any>(true);
	const [coachesList, setCoachesList] = useState([]);
	const [allCoachesList, setAllCoachesList] = useState([]);
	const [showAddUserFields, setShowAddUserFields] = useState(false);
	const [isGroupSession, setGroupSession] = useState(false);
	const [searchCoachText, setCoachSearchText] = useState<string>('');
	const [searchUserText, setUserSearchText] = useState<string>('');
	const [datePickerOpen, setDatePickerOpen] = useState(false);
	const [selectedUser, setSelectedUser] = useState<any>(null);

	const patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/;

	const validationSchema = (isNewUser = false, isGroupSession = false) => {
		let validationData = {
			date: yup.string().required('Date is required'),
			time: yup.string().required('Time is required'),
			isPublic: yup.string().required('Type is required'),
			bookingId: yup.string().required('Coach is required'),
		};

		if (!isGroupSession) {
			validationData = {
				...validationData,
				...{
					paidAmount: yup
						.string()
						.required(`${t('paidAmountRequired')}`)
						.test(
							'is-decimal',
							'The price amount allows numbers only with maximum of 2 decimal points',
							(val: any) => {
								if (val !== undefined) {
									return patternTwoDigisAfterComma.test(val);
								}
								return true;
							}
						),
					balanceAmount: yup
						.string()
						.required(`${t('balanceAmountRquired')}`)
						.test(
							'is-decimal',
							'The price amount allows numbers only with maximum of 2 decimal points',
							(val: any) => {
								if (val !== undefined) {
									return patternTwoDigisAfterComma.test(val);
								}
								return true;
							}
						),
					paymentType: yup.string().required('Payment type is required'),
				},
			};
		}

		if (isNewUser) {
			validationData = {
				...validationData,
				...{
					firstName: yup
						.string()
						.required(`${t('firstNameRequired')}`)
						.min(1, `${t('minimumOneLettersRequired')}`)
						.max(25, `${t('maximum25LettersAllowed')}`)
						.test('alphabets-only', t('AlphabetsOnly'), (val) => {
							return val === undefined || VALIDATION.ALPHABETS_SPACE.test(val);
						}),
					lastName: yup
						.string()
						.required(`${t('lastNameRequired')}`)
						.min(1, `${t('minimumOneLettersRequired')}`)
						.max(25, `${t('maximum25LettersAllowed')}`)
						.test('alphabets-only', t('AlphabetsOnly'), (val) => {
							return val === undefined || VALIDATION.ALPHABETS_SPACE.test(val);
						}),
					countryCode: yup.string().required(`${t('countryCodeRequired')}`),
					phone: yup
						.string()
						.required(t('phoneNumberRequired'))
						.max(13, `${t('maximum13LettersAllowed')}`)
						.matches(/^\d+$/, t('invalidPhoneNumber')),
					email: yup
						.string()
						.email(`${t('invalidEmail')}`)
						.required(`${t('emailRequired')}`),
				},
			};
		} else {
			validationData = {
				...validationData,
				...{
					user: yup.string().required('User is required'),
				},
			};
		}
		return yup.object().shape(validationData);
	};

	const fetchCoachData = (values: any, initialLoad = false) => {
		if (values?.time && values?.date && values?.isPublic) {
			setCoachesList([]);
			dispatch(
				coachAvailabilityCheck(
					{
						time: values?.time,
						date: formatDate(values?.date, 'YYYY-MM-DD'),
						isPublic: values?.isPublic === '1',
					},
					(response) => {
						let coachList: any = response?.data?.data || response?.data || response || [];

						coachList = sortBy(coachList, 'firstName');
						coachList = coachList?.filter((x: any) => x?.club === selectedClub);

						if (isCoachPortal) {
							coachList = coachList?.filter((x: any) => x?.user === user?.userId);
						}

						let newCoachList: any =
							uniqBy(coachList, (coach: any) => coach?.userDetail?.email) || [];
						setCoachesList(newCoachList);
					}
				)
			);
		}
	};

	useEffect(() => {
		setTimeSlots([]);
		const x = {
			slotInterval: 30,
			openTime: '00:00',
			closeTime: '24:00',
		};

		//Format the time
		const startTime = moment(x.openTime, 'HH:mm');

		//Format the end time and the next day to it
		const endTime = moment(x.closeTime, 'HH:mm').add(1, 'days');

		//Times
		const allTimes: any = [];

		//Loop over the times - only pushes time with 30 minutes interval
		while (startTime < endTime) {
			//Push times
			allTimes.push(startTime.format('HH:mm'));
			//Add interval of 30 minutes
			startTime.add(x.slotInterval, 'minutes');
		}

		setTimeSlots(allTimes);
	}, []);

	useEffect(() => {
		dispatch(
			fetchCoachingList(
				{
					pageSize: 10000,
					limit: 10000,
					page: 1,
					//filters: `club|${formData?.club}`,
					noSaveRedux: true,
				},
				(data: any) => {
					setAllCoachesList(data || []);
				}
			)
		);
	}, []);

	useEffect(() => {
		if (formData?._id) {
			const newData = {
				date: formData?.coachDate,
				time: formData?.slots?.[0] || formatTime(formData?.coachStartTime, 'HH:mm'),
				isPublic: formData?.isPublic ? '1' : '0',
				//coach: formData?._id,
				bookingId: formData?._id,
				paidAmount:
					formData?.isPublic === '1'
						? 0
						: formData?.paidAmount
						? parseFloat(formData?.paidAmount)
						: 0,
				balanceAmount:
					formData?.isPublic === '1'
						? 0
						: formData?.balanceAmount
						? parseFloat(formData?.balanceAmount)
						: 0,
				paymentType: formData?.isPublic === '1' ? 'cash' : formData?.paymentType,
				amountToPay: formData?.amountToPay || 0,
			};
			setEditData(newData);
			setGroupSession(formData?.isPublic === '1');

			if (isCalendarFlow) {
				setCoachSearchText(`${formData?.userDetail?.firstName} ${formData?.userDetail?.lastName}`);
			}
			if (!isCalendarFlow) fetchCoachData(newData, true); //Initial load
		}

		if (isOverviewPage) {
			fetchCoachData(formData, true);
			// dispatch(
			// 	fetchCoachingList(
			// 		{
			// 			pageSize: 1000,
			// 			limit: 1000,
			// 			page: 1,
			// 			filters: `club|${formData?.club}`,
			// 			noSaveRedux: true,
			// 		},
			// 		(data: any) => {
			// 			setCoachesList(data || []);
			// 		}
			// 	)
			// );
		}
	}, [formData, formData?.attendees, formData?.updatedAt]);

	useEffect(() => {
		if (formData?._id && coachesList?.length > 0) {
			let selectedCoach: any = coachesList?.find((x: any) => x?._id === formData?._id);
			if (selectedCoach) {
				setCoachSearchText(
					`${selectedCoach?.userDetail?.firstName} ${selectedCoach?.userDetail?.lastName}`
				);
				setChangeCoach(false);
			}
		}
	}, [formData, formData?.attendees, coachesList]);

	const handlePhoneValidation = debounce(
		(val: any, field: string, values: any, setFieldValue: any) => {
			if (field === 'phone') setFieldValue('phone', val);

			if (values?.countryCode && val?.length >= 8) {
				const countryCode: any = extractDialCode(values?.countryCode);

				checkPhoneNumberValidity(
					{ countryCode: parseInt(countryCode), phone: val },
					(data: any) => {
						if (!data) {
							console.log('Phone validation error:', data);
							return;
						}
						// Valid phone number logic here (optional)
					}
				);
			}
		},
		100
	);

	/**
	 * Handle Next on button click to load the next step if second page call the api
	 *
	 * @function
	 * @returns void
	 */
	const handleSubmit = async (values?: any, actions?: any) => {
		let newValues: any = {
			...values,
			paidAmount: values?.isPublic === '1' ? 0 : Number(parseFloat(values?.paidAmount).toFixed(2)),
			balanceAmount:
				values?.isPublic === '1' ? 0 : Number(parseFloat(values?.balanceAmount).toFixed(2)),
			paymentType: values?.isPublic === '1' ? 'cash' : values?.paymentType,
			isOverviewPage: isOverviewPage || false,
			isPublic: values?.isPublic === '1',
		};

		if (typeof values?.countryCode !== 'number') {
			const countryCode: any = extractDialCode(values?.countryCode);
			newValues.countryCode = parseInt(countryCode, 10);
		}

		let slots: any = [];

		if (isOverviewPage) {
			slots = generateIntermediateTimeSlots(values?.time, values?.endTime || formData?.endTime) || [
				values?.time,
			];

			let findThirtyMinutesAmount = values?.selectedUser?.privateCoachingPricePerHour / 2 || 0;
			newValues.totalAmount = findThirtyMinutesAmount * slots?.length;
			newValues.amountToPay = findThirtyMinutesAmount * slots?.length;

			// newValues = {
			// 	...newValues,
			// 	startTime: moment(values?.date).format('YYYY-MM-DD') + ' ' + values?.time,
			// 	endTime: moment(values?.date).format('YYYY-MM-DD') + ' ' + values?.endTime,
			// 	slots,
			// 	date: moment(values?.date)
			// 		.utc()
			// 		.startOf('day')
			// 		.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
			// 	paymentStatus: 'pending',
			// 	bookingMinutes: values?.bookingMinutes || formData?.bookingMinutes,
			// 	playersAllowed: 2,
			// 	status: 'confirmed',
			// 	bookingType: 'coaching',
			// };

			newValues = {
				//...newValues,
				//startTime: moment(values?.date).format('YYYY-MM-DD') + ' ' + values?.time,
				//endTime: moment(values?.date).format('YYYY-MM-DD') + ' ' + values?.endTime,
				slots,
				// date: moment(values?.date)
				// 	.utc()
				// 	.startOf('day')
				// 	.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
				paymentStatus: 'pending',
				bookingMinutes: values?.bookingMinutes || formData?.bookingMinutes,
				playersAllowed: 2,
				status: 'confirmed',
				bookingType: 'coaching',
				bookingId: newValues?.bookingId,
				user: newValues?.user,
				paymentType: 'cash',
				paidAmount: newValues?.paidAmount || 0,
				balanceAmount: newValues?.balanceAmount || 0,
				court: newValues?.court,
				isOverviewPage: true,
			};
		}

		if (showAddUserFields) {
			// Validate phone number before making the API call
			const isPhoneValid = await new Promise((resolve) =>
				checkPhoneNumberValidity(
					{ countryCode: newValues?.countryCode, phone: newValues?.phone },
					(data: any) => {
						if (!data) {
							console.log('Phone validation error:', data);
							resolve(false);
						} else {
							resolve(true);
						}
					}
				)
			);

			if (!isPhoneValid) {
				return; // Exit if phone validation fails
			}
		}

		['firstName', 'lastName', 'email'].forEach((key) => {
			if (newValues?.[key]) {
				newValues[key] = newValues[key].trim();
			}
		});

		if (actions) actions.setSubmitting(true);

		dispatch(
			await createCoachingSession(newValues, (data: any, error?: any) => {
				if (data?._id) {
					refresh();
					setTimeout(() => {
						onClose();
					});
					if (actions) actions.setSubmitting(false);
				}
				if (error) {
					if (actions) actions.setSubmitting(false);
				}
			})
		);
	};

	/**
	 * Handle close on button click
	 *
	 * @function
	 * @returns void
	 */
	const handleCloseModal = (event?: any, reason?: any) => {
		if (formikRef?.current?.dirty) {
			// Show confirmation dialog if there are unsaved changes
			setConfirmOpen(true);
		} else {
			onClose();
		}
	};

	// Handle close confirmation dialog
	const handleCancelClose = () => {
		setConfirmOpen(false);
	};

	// Handle confirm close action
	const handleConfirmClose = () => {
		setConfirmOpen(false);
		onClose();
	};

	/**
	 * Render the forms on each step
	 *
	 * @function
	 * @returns void
	 */

	const handleAddUserClick = () => {
		setShowAddUserFields(true);

		setSelectedUser(null);

		// Use the selected user value to update the form field
		const setFieldValue:any = formikRef?.current?.setFieldValue;
		if(formikRef?.current?.setFieldValue) {
			setFieldValue('user', '');
			setFieldValue('selectedUser', '');
			setFieldValue('firstName', '');
			setFieldValue('lastName', '');
			setFieldValue('email', '');
			setFieldValue('countryCode', '');
			setFieldValue('phone', '');
		}
	};

	const handleSearchChange =
		(objectKey: string) => (event: React.SyntheticEvent<Element, Event>, value: string) => {
			if (objectKey === 'coach') setCoachSearchText(value);
			else setUserSearchText(value);
		};

	const handleOnChange = (objectKey: string, setFieldValue?: any) => (e: any) => {
		const newValue = e?.target?.value;
		setFieldValue(objectKey, newValue);
	};

	const calculateAmountForDuration = (duration: any, price: any) => {
		switch (duration) {
			case 30:
				return price / 2;
			case 60:
				return price;
			case 90:
				return price * 1.5;
			case 120:
				return price * 2;
			case 150:
				return price * 2.5;
			case 180:
				return price * 3;
			case 210:
				return price * 3.5;
			case 240:
				return price * 4;
			default:
				return price;
		}
	};

	const handleDuration = (values: any, setFieldValue: any) => (e: any) => {
		const newDuration = parseInt(e.target.value, 10);
		setFieldValue('bookingMinutes', newDuration || 60);
		setFieldValue(
			'endTime',
			moment(values?.time, 'HH:mm').add(newDuration, 'minutes').format('HH:mm')
		);
		if (values?.selectedUser || values?.selectedCoach?.privateCoachingPricePerHour) {
			let amount = calculateAmountForDuration(
				newDuration,
				parseFloat(
					parseFloat(
						values?.selectedUser?.privateCoachingPricePerHour ||
							values?.selectedCoach?.privateCoachingPricePerHour ||
							0
					).toFixed(2)
				)
			);
			amount = parseFloat(amount?.toFixed(2));
			setFieldValue('amountToPay', amount);
			setFieldValue('paidAmount', amount);
			setFieldValue('totalAmount', amount);
			setFieldValue('balanceAmount', 0);
		}
	};

	// Handle focus event to remove leading zeros
	const handleFocus = (
		event: React.FocusEvent<HTMLInputElement>,
		field: string,
		setFieldValue: FormikProps<any>['setFieldValue']
	) => {
		const valueWithoutLeadingZeros: any = event.target.value.replace(/^0+(?=\d)/, '');
		setFieldValue(field, valueWithoutLeadingZeros > 0 ? valueWithoutLeadingZeros : '');
	};

	// Handle blur event to format number
	const handleBlur = (
		field: string,
		value: string,
		setFieldValue: FormikProps<any>['setFieldValue']
	) => {
		setFieldValue(field, value ? parseFloat(value) : 0);
	};

	const fetchUserOptions = async (inputValue: string, page: number) => {
		try {
			const response: any = await fetchSearchUsers(inputValue, page, true);

			const normalizedData = response?.data?.map((x: any) => ({
				...x,
				id: x?.user?._id || x?._id,
			}));

			return {
				...response,
				data: normalizedData || [],
			};
		} catch (error) {
			console.error('Error fetching user options:', error);
			return {
				data: [],
			};
		}
	};

	return (
		<>
			<Modal
				open={open}
				onClose={handleCloseModal}
				closeAfterTransition
				slotProps={{
					backdrop: {
						style: {
							backgroundColor: 'rgba(0,0,0,0.5)',
						},
					},
				}}
			>
				<Slide in={open} direction="left">
					<Paper
						style={{
							position: 'absolute',
							right: '0',
							transform: 'translateY(-50%)',
							width: '35%',
							padding: '25px',
							height: '100vh',
							overflow: 'auto',
						}}
					>
						<Box>
							{/* Content of the sliding modal */}
							<BoldTypography variant="h6" style={{ marginBottom: '1rem' }}>
								{t('addNewSession')}
							</BoldTypography>
							<Divider />
							<IconButton
								edge="end"
								color="inherit"
								onClick={handleCloseModal}
								aria-label="close"
								sx={{
									position: 'absolute',
									top: '15px',
									right: '24px',
									cursor: 'pointer',
								}}
							>
								<CloseIcon />
							</IconButton>
							<div>
								<Formik
									innerRef={formikRef}
									initialValues={
										isCoachPortal
											? {
													...defaultGeneralInformationValue,
													...editData,
													isPublic: '0',
													coach: user?.userId,
											  }
											: isOverviewPage
											? {
													...defaultGeneralInformationValue,
													...formData,
													//isPublic: formData?.isPublic ? "1" : "0",
													court: formData?.court,
													paymentType: 'cash',
													//balanceAmount: 0,
													//paidAmount: 0,
													isPublic: '0',
											  }
											: {
													...defaultGeneralInformationValue,
													...editData,
													isPublic: '0',
											  }
									}
									onSubmit={(values, actions) => handleSubmit(values, actions)}
									enableReinitialize
									validationSchema={validationSchema(showAddUserFields, isGroupSession)}
								>
									{({ values, errors, setFieldValue, touched, submitCount, isSubmitting }: any) => {
										return (
											<>
												<Form>
													<Grid container spacing={2} style={{ marginTop: '10px' }}>
														{' '}
														<Grid item xs={6}>
															<Typography variant="body2" gutterBottom className="uppercase label">
																{t('date')}
															</Typography>

															<LocalizationProvider
																dateAdapter={AdapterDayjs}
																adapterLocale={localStorage.getItem('language') || 'en'}
															>
																<DatePicker
																	value={values?.date ? dayjs.utc(values?.date) : null}
																	onChange={(newValue: any, neww: any) => {
																		const formattedDate: any = dayjs(newValue).format('YYYY-MM-DD');

																		setFieldValue(`date`, formattedDate);

																		//if (!isOverviewPage)
																		fetchCoachData({
																			...values,
																			date: formattedDate,
																		});
																	}}
																	timezone="UTC"
																	format="DD/MM/YYYY"
																	disabled={formData?._id || isOverviewPage}
																	disablePast
																	open={datePickerOpen}
																	onOpen={() => setDatePickerOpen(true)}
																	onClose={() => setDatePickerOpen(false)}
																	slotProps={{
																		textField: {
																			onClick: () => setDatePickerOpen(true),
																		},
																	}}
																/>
															</LocalizationProvider>
															<FormHelperText>
																{touched?.date && errors?.date ? (
																	<span
																		className="MuiFormHelperText-root Mui-error"
																		style={{ color: '#d32f2f' }}
																	>
																		{errors?.date}
																	</span>
																) : (
																	''
																)}
															</FormHelperText>
														</Grid>
														<Grid item xs={6}>
															<Typography variant="body2" gutterBottom className="uppercase label">
																{t('time')}
															</Typography>
															<Select
																name="time"
																value={values?.time}
																onChange={(e) => {
																	setFieldValue(`time`, e?.target?.value);
																	//if (!isOverviewPage)
																	fetchCoachData({
																		...values,
																		time: e?.target?.value,
																	});
																}}
																fullWidth
																disabled={formData?._id || isOverviewPage}
															>
																{timeSlots?.map((option: any, key: number) => (
																	<MenuItem key={`${option}-${key}`} value={option}>
																		{option}
																	</MenuItem>
																))}
															</Select>
															<FormHelperText>
																{touched?.time && errors?.time ? (
																	<span
																		className="MuiFormHelperText-root Mui-error"
																		style={{ color: '#d32f2f' }}
																	>
																		{t('timeRequired')}
																	</span>
																) : (
																	''
																)}
															</FormHelperText>
														</Grid>
													</Grid>

													<div>
														<FormControl fullWidth style={{ marginTop: '20px' }}>
															<Typography variant="body2" className="uppercase label" gutterBottom>
																{t('type')}
															</Typography>
															<Select
																name="type"
																value={values?.isPublic ? values?.isPublic : ''}
																onChange={(e) => {
																	setFieldValue(`isPublic`, e?.target?.value);
																	setGroupSession(e?.target?.value === '1');
																	//if (!isOverviewPage)
																	fetchCoachData({
																		...values,
																		isPublic: e?.target?.value,
																	});
																}}
																style={{ marginTop: '15px' }}
																disabled={formData?._id || isOverviewPage}
															>
																{types?.length !== 0 ? (
																	types?.map((type: any) => (
																		<MenuItem key={type?.id} value={type?.id}>
																			{type?.label !== '' ? type?.label : t('noName')}
																		</MenuItem>
																	))
																) : (
																	<MenuItem value="No Data">{t('noData')}</MenuItem>
																)}
															</Select>{' '}
															<FormHelperText>
																{touched?.isPublic && errors?.isPublic ? (
																	<span
																		className="MuiFormHelperText-root Mui-error"
																		style={{ color: '#d32f2f' }}
																	>
																		{t('typeRequired')}
																	</span>
																) : (
																	''
																)}
															</FormHelperText>
														</FormControl>

														{isOverviewPage && (
															<Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
																<Grid item xs={12}>
																	<Typography className="uppercase label">
																		{t('duration')}
																	</Typography>
																</Grid>

																<Grid item xs={12}>
																	<RadioGroup
																		row
																		name="bookingMinutes"
																		value={values?.bookingMinutes || 30}
																		onChange={handleDuration(values, setFieldValue)}
																		defaultValue={BookingMinutes?.SIXTY}
																	>
																		<FormControlLabel
																			value={BookingMinutes?.THIRTY}
																			control={<Radio />}
																			label={`${BookingMinutes?.THIRTY} ${t('mins')}`}
																		/>
																		<FormControlLabel
																			value={BookingMinutes?.SIXTY}
																			control={<Radio />}
																			label={`${BookingMinutes?.SIXTY} ${t('mins')}`}
																		/>
																		<FormControlLabel
																			value={BookingMinutes?.NINETY}
																			control={<Radio />}
																			label={`${BookingMinutes?.NINETY} ${t('mins')}`}
																		/>
																		<FormControlLabel
																			value={BookingMinutes?.ONE_TWENTY}
																			control={<Radio />}
																			label={`${BookingMinutes?.ONE_TWENTY} ${t('mins')}`}
																		/>
																	</RadioGroup>
																</Grid>
															</Grid>
														)}

														<Grid item xs={12} sx={{ marginTop: '2rem' }}>
															{' '}
															<BoldTypography variant="h6">{t('bookedCoach')}</BoldTypography>
															<Grid>
																{isCalendarFlow && (
																	<>
																		{' '}
																		<StyledTextField
																			fullWidth
																			name="coach-calendar"
																			value={searchCoachText}
																			disabled
																			style={{
																				marginTop: '10px',
																			}}
																		/>
																	</>
																)}
																{changeCoach && !isCalendarFlow ? (
																	<FormControl
																		fullWidth
																		style={{
																			marginTop: '10px',
																		}}
																	>
																		<Typography
																			variant="body2"
																			className="uppercase label"
																			gutterBottom
																		>
																			{t('coach')}
																		</Typography>

																		<Autocomplete
																			onChange={async (e, selectedUser: any) => {
																				if (!selectedUser) return;

																				const { isPublic, bookingMinutes } = values;
																				const defaultMinutes =
																					bookingMinutes > 120 ? 120 : bookingMinutes;

																				setFieldValue('bookingId', selectedUser._id);

																				if (isOverviewPage) {
																					setFieldValue('coachUserId', selectedUser?.user);
																					setFieldValue('coachId', selectedUser?._id);
																					setFieldValue('selectedUser', selectedUser);

																					let amount =
																						isPublic === '0'
																							? values?.selectedCoach
																									?.privateCoachingPricePerHour || 0
																							: values?.selectedCoach?.groupCoachingPricePerHour ||
																							  0;

																					let finalAmount = calculateAmountForDuration(
																						defaultMinutes,
																						amount
																					);

																					finalAmount = finalAmount
																						? parseFloat(finalAmount?.toFixed(2))
																						: 0;

																					setFieldValue('amountToPay', finalAmount);
																					setFieldValue('totalAmount', finalAmount);
																					setFieldValue('paidAmount', finalAmount);
																					setFieldValue('balanceAmount', 0);
																				}

																				if (selectedUser?.coachId) {
																					const selectedCoach = await dispatch(
																						getSingleCoaching(selectedUser?.coachId)
																					);

																					setFieldValue('selectedCoach', selectedCoach);

																					let amount =
																						isPublic === '0'
																							? selectedCoach?.privateCoachingPricePerHour || 0
																							: selectedCoach?.groupCoachingPricePerHour || 0;

																					let finalAmount = calculateAmountForDuration(
																						defaultMinutes,
																						amount
																					);

																					finalAmount = finalAmount
																						? parseFloat(finalAmount?.toFixed(2))
																						: 0;

																					setFieldValue('amountToPay', finalAmount);
																					setFieldValue('totalAmount', finalAmount);
																					setFieldValue('paidAmount', finalAmount);
																					setFieldValue('balanceAmount', 0);
																				}
																			}}
																			options={coachesList?.filter((x: any) =>
																				allCoachesList?.some(
																					(y: any) => y._id === x.coachId && y.status === 'active'
																				)
																			)}
																			fullWidth
																			onInputChange={(e, newInputValue) => {
																				// Handle changes in the input value for searching
																				handleSearchChange('coach')(e, newInputValue);
																			}}
																			getOptionLabel={(option: any) =>
																				toCamelCase(
																					option?.userDetail?.firstName,
																					option?.userDetail?.lastName
																				)
																			}
																			renderOption={(props, user, { selected }) => (
																				// Customize how each option is displayed in the dropdown
																				<li {...props} key={user?._id}>
																					<FormControlLabel
																						control={<Radio checked={selected} />}
																						label={
																							<Grid container alignItems="center" spacing={2}>
																								<Grid item>
																									<Avatar
																										src={user?.userDetail?.profilePic}
																										alt={`${user?.userDetail?.firstName} ${user?.userDetail?.lastName}`}
																									/>
																								</Grid>
																								<Grid item>
																									<Typography variant="body1">
																										{toCamelCase(
																											user?.userDetail?.firstName,
																											user?.userDetail?.lastName
																										)}
																									</Typography>
																								</Grid>
																							</Grid>
																						}
																					/>
																				</li>
																			)}
																			renderInput={(params) => (
																				<StyledTextField
																					{...params}
																					InputLabelProps={{
																						children: 'Search for a user',
																						...(params.InputLabelProps as object),
																					}}
																				/>
																			)}
																		/>
																		<FormHelperText>
																			{submitCount && errors?.bookingId ? (
																				<span
																					className="MuiFormHelperText-root Mui-error"
																					style={{
																						color: '#d32f2f',
																					}}
																				>
																					{errors?.bookingId}
																				</span>
																			) : (
																				''
																			)}
																		</FormHelperText>
																	</FormControl>
																) : !isCalendarFlow ? (
																	<>
																		<StyledTextField
																			fullWidth
																			name="coach"
																			value={searchCoachText}
																			disabled
																			style={{
																				marginTop: '10px',
																			}}
																		/>
																		{/* <Box
                                    sx={{
                                      cursor: "pointer",
                                      display: "flex",
                                      fontWeight: 500,
                                      marginTop: "0.5rem",
                                      textDecoration: "underline",
                                    }}
                                    onClick={() => {
                                      setChangeCoach(!changeCoach);
                                      setFieldValue("coach", "");
                                    }}
                                  >
                                    {changeCoach ? "Cancel" : "Change"} Coach
                                  </Box> */}
																	</>
																) : null}
															</Grid>
														</Grid>

														{/* If group session no need to show the payment type, amount paid */}
														{values?.isPublic === '0' && (
															<>
																<Grid container spacing={2} style={{ marginTop: '10px' }}>
																	{' '}
																	<Grid item xs={6}>
																		<Typography className="uppercase label">
																			{t('amountToPay')}
																		</Typography>
																		<StyledTextField
																			fullWidth
																			name="amountToPay"
																			value={
																				values?.amountToPay !== null || values?.amountToPay !== ''
																					? values?.amountToPay
																					: ''
																			}
																			margin="normal"
																			placeholder={t('amountToPay')}
																			InputProps={{
																				startAdornment: (
																					<InputAdornment position="start">€</InputAdornment>
																				),
																			}}
																			disabled
																		/>
																	</Grid>
																	<Grid item xs={6}>
																		<Typography className="uppercase label">
																			{t('amountPaid')}
																		</Typography>
																		<StyledTextField
																			fullWidth
																			name="paidAmount"
																			value={
																				values?.paidAmount !== null || values?.paidAmount !== ''
																					? values?.paidAmount
																					: ''
																			}
																			onChange={(e: any) => {
																				let val = e.target.value;

																				// Use regex to check for two decimal places

																				const regex = /^\d+(\.\d{0,2})?$/;

																				if (regex.test(val) || val === '') {
																					// Allow empty string for clearing the input

																					setFieldValue('paidAmount', val || '');

																					let subAmount: any =
																						(values?.amountToPay ? values?.amountToPay : 0) -
																						(val ? val : 0);

																					setFieldValue(
																						'balanceAmount',
																						subAmount ? parseFloat(subAmount?.toFixed(2)) : 0
																					);
																				} else {
																					// Optionally handle invalid input (e.g., show an error message)

																					console.log(
																						'Invalid input. Please enter a number with up to two decimal places.'
																					);
																				}
																			}}
																			margin="normal"
																			placeholder={t('paidAmount')}
																			error={touched?.paidAmount && errors?.paidAmount}
																			helperText={touched?.paidAmount ? errors?.paidAmount : ''}
																			InputProps={{
																				startAdornment: (
																					<InputAdornment position="start">€</InputAdornment>
																				),
																			}}
																			onFocus={(e: React.FocusEvent<HTMLInputElement>) =>
																				handleFocus(e, 'paidAmount', setFieldValue)
																			}
																			onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
																				handleBlur('paidAmount', e.target.value, setFieldValue)
																			}
																		/>
																	</Grid>
																	<Grid item xs={6}>
																		<Typography className="uppercase label">
																			{t('paymentBalance')}
																		</Typography>
																		<StyledTextField
																			fullWidth
																			name="balanceAmount"
																			value={
																				values?.balanceAmount !== null ||
																				values?.balanceAmount !== ''
																					? values?.balanceAmount
																					: ''
																			}
																			onChange={() => {
																				handleOnChange('balanceAmount', setFieldValue);
																			}}
																			margin="normal"
																			placeholder={t('balanceAmount')}
																			error={touched?.balanceAmount && errors?.balanceAmount}
																			helperText={
																				touched?.balanceAmount ? errors?.balanceAmount : ''
																			}
																			InputProps={{
																				startAdornment: (
																					<InputAdornment position="start">€</InputAdornment>
																				),
																			}}
																			onFocus={(e: React.FocusEvent<HTMLInputElement>) =>
																				handleFocus(e, 'balanceAmount', setFieldValue)
																			}
																			onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
																				handleBlur('balanceAmount', e.target.value, setFieldValue)
																			}
																		/>
																	</Grid>
																	<Grid item xs={6}>
																		<FormControl fullWidth>
																			<Typography
																				variant="body2"
																				className="uppercase label"
																				gutterBottom
																			>
																				{t('paymentType')}
																			</Typography>
																			<Select
																				name="paymentType"
																				value={values?.paymentType}
																				onChange={(e) => {
																					setFieldValue(`paymentType`, e?.target?.value);
																				}}
																				style={{
																					marginTop: '15px',
																				}}
																			>
																				<MenuItem value={BookingPaymentType?.CARD}>
																					{t('creditCard')}
																				</MenuItem>
																				<MenuItem value={BookingPaymentType?.CASH}>
																					{t('cash')}
																				</MenuItem>
																				{/* <MenuItem
                                    value={BookingPaymentType?.SUBSCRIPTION}
                                  >
                                    {t("membership")}
                                  </MenuItem> */}
																			</Select>{' '}
																			<FormHelperText>
																				{touched?.paymentType && errors?.paymentType ? (
																					<span
																						className="MuiFormHelperText-root Mui-error"
																						style={{
																							color: '#d32f2f',
																						}}
																					>
																						{t('paymentTypeRequired')}
																					</span>
																				) : (
																					''
																				)}
																			</FormHelperText>
																		</FormControl>
																	</Grid>
																</Grid>
															</>
														)}

														<Divider sx={{ margin: '1.5rem 0' }} />
														<BoldTypography variant="h6">{t('player')}</BoldTypography>
														<Grid container spacing={2} style={{ marginTop: '10px' }}>
															{!showAddUserFields && (
																<Grid item xs={12}>
																	<PaginatedAutocomplete
																		//label={t("user")}
																		handleUserSelect={(event: any, selectedUser: any) => {
																			setSelectedUser(selectedUser);
																			// Use the selected user value to update the form field
																			setFieldValue('user', selectedUser?._id);

																			setFieldValue('selectedUser', selectedUser);

																			setFieldValue('firstName', selectedUser?.firstName || '');
																			setFieldValue('lastName', selectedUser?.lastName || '');
																			setFieldValue('email', selectedUser?.email || '');
																			setFieldValue('countryCode', selectedUser?.countryCode || '');
																			setFieldValue('phone', selectedUser?.phone || '');
																		}}
																		fetchOptions={fetchUserOptions}
																		selectedUser={selectedUser}
																		handleAddUserClick={()=> {
																			handleAddUserClick()
																		}}
																		t={t}
																		//disabled={formData?._id}
																	/>

																	{/* <Autocomplete
																		onChange={(
																			e,
																			selectedUser: any
																		) => {
																			// Use the selected user value to update the form field
																			setFieldValue(
																				'user',
																				selectedUser?._id
																			);

																			setFieldValue(
																				'selectedUser',
																				selectedUser
																			);

																			setFieldValue(
																				'firstName',
																				selectedUser?.firstName ||
																					''
																			);
																			setFieldValue(
																				'lastName',
																				selectedUser?.lastName ||
																					''
																			);
																			setFieldValue(
																				'email',
																				selectedUser?.email ||
																					''
																			);
																			setFieldValue(
																				'countryCode',
																				selectedUser?.countryCode ||
																					''
																			);
																			setFieldValue(
																				'phone',
																				selectedUser?.phone ||
																					''
																			);
																		}}
																		options={usersList?.filter(
																			(x: any) =>
																				x?.status ===
																					'active' &&
																				x?.firstName
																		)}
																		fullWidth
																		inputValue={searchUserText}
																		onInputChange={(
																			e,
																			newInputValue
																		) => {
																			// Handle changes in the input value for searching
																			handleSearchChange(
																				'user'
																			)(e, newInputValue);
																		}}
																		getOptionLabel={(
																			option: any
																		) =>
																			toCamelCase(
																				option.firstName,
																				option.lastName
																			)
																		}
																		renderOption={(
																			props,
																			user,
																			{ selected }
																		) => (
																			<li
																				{...props}
																				key={user?._id}
																			>
																				<FormControlLabel
																					control={
																						<Radio
																							checked={
																								values?.user ===
																								user?._id
																							}
																						/>
																					}
																					label={
																						<Grid
																							container
																							alignItems="center"
																							spacing={
																								2
																							}
																						>
																							<Grid
																								item
																							>
																								<Avatar
																									src={
																										user?.profilePic
																									}
																									alt={`${user?.firstName} ${user?.lastName}`}
																								/>
																							</Grid>
																							<Grid
																								item
																							>
																								<Typography variant="body1">
																									{toCamelCase(
																										user?.firstName,
																										user?.lastName
																									)}
																								</Typography>
																							</Grid>
																							<Grid
																								item
																							>
																								<Typography variant="body1">
																									<Typography variant="body1">
																										[Player
																										Level:{' '}
																										{parseFloat(
																											user.playerLevel
																										).toFixed(
																											2
																										)}

																										]
																									</Typography>
																								</Typography>
																							</Grid>
																						</Grid>
																					}
																				/>
																			</li>
																		)}
																		renderInput={(params) => (
																			<StyledTextField
																				{...params}
																				InputLabelProps={{
																					children:
																						'Search for a user',
																					...(params.InputLabelProps as object),
																				}}
																			/>
																		)}
																		isOptionEqualToValue={(
																			option: User,
																			value: User
																		) => option.id === value.id}
																		noOptionsText={
																			<Typography>
																				{t(
																					'didntFindThePerson'
																				)}{' '}
																				<Link
																					href="#"
																					onClick={() =>
																						handleAddUserClick()
																					}
																				>
																					+{' '}
																					{t(
																						'AddNewuser'
																					)}
																				</Link>
																			</Typography>
																		}
																	/> */}
																	<FormHelperText>
																		{touched?.user && errors?.user ? (
																			<span
																				className="MuiFormHelperText-root Mui-error"
																				style={{
																					color: '#d32f2f',
																				}}
																			>
																				{errors?.user}
																			</span>
																		) : (
																			''
																		)}
																	</FormHelperText>
																</Grid>
															)}

															{!showAddUserFields && (
																<Grid item xs={12}>
																	<Typography>
																		<Link href="#" onClick={() => handleAddUserClick()}>
																			+ {t('AddNewuser')}
																		</Link>
																	</Typography>
																</Grid>
															)}

															{/* First Name and Last Name */}
															{showAddUserFields && (
																<>
																	<Grid item xs={6}>
																		<Typography
																			variant="body2"
																			className="uppercase label"
																			gutterBottom
																		>
																			{t('firstName')}
																		</Typography>
																		<StyledTextField
																			fullWidth
																			name="firstName"
																			value={values?.firstName}
																			onChange={handleOnChange('firstName', setFieldValue)}
																			margin="normal"
																			error={errors?.firstName}
																			helperText={errors?.firstName}
																		/>
																	</Grid>
																	<Grid item xs={6}>
																		<Typography
																			variant="body2"
																			className="uppercase label"
																			gutterBottom
																		>
																			{t('lastName')}
																		</Typography>
																		<StyledTextField
																			fullWidth
																			name="lastName"
																			value={values?.lastName}
																			onChange={handleOnChange('lastName', setFieldValue)}
																			margin="normal"
																			error={errors?.lastName}
																			helperText={errors?.lastName}
																		/>
																	</Grid>

																	{/* Phone, Email, and Country Code */}
																	<Grid item xs={6}>
																		<Typography
																			variant="body2"
																			className="uppercase label"
																			gutterBottom
																		>
																			{t('phone')}
																		</Typography>
																		<Grid container spacing={2}>
																			<Grid item xs={4}>
																				<FormSelectField
																					name="countryCode"
																					options={COUNTRY_CODES?.map(
																						(x: any) =>
																							(x = {
																								...x,
																								label: x?.name,
																								value: x?.name,
																							})
																					)}
																					customWidth="240"
																					customMarginLeft="55"
																					onChangeCallback={(val: any) =>
																						handlePhoneValidation(
																							val,
																							'countryCode',
																							values,
																							setFieldValue
																						)
																					}
																				/>
																			</Grid>
																			<Grid item xs={8}>
																				<Typography
																					variant="body2"
																					className="uppercase label"
																					sx={{
																						marginBottom: '1rem',
																					}}
																				></Typography>
																				<FormTextField
																					name="phone"
																					customCallback={(val: any) =>
																						handlePhoneValidation(
																							val,
																							'phone',
																							values,
																							setFieldValue
																						)
																					}
																					sx={{
																						'& .MuiOutlinedInput-input': {
																							padding: '16px 14px', // Adjust the padding value as needed
																						},
																					}}
																				/>
																			</Grid>
																		</Grid>
																	</Grid>

																	<Grid item xs={6}>
																		<Typography
																			variant="body2"
																			className="uppercase label"
																			gutterBottom
																		>
																			{t('email')}
																		</Typography>
																		<StyledTextField
																			fullWidth
																			name="email"
																			type="email"
																			value={values?.email}
																			onChange={handleOnChange('email', setFieldValue)}
																			margin="normal"
																			error={errors?.email}
																			helperText={errors?.email}
																		/>
																	</Grid>

																	<Grid item xs={12}>
																		<Typography
																			variant="body2"
																			className="uppercase label"
																			gutterBottom
																		>
																			{t('playerLevel')}
																		</Typography>

																		<StyledTextField
																			fullWidth
																			name="playerLevel"
																			onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
																				const inputValue = e.target.value;
																				// Check if the input is valid (numeric or empty) and within the range
																				if (/^$|^(?:10|\d(?:\.\d{0,2})?)$/.test(inputValue)) {
																					// If valid, update the field value
																					setFieldValue('playerLevel', inputValue);
																				}
																			}}
																		/>
																	</Grid>
																	<Grid>
																		<Button
																			disabled={!showAddUserFields}
																			onClick={() => {
																				setShowAddUserFields(false);
																			}}
																			sx={{
																				marginTop: '1rem',
																				marginLeft: '1rem',
																			}}
																		>
																			{t('CancelNewUser')}
																		</Button>
																	</Grid>
																</>
															)}

															<Divider />
														</Grid>
													</div>

													<div
														style={{
															display: 'flex',
															justifyContent: 'space-between',
														}}
													>
														<Grid container spacing={2} style={{ marginTop: '15px' }}>
															<Grid item xs={12} container justifyContent="flex-end">
																<SubmitButton type="submit" disabled={isSubmitting}>
																	{t('saveNewSession')}
																</SubmitButton>
															</Grid>
														</Grid>
													</div>
												</Form>
											</>
										);
									}}
								</Formik>
							</div>
						</Box>
					</Paper>
				</Slide>
			</Modal>

			{/* Confirmation dialog for unsaved changes */}
			<ConfirmDialog
				open={confirmOpen}
				onClose={handleCancelClose}
				onConfirm={handleConfirmClose}
			/>
		</>
	);
};

export default AddSessionModalForm;
