/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import "react-toastify/dist/ReactToastify.css";
import {
  Modal,
  Box,
  Divider,
  Paper,
  Slide,
  Grid,
  Typography,
  IconButton,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";

import { Form, Formik, FormikProps } from "formik";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

import { useAuth } from "../../../../AuthContext";
import { AppDispatch } from "../../../redux/store";
import { defaultGeneralInformationValue } from "../../../model/tag-model";
import { FormTextField, FormSelectField } from "app/shared/atoms";
import { manageTag, fetchTagsList } from "../tagsApiService";
import { tagTypes, priorities } from "app/config/constants";
import { AutoCompleteMultiSelect } from "app/shared/molecules";
import { AddTagValidationSchema } from "app/shared/validations/TagValidationSchema";
import ConfirmDialog from "app/shared/components/ConfirmDialog";
import { SubmitButton } from "app/shared/molecules";

interface ModalFormProps {
  open: boolean;
  onClose: () => void;
  formData?: any;
  callback?: any;
  action?: any;
}

const BoldTypography = styled(Typography)(({ theme }) => ({
  fontWeight: "bold", // Set the font weight to bold
}));

const AddTagModalForm: React.FC<ModalFormProps> = ({
  open,
  onClose,
  formData,
  callback,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const formikRef = useRef<FormikProps<any>>(null);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const { user } = useAuth();
  const [editData, setEditData] = useState<any>();

  // Set all the form data used for building the request object to the API finally
  useEffect(() => {
    if (formData?._id) {
      setEditData(formData);
    }
  }, [formData]);

  /**
   * Handle Next on button click to load the next step if second page call the api
   *
   * @function
   * @returns void
   */
  const handleSubmit = async (values?: any, actions?: any) => {
    try {
      if (formData?._id) {
        delete values._id; 
          const response = await dispatch(manageTag({ ...values }, formData?._id));
  
        if (response) {
          if (typeof callback === "function") {
            callback(); 
          }
            onClose();
        }
      } else {
        const response = await dispatch(manageTag({ ...values }, undefined));
        if (response) {
          onClose(); 
          dispatch(fetchTagsList({ page: 1 })); 
        }
      }
    } catch (error) {
      console.error("Error during submission:", error);
    } finally {
      actions.setSubmitting(false); 
    }
  };

  /**
   * Handle close on button click
   *
   * @function
   * @returns void
   */
  const handleCloseModal = (event?: any, reason?: any) => {
    if (formikRef?.current?.dirty) {
      // Show confirmation dialog if there are unsaved changes
      setConfirmOpen(true);
    } else {
      onClose();
    }
  };

    // Handle close confirmation dialog
    const handleCancelClose = () => {
      setConfirmOpen(false);
    };
  
    // Handle confirm close action
    const handleConfirmClose = () => {
      setConfirmOpen(false);
      onClose();
    };

  const handleOnChange =
    (objectKey: string, setFieldValue?: any) => (e: any) => {
      const val = e?.target?.value;
      setFieldValue(`${objectKey}`, val);
    };

  return (
    <><Modal
      open={open}
      onClose={handleCloseModal}
      closeAfterTransition
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: "rgba(0,0,0,0.5)",
          },
        },
      }}
    >
      <Slide in={open} direction="left">
        <Paper
          style={{
            position: "absolute",
            right: "0",
            transform: "translateY(-50%)",
            width: "35%",
            padding: "25px",
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Box>
            {/* Content of the sliding modal */}
            <BoldTypography variant="h6" style={{ marginBottom: "1rem" }}>
              {formData?._id ? t("editTag") : t("createNewTag")}
            </BoldTypography>
            <Divider />
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleCloseModal}
              aria-label="close"
              sx={{
                position: "absolute",
                top: "15px",
                right: "24px",
                cursor: "pointer",
              }}
            >
              <CloseIcon />
            </IconButton>
            <div>
              <Formik
                innerRef={formikRef}
                initialValues={
                  formData?._id ? editData : defaultGeneralInformationValue
                }
                onSubmit={(values, actions) =>
                  handleSubmit(
                    {
                      ...values,
                      createdBy: user?.userId,
                    },
                    actions
                  )
                }
                enableReinitialize
                validationSchema={AddTagValidationSchema()}
              >
                {({ values, errors, setFieldValue, submitCount, isSubmitting }: any) => {
                  return (
                    <>
                      <Form>
                        <Grid
                          container
                          spacing={2}
                          style={{ marginTop: "10px" }}
                        >
                          {" "}
                          <Grid item xs={12}>
                            <FormTextField
                              name="name"
                              label={t("tagName")}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <AutoCompleteMultiSelect
                              apiName="types"
                              apiCall={false}
                              label={t("type")}
                              multiple={true}
                              onChange={(types: any) => {
                                setFieldValue("type", types);
                              }}
                              options={tagTypes}
                              error={submitCount && errors?.type ? true : false}
                              value={values?.type || null}
                            />
                            <FormHelperText>
                              {submitCount && errors?.type ? (
                                <span
                                  className="MuiFormHelperText-root Mui-error"
                                  style={{ color: "#d32f2f" }}
                                >
                                  {errors?.type}
                                </span>
                              ) : (
                                ""
                              )}
                            </FormHelperText>
                          </Grid>
                          <Grid item xs={12}>
                            <FormSelectField
                              label={t("priority")}
                              name="priority"
                              value={values?.priority}
                              options={priorities}
                              helperText={t("1to8priority")}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Divider
                              sx={{ marginTop: "1rem", marginBottom: "1.5rem" }}
                            />

                            <FormControl fullWidth>
                              <Typography
                                variant="body2"
                                className="uppercase label"
                                gutterBottom
                              >
                                {t("description")} {t("optional")}
                              </Typography>
                              <TextareaAutosize
                                value={values?.description}
                                onChange={handleOnChange(
                                  "description",
                                  setFieldValue
                                )}
                                minRows={5}
                                style={{ marginTop: "15px" }}
                              />
                              <FormHelperText>
                                {errors?.description ? (
                                  <span
                                    className="MuiFormHelperText-root Mui-error"
                                    style={{ color: "#d32f2f" }}
                                  >
                                    {errors?.description}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                        </Grid>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Grid
                            container
                            spacing={2}
                            style={{ marginTop: "15px" }}
                          >
                            <Grid
                              item
                              xs={12}
                              container
                              justifyContent="flex-end"
                            >
                              <SubmitButton
                                disabled={isSubmitting}
                                type="submit"
                              >
                                {formData?._id
                                  ? t("saveChanges")
                                  : t("saveNewTag")}
                              </SubmitButton>
                            </Grid>
                          </Grid>
                        </div>
                      </Form>
                    </>
                  );
                }}
              </Formik>
            </div>
          </Box>
        </Paper>
      </Slide>
    </Modal>
    
    {/* Confirmation dialog for unsaved changes */}
    <ConfirmDialog
        open={confirmOpen}
        onClose={handleCancelClose}
        onConfirm={handleConfirmClose}
      />
    
    </>
  );
};

export default AddTagModalForm;
