import React from 'react';
import {
	Grid,
	Typography,
	FormControl,
	Select,
	MenuItem,
	Box,
	Button,
	IconButton,
	Radio,
} from '@mui/material';
import { toast } from 'react-toastify';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';
//import { SelectChangeEvent } from '@mui/material/Select';
import InputAdornment from '@mui/material/InputAdornment';
import COUNTRY_CODES from '../../../../shared/data/CountryCodes.json';
import FormHelperText from '@mui/material/FormHelperText';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import StyledCustomTextField from 'app/shared/components/StyledTextField';
import { useTranslation } from 'react-i18next';
import { showToast } from '../../../../shared/util/toastHelper';
import { uploadImageToS3 } from 'app/shared/util/ImageUploadApiService';
import { dayNames } from 'app/config/constants';
import { generateTimeSlots } from '../../../../shared/util/GenerateTimeSlots';
//import { checkPhoneNumberValidity } from 'app/entities/Users/usersApiService';
import { extractDialCode } from 'app/shared/components/CountryCodeExtractor';
import { debounce } from 'lodash';
interface GeneralInformationProps {
	type?: any;
	generalInformationData?: any;
	handleChange?: any;
	hasValues?: boolean;
	setHasValues?: any;
	setGeneralInformationData?: any;
	action?: any;
	imgData?: any;
	setImgData?: any;
	formProps?: any;
	clubsList?: any;
	categories?: any;
	isCoachPortal?: any;
}

const GeneralInformation: React.FC<GeneralInformationProps> = ({
	formProps,
	clubsList,
	categories,
	isCoachPortal,
}) => {
	const { values, errors, setFieldValue, submitCount, touched } = formProps;
	const { t } = useTranslation();

	// Separate handlers for Select components
	// const handleCountryCodeChange = (event: SelectChangeEvent) => {
	// 	setFieldValue('countryCode', event.target.value);
	// };

	const uploadImages = async (selectedFiles: any) => {
		try {
			const formData = new FormData();
			selectedFiles.forEach((file: any) => formData.append('files', file));

			const uploadImageAPIResponse = await uploadImageToS3(formData);
			return uploadImageAPIResponse;
		} catch (error) {
			// Handle error
			showToast(t('imageUploadedError'), 'Error', {
				position: toast.POSITION.TOP_RIGHT,
			});
			throw error;
		}
	};

	const handleOnChange = (objectKey: string) => (e: any) => {
		setFieldValue(`${objectKey}`, e?.target?.value || '');
	};

	const showToastMessage = (message: any, type: any) => {
		showToast(message, type, { position: toast.POSITION.TOP_RIGHT });
	};

	const handleFileInputChange =
		(formProps?: any) => async (e: React.ChangeEvent<HTMLInputElement>) => {
			if (e.target.files) {
				const selectedFiles = Array.from(e.target.files);

				try {
					const response = await uploadImages(selectedFiles);

					if (response.statusCode === 200 || response.statusCode === 201) {
						const responseData = response?.data;

						if (responseData?.length > 0) {
							setFieldValue('profilePic', responseData?.[0]?.Location?.Location);
						}

						showToastMessage(t('imageUploadedSuccessfully'), 'Success');
					} else {
						showToastMessage(t('imageUploadedError'), 'Error');
					}
				} catch (error) {
					showToastMessage(t('imageUploadedError'), 'Error');
				}

				e.target.value = '';
			}
		};

	const handlePhoneValidation = debounce(
		(
			val: any,
			field: string,
			values: any,
			setFieldValue: (field: string, value: any) => void
		) => {
			const params: { phone?: any; countryCode?: number } = {};

			const countryCode: any = extractDialCode(values?.countryCode);

			if (field === 'phone') {
				setFieldValue('phone', val);
				params.phone = val;
				params.countryCode = parseInt(countryCode, 10); // Using base 10
			} else {
				params.phone = values?.phone;
				params.countryCode = parseInt(countryCode, 10);
			}

			// if (params?.countryCode && params?.phone?.length >= 8) {
			// 	checkPhoneNumberValidity(params, (data: any) => {
			// 		if (!data) {
			// 			console.log('Phone validation error:', data);
			// 			return;
			// 		}
			// 		// Add any further actions on successful validation
			// 	});
			// }
		},
		100 // Debounce time in milliseconds
	);

	const handleImageRemove = () => {
		setFieldValue(`profilePic`, null);
	};

	const getImageOrigionalName = (imageUrl: string) => {
		const originalName: any = imageUrl?.split('/')?.pop();
		return decodeURIComponent(originalName);
	};

	// Find min and max time all courts and day specific
	const getConsolidatedWorkingHours = (courts: any) => {
		const consolidatedWorkingHours: any = {};

		courts?.forEach((court: any) => {
			if (court?.status === 'active') {
				court?.workingHours?.forEach((hour: any) => {
					const { day, startTime, endTime } = hour;
					if (!consolidatedWorkingHours[day]) {
						consolidatedWorkingHours[day] = {
							day,
							startTime,
							endTime,
							slots: [],
							peakSlots: [],
							peakHours: [],
							isPeak: false,
							isChecked: false,
						};
					} else {
						// Update min startTime
						if (startTime < consolidatedWorkingHours[day].startTime) {
							consolidatedWorkingHours[day].startTime = startTime;
						}

						// Update max endTime
						if (endTime > consolidatedWorkingHours[day].endTime) {
							consolidatedWorkingHours[day].endTime = endTime;
						}
					}
				});
			}
		});

		return Object.values(consolidatedWorkingHours);
	};

	const handleDropdownChange = (objectKey: string) => (e: any) => {
		setFieldValue(`${objectKey}`, e?.target?.value);
		if (objectKey === 'club') {
			const selectedClubObject = clubsList?.find((x: any) => x?._id === e?.target?.value);

			const manipulateData = dayNames?.map((x: any) => {
				let consolidatedHours = getConsolidatedWorkingHours(selectedClubObject?.courts);
				const findDayObject: any = consolidatedHours?.find((y: any) => y?.day === x);

				return findDayObject
					? {
							...findDayObject,
							slots: generateTimeSlots(
								findDayObject?.startTime || values?.minTimeSlot || '00:00',
								findDayObject?.endTime || values?.maxTimeSlot || '00:00'
							),
							isChecked: false,
							timing: [
								{
									start: '',
									end: '',
									isPrivate: true,
									slots: [],
								},
							],
					  }
					: {
							day: x,
							endTime: values?.maxTimeSlot || '00:00',
							isChecked: false,
							isPeak: false,
							peakHours: [],
							peakSlots: [],
							slots: [],
							startTime: values?.minTimeSlot || '00:00',
					  };
			});

			setFieldValue(`availability`, manipulateData);
		}
	};

	const shouldDisplayErrorMessage = (fieldName: string) => {
		return submitCount ? errors?.[fieldName] : '';
	};

	const shouldDisplayError = (fieldName: string) => {
		return submitCount && touched?.[fieldName] && errors?.[fieldName] ? true : false;
	};

	return (
		<div>
			{formProps?.values?.profilePic ? (
				<Box display="flex" flexWrap="wrap">
					<Grid
						container
						spacing={2}
						style={{ marginTop: '10px' }}
						key={formProps?.values?.profilePic}
						alignItems={'center'}
					>
						<Grid item xs={4}>
							<img
								src={formProps?.values?.profilePic}
								alt={`Uploaded `}
								style={{ maxWidth: '100%', maxHeight: '180px' }}
							/>
						</Grid>
						<Grid item xs={7} style={{ fontWeight: 600, wordBreak: 'break-all' }}>
							{getImageOrigionalName(formProps?.values?.profilePic)}
							{/* <Typography>{byteConverter(image?.size)}</Typography> */}
						</Grid>
						<Grid item xs={1}>
							<IconButton
								color="primary"
								onClick={() => handleImageRemove()}
								sx={{ float: 'right' }}
							>
								<CloseIcon />
							</IconButton>
						</Grid>
					</Grid>
				</Box>
			) : (
				<Grid container spacing={2} style={{ marginTop: '10px' }}>
					<Grid item xs={4}>
						<div>
							<input
								type="file"
								id="fileInput"
								accept="image/*"
								multiple={false}
								style={{ display: 'none' }}
								onChange={handleFileInputChange(formProps)}
							/>
							<label htmlFor="fileInput">
								<Button
									variant="outlined"
									component="span"
									sx={{
										width: '100%',
										minHeight: '189px',
										border: '1px solid #E0E0E0',
										padding: '51px 117px, 51px, 117px',
										display: 'inline-block',
										textAlign: 'center',
										borderRadius: '5px',
									}}
								>
									<CloudUploadIcon
										style={{ marginTop: '35px', color: 'black' }}
									/>
									<Typography
										variant="button"
										display="block"
										sx={{ fontWeight: 'bold', color: 'black' }}
									>
										{t('clickToUpload')}{' '}
										<span style={{ color: 'grey' }}>{t('dragDrop')}</span>
									</Typography>
									<Typography
										variant="body2"
										display="block"
										sx={{ color: 'black' }}
									>
										{t('youCanUpload')}
									</Typography>
								</Button>
							</label>
						</div>
					</Grid>
				</Grid>
			)}

			<Grid container spacing={2} style={{ marginTop: '10px' }}>
				<Grid item xs={6}>
					<Typography variant="body2" className="uppercase label" gutterBottom>
						{t('firstName')}
					</Typography>
					<StyledCustomTextField
						fullWidth
						name="firstName"
						value={values?.firstName}
						onChange={handleOnChange('firstName')}
						margin="normal"
						error={shouldDisplayError('firstName')}
						helperText={shouldDisplayErrorMessage('firstName')}
					/>
				</Grid>
				<Grid item xs={6}>
					<Typography variant="body2" className="uppercase label" gutterBottom>
						{t('lastName')}
					</Typography>
					<StyledCustomTextField
						fullWidth
						name="lastName"
						value={values?.lastName}
						onChange={handleOnChange('lastName')}
						margin="normal"
						error={shouldDisplayError('lastName')}
						helperText={shouldDisplayErrorMessage('lastName')}
					/>
				</Grid>
			</Grid>

			<Grid container spacing={2} style={{ marginTop: '10px' }}>
				<Grid item xs={6}>
					<Typography variant="body2" className="uppercase label" gutterBottom>
						{t('phone')}
					</Typography>
					<Grid container spacing={2}>
						<Grid item xs={4} style={{ marginTop: '22px' }}>
							<FormControl fullWidth>
								<Select
									name="countryCode"
									value={`${values?.countryCode}`}
									// onChange={()=>{
									// 	handleCountryCodeChange}
									// }
									onChange={(event: any) => {
										setFieldValue('countryCode', event.target.value);
										// handlePhoneValidation(
										// 	event?.target?.value,
										// 	'countryCode',
										// 	{ ...values, countryCode: event.target.value },
										// 	setFieldValue
										// );
									}}
									error={shouldDisplayError('countryCode')}
									MenuProps={{
										PaperProps: {
											style: {
												width: '400px', // Adjust the width as needed
												marginLeft: '110px',
											},
										},
									}}
								>
									{Array.isArray(COUNTRY_CODES) && COUNTRY_CODES?.length !== 0 ? (
										COUNTRY_CODES?.map(
											(countryCodeElement: any, index: any) => (
												<MenuItem
													key={`${countryCodeElement?.name}`}
													value={countryCodeElement?.name}
												>
													{countryCodeElement?.name}
												</MenuItem>
											)
										)
									) : (
										<MenuItem value="No Data">{t('noData')}</MenuItem>
									)}
								</Select>

								<FormHelperText>
									{shouldDisplayError('countryCode') ? (
										<span
											className="MuiFormHelperText-root Mui-error"
											style={{ color: '#d32f2f' }}
										>
											{t('countryCodeRequired')}
										</span>
									) : (
										''
									)}
								</FormHelperText>
							</FormControl>
						</Grid>
						<Grid item xs={8}>
							<Typography
								variant="body2"
								className="uppercase label"
								gutterBottom
							></Typography>
							<StyledCustomTextField
								fullWidth
								name="phone"
								value={values?.phone}
								//onChange={handleOnChange('phone')}
								margin="normal"
								error={shouldDisplayError('phone')}
								helperText={shouldDisplayErrorMessage('phone')}
								onChange={(event: any) =>
									handlePhoneValidation(
										event?.target?.value,
										'phone',
										values,
										setFieldValue
									)
								}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={6}>
					<Typography variant="body2" className="uppercase label" gutterBottom>
						{t('email')}
					</Typography>
					<StyledCustomTextField
						fullWidth
						name="email"
						//type="email"
						value={values?.email || ''}
						onChange={handleOnChange('email')}
						margin="normal"
						error={submitCount > 0 && touched?.email && errors?.email ? true : false}
						helperText={shouldDisplayErrorMessage('email')}
					/>
				</Grid>
			</Grid>

			<FormControl fullWidth style={{ marginTop: '10px' }}>
				<Typography variant="body2" className="uppercase label" gutterBottom>
					{t('club')}
				</Typography>
				{isCoachPortal ? (
					<StyledCustomTextField
						fullWidth
						name="clubName"
						value={values?.clubDetail?.name || ''}
						margin="normal"
						disabled={true}
					/>
				) : (
					<Select
						name="club"
						value={values?.club ? values?.club : ''}
						onChange={handleDropdownChange('club')}
						style={{ marginTop: '15px' }}
						defaultValue=""
					>
						{Array.isArray(clubsList) && clubsList?.length !== 0 ? (
							clubsList
								?.filter((x: any) => x?.status === 'active')
								?.map((club: any) => (
									<MenuItem key={club?._id} value={club?._id}>
										<Radio checked={values?.club?.includes(club?._id)} />
										{club?.name !== '' ? club?.name : t('noName')}
									</MenuItem>
								))
						) : (
							<MenuItem value="No Data">{t('noData')}</MenuItem>
						)}
					</Select>
				)}
			</FormControl>

			<FormControl fullWidth style={{ marginTop: '20px' }}>
				<Typography variant="body2" className="uppercase label" gutterBottom>
					{t('category')}
				</Typography>
				{isCoachPortal ? (
					<StyledCustomTextField
						fullWidth
						name="categoryName"
						value={values?.categoryDetail?.name || ''}
						margin="normal"
						disabled={true}
					/>
				) : (
					<Select
						name="category"
						value={values?.category ? values?.category : ''}
						onChange={handleOnChange('category')}
						style={{ marginTop: '15px' }}
					>
						{categories?.length !== 0 ? (
							categories?.map((category: any) => (
								<MenuItem key={category?._id} value={category?._id}>
									{category?.name !== '' ? category?.name : t('noName')}
								</MenuItem>
							))
						) : (
							<MenuItem value="No Data">{t('noData')}</MenuItem>
						)}
					</Select>
				)}
			</FormControl>

			<Grid container spacing={2} style={{ marginTop: '10px' }}>
				{/* <Grid item xs={6}>
          <Typography className="uppercase label">
            {t("groupCoachingPricePerHour")}
          </Typography>
          <StyledCustomTextField
            fullWidth
            name="groupCoachingPricePerHour"
            value={values?.groupCoachingPricePerHour || ""}
            onChange={handleOnChange("groupCoachingPricePerHour")}
            margin="normal"
            placeholder={t("groupCoachingPricePerHour")}
            error={shouldDisplayError("groupCoachingPricePerHour")}
            helperText={shouldDisplayErrorMessage("groupCoachingPricePerHour")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">€</InputAdornment>
              ),
            }}
          />
        </Grid> */}
				<Grid item xs={7}>
					<Typography className="uppercase label">
						{t('PrivateCoachingPricePerHour')}
					</Typography>
					<StyledCustomTextField
						fullWidth
						name="privateCoachingPricePerHour"
						value={values?.privateCoachingPricePerHour || ''}
						onChange={handleOnChange('privateCoachingPricePerHour')}
						margin="normal"
						placeholder={t('PrivateCoachingPricePerHour')}
						error={shouldDisplayError('privateCoachingPricePerHour')}
						helperText={shouldDisplayErrorMessage('privateCoachingPricePerHour')}
						InputProps={{
							startAdornment: <InputAdornment position="start">€</InputAdornment>,
						}}
					/>
				</Grid>
			</Grid>

			<Grid item xs={12} style={{ marginTop: '10px' }}>
				<FormControl fullWidth>
					<Typography variant="body2" className="uppercase label" gutterBottom>
						{t('description')}
					</Typography>
					<TextareaAutosize
						value={values?.description}
						onChange={handleOnChange('description')}
						minRows={5}
						style={{ marginTop: '15px' }}
					/>
					<FormHelperText>
						{shouldDisplayError('description') ? (
							<span
								className="MuiFormHelperText-root Mui-error"
								style={{ color: '#d32f2f' }}
							>
								{errors?.description}
							</span>
						) : (
							''
						)}
					</FormHelperText>
				</FormControl>
			</Grid>
		</div>
	);
};

export default GeneralInformation;
