import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container } from "@mui/material";

// import GeneralInformation from "./GeneralInformation";
// import UserResponsibilities from "./UserResponsibilities";
// import PurchaseProcess from "./PurchaseProcess";
// import PremiumPlan from "./PremiumPlan";
// import Availability from "./Availability";
// import PricesPayment from "./PricesPayment";
// import Promotions from "./Promotions";
// import Errors from "./Errors";
// import PurchaseBonus from "./PurchaseBonus";
// import Cancellations from "./Cancellations";
// import DisclaimerLiability from "./DisclaimerLiability";
// import Indemnity from "./Indemnity";
// import CommunicationsNotifications from "./CommunicationsNotifications";
// import Waiver from "./Waiver";
// import Nullity from "./Nullity";
// import CompleteAgreement from "./CompleteAgreement";
// import DataProtection from "./DataProtection";
// import LegislationJurisdiction from "./LegislationJurisdiction";
// import ComplaintsClaims from "./ComplaintsClaims";

// import { TermsConditionsEN } from "app/languages/en/terms-conditions-translation";
// import { TermsConditionsES } from "app/languages/es/terms-conditions-translation";
import { LegalConditionsFR } from "app/languages/fr/legal-conditions-translation";
// import CommonTitleContent from "./CommonTitleContent";
// import MentionsLegales from "./MentionsLegales";
// import ConfidentialityAndPersonalData from "./ConfidentialityAndPersonalData";
import LegalConditionsData from "./LegalConditionsData";

const LegalConditions: React.FC = () => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { lang } = useParams<{ lang: string }>();

  const selectedLanguage: string = localStorage.getItem('accessToken') ? i18n.language || lang || "en": lang || "en";

  useEffect(() => {
    // Set the i18n language if it's not the same as the selected language
    if (selectedLanguage && i18n.language !== selectedLanguage) {
      i18n.changeLanguage(selectedLanguage);
    }

    // Navigate to a route with the selected language if needed
    // navigate(`/termsConditions/${selectedLanguage}`);
  }, [selectedLanguage, i18n, navigate]);

  // let legalConditionsContent =
  //   selectedLanguage === "es"
  //     ? TermsConditionsES
  //     : selectedLanguage === "fr"
  //     ? TermsConditionsFR
  //     : TermsConditionsEN;

  let legalConditionsContent = LegalConditionsFR;

  return (
    <>
      {" "}
      <Container maxWidth="md" sx={{ marginTop: "2rem", marginBottom: "2rem" }}>
        {/* <Typography variant="h5" sx={{ fontWeight: 500, marginBottom: "1rem", textTransfrom: 'capitalize' }}>
          {t("legalConditionsScreen.legalConditions")}
        </Typography> */}
        {/* <GeneralInformation
          title={legalConditionsContent?.generalInfo?.title}
          content={legalConditionsContent?.generalInfo?.content}
        /> */}
        <LegalConditionsData legalConditionsContent={legalConditionsContent} />
        {/* <ConfidentialityAndPersonalData
          title={legalConditionsContent?.confidentialityAndPersonalData?.title}
          content={legalConditionsContent?.confidentialityAndPersonalData?.content}
        />
        <CommonTitleContent
          title={legalConditionsContent?.whoIsTheController?.title}
          content={legalConditionsContent?.whoIsTheController?.content}
        />
        <CommonTitleContent
          title={legalConditionsContent?.howAreYourPersonal?.title}
          content={legalConditionsContent?.howAreYourPersonal?.content}
        />
        <CommonTitleContent
          title={legalConditionsContent?.whatPersonalDataIsLikelyToBeCollected?.title}
          content={legalConditionsContent?.whatPersonalDataIsLikelyToBeCollected?.content}
        />
        <CommonTitleContent
          title={legalConditionsContent?.personalDataStorageDuration?.title}
          content={legalConditionsContent?.personalDataStorageDuration?.content}
        />
        <CommonTitleContent
          title={legalConditionsContent?.thirdPartyIntervention?.title}
          content={legalConditionsContent?.thirdPartyIntervention?.content}
        />
        <CommonTitleContent
          title={legalConditionsContent?.placeOfStorageOfYourData?.title}
          content={legalConditionsContent?.placeOfStorageOfYourData?.content}
        />
        <CommonTitleContent
          title={legalConditionsContent?.yourRightsRegardingPersonalData?.title}
          content={legalConditionsContent?.yourRightsRegardingPersonalData?.content}
        />
        <CommonTitleContent
          title={legalConditionsContent?.contactResponsableConformite?.title}
          content={legalConditionsContent?.contactResponsableConformite?.content}
        /> */}

        {/* <PurchaseProcess
          title={legalConditionsContent?.purchaseProcess?.title}
          content={legalConditionsContent?.purchaseProcess?.content}
        />
        <PremiumPlan
          title={legalConditionsContent?.premiumPlan?.title}
          content={legalConditionsContent?.premiumPlan?.content}
        />
        <Availability
          title={legalConditionsContent?.availability?.title}
          content={legalConditionsContent?.availability?.content}
        />{" "}
        <PricesPayment
          title={legalConditionsContent?.pricesPayment?.title}
          content={legalConditionsContent?.pricesPayment?.content}
        />
        <Promotions
          title={legalConditionsContent?.promotions?.title}
          content={legalConditionsContent?.promotions?.content}
        />
        <PurchaseBonus
          title={legalConditionsContent?.purchaseBonus?.title}
          content={legalConditionsContent?.purchaseBonus?.content}
        />
        <Errors
          title={legalConditionsContent?.errors?.title}
          content={legalConditionsContent?.errors?.content}
        />{" "}
        <Cancellations
          title={legalConditionsContent?.cancellations?.title}
          content={legalConditionsContent?.cancellations?.content}
        />
        <DisclaimerLiability
          title={legalConditionsContent?.disclaimerLiability?.title}
          content={legalConditionsContent?.disclaimerLiability?.content}
        />
        <Indemnity
          title={legalConditionsContent?.indemnity?.title}
          content={legalConditionsContent?.indemnity?.content}
        />
        <CommunicationsNotifications
          title={legalConditionsContent?.communications?.title}
          content={legalConditionsContent?.communications?.content}
        />
        <Waiver
          title={legalConditionsContent?.waiver?.title}
          content={legalConditionsContent?.waiver?.content}
        />
        <Nullity
          title={legalConditionsContent?.nullity?.title}
          content={legalConditionsContent?.nullity?.content}
        />
        <CompleteAgreement
          title={legalConditionsContent?.completeAgreement?.title}
          content={legalConditionsContent?.completeAgreement?.content}
        />
        <DataProtection
          title={legalConditionsContent?.dataProtection?.title}
          content={legalConditionsContent?.dataProtection?.content}
        />
        <LegislationJurisdiction
          title={legalConditionsContent?.legislation?.title}
          content={legalConditionsContent?.legislation?.content}
        />
        <ComplaintsClaims
          title={legalConditionsContent?.complaintClaims?.title}
          content={legalConditionsContent?.complaintClaims?.content}
        /> */}
      </Container>
    </>
  );
};

export default LegalConditions;
