/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';
import {
	Modal,
	Box,
	Button,
	Divider,
	Paper,
	Slide,
	Grid,
	Typography,
	IconButton,
	FormHelperText,
	Stack,
	FormControl,
	Select,
	MenuItem,
	Link,
	TextField,
} from '@mui/material';
import { debounce } from 'lodash';
import { Form, Formik, FormikProps } from 'formik';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { sortBy } from 'lodash';
import { toast } from 'react-toastify';

import { useAuth } from '../../../../AuthContext';
import { RootState, AppDispatch } from '../../../redux/store';
import { defaultCreditValue } from '../../../model/organisation-model';
import { FormTextField, FormSelectField } from 'app/shared/atoms';
import { SubmitButton } from 'app/shared/molecules';
import {
	createCredit,
	updateCredit,
	fetchCreditsList,
	fetchOrganisationsList,
} from '../organisationsApiService';
import { fetchSessionPackagesList } from 'app/entities/SessionPackages/sessionPackagesApiService';
import { AddCreditValidationSchema } from 'app/shared/validations/OrganisationValidationSchema';
import { BookingPaymentType } from 'app/config/constants';
import ConfirmDialog from 'app/shared/components/ConfirmDialog';
import { fetchClubsList } from 'app/entities/Clubs/clubsApiService';
import { AntSwitch } from 'app/shared/components/BlackSwitch';
import COUNTRY_CODES from 'app/shared/data/CountryCodes.json';
import { extractDialCode } from 'app/shared/components/CountryCodeExtractor';
import {
	checkPhoneNumberValidity,
	fetchSearchUsers,
	redeemCode,
} from 'app/entities/Users/usersApiService';
import PaginatedAutocomplete from 'app/shared/molecules/PaginatedAutocomplete';
import { showToast } from 'app/shared/util/toastHelper';
import { useLocationQuery } from 'app/shared/util/useLocationQuery';

interface ModalFormProps {
	open: boolean;
	onClose: () => void;
	formData?: any;
	callback?: any;
	action?: any;
	showCorporateUserOption?: any;
	corporateId?: any;
}

const brownButtonStyle = {
	backgroundColor: '#333333',
	color: 'white', // Text color
};

const BoldTypography = styled(Typography)(({ theme }) => ({
	fontWeight: 'bold', // Set the font weight to bold
}));

const AddCreditModalForm: React.FC<ModalFormProps> = ({
	open,
	onClose,
	formData,
	callback,
	showCorporateUserOption = true,
	corporateId,
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch<AppDispatch>();
	const { user } = useAuth();
	const sessionPackages: any = useSelector((state: RootState) => state?.sessionPackages?.data);
	const isTransactionsPage = window.location.pathname === '/transactions';
	const searchParams = useLocationQuery();

	const formikRef = useRef<FormikProps<any>>(null);
	const [confirmOpen, setConfirmOpen] = useState(false);
	const [isLocked, setIsLocked] = useState(false);
	const [haveCorporateCode, setHaveCode] = useState(false);

	const [editData, setEditData] = useState<any>();
	const [organisations, setOrganisations] = useState([]);
	const [showAddUserFields, setShowAddUserFields] = useState(false);
	const [type, setType] = useState(
		isTransactionsPage ? (searchParams?.tab === '2' ? 'corporate' : 'user') : 'corporate'
	);
	const [selectedUser, setSelectedUser] = useState<any>(null);

	useEffect(() => {
		if (open) {
			dispatch(
				fetchOrganisationsList(
					{
						pageSize: 1000,
						page: 1,
					},
					(data: any) => {
						setOrganisations(data);
					}
				)
			);
			dispatch(
				fetchSessionPackagesList({
					pageSize: 1000,
					page: 1,
				})
			);
			//if(isCoachPortal) {
			dispatch(fetchClubsList({ limit: 1000, page: 1 }));
			//}
		}

		return () => {
			setOrganisations([]);
		};
	}, [open, dispatch]);

	// Set all the form data used for building the request object to the API finally
	useEffect(() => {
		if (formData?._id) {
			let newData = {
				...formData,
				paymentType: formData?.paymentType,
				packageid: formData?.packageid,
				amountToPay: parseFloat(formData?.paidAmount) + parseFloat(formData?.balanceAmount || 0),
				...(formData?.type === 'corporate' && {
					corporate: formData?.corporate,
				}),
				...(formData?.type === 'user' && {
					user: formData?.user,
				}),
			};
			setType(formData?.type);
			setEditData(newData);
		}
	}, [formData?._id]);

	/**
	 * Handle Next on button click to load the next step if second page call the api
	 *
	 * @function
	 * @returns void
	 */
	const handleSubmit = async (values?: any, actions?: any) => {
		try {
			if (values?.type === 'corporate') delete values.user;
			else delete values.corporate;

			if (values?.casaCode !== undefined) delete values.casaCode;

			if (showAddUserFields) {
				delete values.user;
			}

			if (formData?._id) {
				delete values._id;

				const response = await dispatch(updateCredit(formData?._id, { ...values }));

				if (response) {
					if (typeof callback === 'function') {
						callback();
					}
					onClose();
				}
			} else {
				const response = await dispatch(createCredit({ ...values }));
				if (response) {
					onClose();
					dispatch(
						fetchCreditsList({ page: 1, ...(corporateId && { filter_corporate: corporateId }) })
					);
					if (typeof callback === 'function' && corporateId) {
						callback();
					}
				}
			}
		} catch (error) {
			console.error('Error during submission:', error);
		} finally {
			setTimeout(() => {
				actions.setSubmitting(false);
				setIsLocked(false);
			});
		}
	};

	/**
	 * Handle close on button click
	 *
	 * @function
	 * @returns void
	 */
	const handleCloseModal = (event?: any, reason?: any) => {
		if (formikRef?.current?.dirty) {
			// Show confirmation dialog if there are unsaved changes
			setConfirmOpen(true);
		} else {
			onClose();
		}
	};

	// Handle close confirmation dialog
	const handleCancelClose = () => {
		setConfirmOpen(false);
	};

	// Handle confirm close action
	const handleConfirmClose = () => {
		setConfirmOpen(false);
		onClose();
	};

	const calculateAmount = (values: any, setFieldValue: any) => {
		let calBalanceAmount = parseFloat(values?.amountToPay) - parseFloat(values?.paidAmount || 0);

		setFieldValue('balanceAmount', calBalanceAmount ? parseFloat(calBalanceAmount?.toFixed(2)) : 0);
	};

	const handleSwitchChange = (objectKey: string, checked: boolean, setFieldValue: any) => {
		setFieldValue(`${objectKey}`, checked ? 'user' : 'corporate');
		setType(checked ? 'user' : 'corporate');
		setShowAddUserFields(false);
	};

	const handlePackageCount = async (event: any, setFieldValue?: any) => {
		setFieldValue('noOfPackages', parseInt(event?.target?.value));
	};

	const handleAddUserClick = () => {
		setShowAddUserFields(true);
	};

	const handlePhoneValidation = debounce(
		(val: any, field: string, values: any, setFieldValue: any) => {
			if (field === 'phone') setFieldValue('phone', val);

			if (values?.countryCode && val?.length >= 8) {
				const countryCode: any = extractDialCode(values?.countryCode);

				checkPhoneNumberValidity(
					{ countryCode: parseInt(countryCode), phone: val },
					(data: any) => {
						if (!data) {
							console.log('Phone validation error:', data);
							return;
						}
						// Valid phone number logic here (optional)
					}
				);
			}
		},
		100
	);

	/**
	 * Handle redeem code
	 *
	 * @function
	 * @returns void
	 */
	const verifyRedeemCode = async (values: any, setFieldValue: any) => {
		redeemCode({ userId: values?.user, code: values?.casaCode }, (data: any) => {
			if (data) {
				onClose();
			} else {
				console.error('No data returned from redeemCode');
			}
		});
	};

	const fetchUserOptions = async (inputValue: string, page: number) => {
		try {
			const response: any = await fetchSearchUsers(inputValue, page, true);

			const normalizedData = response?.data?.map((x: any) => ({
				...x,
				id: x?.user?._id || x?._id,
			}));

			return {
				...response,
				data: normalizedData || [],
			};
		} catch (error) {
			console.error('Error fetching user options:', error);
			return {
				data: [],
			};
		}
	};

	const handleUserSelect = (values: any, setFieldValue?: any) => (event: any, value: any) => {
		setSelectedUser(value);
		setFieldValue('user', value?.id || value?._id);
		setHaveCode(false);
	};

	return (
		<>
			<Modal
				open={open}
				onClose={handleCloseModal}
				closeAfterTransition
				slotProps={{
					backdrop: {
						style: {
							backgroundColor: 'rgba(0,0,0,0.5)',
						},
					},
				}}
			>
				<Slide in={open} direction="left">
					<Paper
						style={{
							position: 'absolute',
							right: '0',
							transform: 'translateY(-50%)',
							width: '35%',
							padding: '25px',
							height: '100vh',
							overflow: 'auto',
						}}
					>
						<Box>
							{/* Content of the sliding modal */}
							<BoldTypography variant="h6" style={{ marginBottom: '1rem' }}>
								{haveCorporateCode ? t('redeemCode'): formData?._id ? t('editCreditPurchase') : t('addNewCreditPurchase')}
							</BoldTypography>
							<Divider />
							<IconButton
								edge="end"
								color="inherit"
								onClick={handleCloseModal}
								aria-label="close"
								sx={{
									position: 'absolute',
									top: '15px',
									right: '24px',
									cursor: 'pointer',
								}}
							>
								<CloseIcon />
							</IconButton>
							<div>
								<Formik
									innerRef={formikRef}
									initialValues={
										formData?._id
											? editData
											: {
													...defaultCreditValue,
													type,
													...(corporateId && { corporate: corporateId }),
											  }
									}
									onSubmit={async (values, actions) => {
										if (isLocked) return; // Prevent further submissions
										setIsLocked(true);
										let newValues: any = {
											...values,
											paidAmount: values?.paidAmount
												? parseFloat(Number(values.paidAmount).toFixed(2))
												: 0,
											balanceAmount: values?.balanceAmount
												? parseFloat(Number(values.balanceAmount).toFixed(2))
												: 0,
											createdBy: user?.userId,
										};

										if (showAddUserFields && typeof values?.countryCode !== 'number') {
											let countryCode: any = extractDialCode(values?.countryCode);
											countryCode = parseInt(countryCode, 10);

											newValues.countryCode = countryCode;

											if (isNaN(countryCode)) {
												showToast('Phone validation error', 'Error', {
													position: toast.POSITION.TOP_RIGHT,
												});
												actions.setSubmitting(false);
												return; // Exit if countryCode is invalid
											}

											// Validate phone number before making the API call
											const isPhoneValid = await new Promise((resolve) =>
												checkPhoneNumberValidity(
													{ countryCode: countryCode, phone: values?.phone },
													(data: any) => {
														if (!data) {
															console.log('Phone validation error:', data);
															resolve(false);
														} else {
															resolve(true);
														}
													}
												)
											);

											if (!isPhoneValid) {
												actions.setSubmitting(false);
												return; // Exit if phone validation fails
											}
										}

										delete values.selectedSessionPackageObj;
										handleSubmit(newValues, actions);
									}}
									enableReinitialize
									validationSchema={AddCreditValidationSchema(type, showAddUserFields)}
								>
									{({ values, errors, setFieldValue, touched, isSubmitting, ...props }: any) => {
										return (
											<>
												<Form>
													<Grid container spacing={2} style={{ marginTop: '10px' }}>
														{(!isTransactionsPage || (!isTransactionsPage && showCorporateUserOption && !haveCorporateCode) && (
																<Grid item xs={12}>
																	<Grid
																		container
																		spacing={2}
																		justifyContent={'space-between'}
																		sx={{
																			marginTop: '1rem',
																			marginBottom: '1rem',
																		}}
																	>
																		<Grid
																			item
																			xs={6}
																			className="label uppercase"
																			sx={{ fontSize: '15px' }}
																		>
																			{t('doYouLikeToPurchaseFor')}
																		</Grid>
																		<Grid item xs={6}>
																			<Stack
																				direction="row"
																				spacing={1}
																				alignItems="center"
																				sx={{ float: 'right' }}
																			>
																				<Typography
																					sx={{
																						fontSize: '12px',
																					}}
																				>
																					{t('organisation')}
																				</Typography>
																				<AntSwitch
																					checked={values?.type === 'user'}
																					onChange={(e) => {
																						handleSwitchChange(
																							'type',
																							e.target.checked,
																							setFieldValue
																						);
																					}}
																					disabled={formData?._id}
																				/>
																				<Typography
																					sx={{
																						fontSize: '12px',
																					}}
																				>
																					{t('user')}
																				</Typography>
																			</Stack>
																		</Grid>
																	</Grid>
																</Grid>
															))}

														{showCorporateUserOption && (
															<>
																{values?.type === 'corporate' ? (
																	<Grid item xs={12}>
																		<Box>
																			<FormSelectField
																				label={t('corporate')}
																				name="corporate"
																				options={
																					organisations?.length > 0
																						? organisations?.map(
																								(x: any) =>
																									(x = {
																										...x,
																										label: x?.name,
																										value: x?._id,
																									})
																						  )
																						: []
																				}
																				onChangeCallback={(value: any) => {
																					setFieldValue('corporate', value);
																				}}
																				disabled={formData?._id}
																			/>
																		</Box>
																	</Grid>
																) : (
																	<Grid item xs={12}>
																		<Box>
																			<Typography
																				className="uppercase label"
																				sx={{ marginBottom: '.8rem', fontSize: '13px' }}
																			>
																				{t('user')}
																			</Typography>

																			{formData?._id ? (
																				<TextField
																					name="userDetails"
																					value={`${values?.userDetail?.firstName || ''} ${
																						values?.userDetail?.lastName || ''
																					}`}
																					disabled
																					fullWidth
																				/>
																			) : (
																				<PaginatedAutocomplete
																					handleUserSelect={handleUserSelect(values, setFieldValue)}
																					fetchOptions={fetchUserOptions}
																					selectedUser={selectedUser}
																					handleAddUserClick={handleAddUserClick}
																					t={t}
																					disabled={formData?._id || showAddUserFields}
																				/>
																			)}
																		</Box>
																		<FormHelperText>
																			{touched?.user && errors?.user ? (
																				<span
																					className="MuiFormHelperText-root Mui-error"
																					style={{
																						color: '#d32f2f',
																					}}
																				>
																					{errors?.user}
																				</span>
																			) : (
																				''
																			)}
																		</FormHelperText>
																		{!showAddUserFields && !formData?._id && (
																			<Typography>
																				<Link href="#" onClick={() => handleAddUserClick()}>
																					+ {t('AddNewuser')}
																				</Link>
																			</Typography>
																		)}
																	</Grid>
																)}
															</>
														)}

														{/* First Name and Last Name */}
														{showAddUserFields && (
															<>
																<Grid container spacing={2} style={{ marginLeft: '0px' }}>
																	<Grid item xs={6}>
																		{' '}
																		<BoldTypography variant="h6">{t('newUser')}</BoldTypography>
																	</Grid>
																	<Grid item xs={6}></Grid>
																	<Grid item xs={6}>
																		<FormTextField name="firstName" label={t('firstName')} />
																	</Grid>
																	<Grid item xs={6}>
																		<FormTextField name="lastName" label={t('lastName')} />
																	</Grid>
																</Grid>

																{/* Phone, Email, and Country Code */}
																<Grid
																	container
																	spacing={2}
																	style={{
																		marginLeft: '0px',
																		marginTop: '15px',
																	}}
																>
																	<Grid item xs={2}>
																		<FormSelectField
																			label={t('phone')}
																			name="countryCode"
																			options={COUNTRY_CODES?.map(
																				(x: any) =>
																					(x = {
																						...x,
																						label: x?.name,
																						value: x?.name,
																					})
																			)}
																			customWidth="240"
																			customMarginLeft="55"
																			onChangeCallback={(val: any) =>
																				handlePhoneValidation(
																					val,
																					'countryCode',
																					values,
																					setFieldValue
																				)
																			}
																		/>
																	</Grid>
																	<Grid item xs={4}>
																		<FormControl
																			fullWidth
																			sx={{
																				marginTop: '2.4rem',
																			}}
																		>
																			<FormTextField
																				name="phone"
																				customCallback={(val: any) =>
																					handlePhoneValidation(val, 'phone', values, setFieldValue)
																				}
																			/>
																		</FormControl>
																	</Grid>
																	<Grid item xs={6}>
																		{' '}
																		<FormControl
																			fullWidth
																			sx={{
																				marginTop: '0.3rem',
																			}}
																		>
																			<FormTextField name="email" label={t('email')} />
																		</FormControl>
																	</Grid>
																</Grid>
																<Grid
																	container
																	spacing={2}
																	style={{
																		marginLeft: '0px',
																		marginTop: '5px',
																	}}
																>
																	<Grid item xs={12}>
																		{' '}
																		<FormControl fullWidth>
																			<FormTextField
																				name="playerLevel"
																				label={t('PlayerRange')}
																				required
																				customCallback={(e: any) => {
																					const inputValue = e;
																					if (
																						inputValue === '' ||
																						/^\d+(\.\d{0,2})?$/.test(inputValue)
																					) {
																						// Update the field value
																						setFieldValue('playerLevel', inputValue);
																					}
																				}}
																			/>
																		</FormControl>
																	</Grid>
																</Grid>

																<Grid item xs={12}>
																	<Box
																		sx={{
																			cursor: 'pointer',
																			marginTop: '1rem',
																			fontSize: '14px',
																			fontWeight: 600,
																			marginLeft: '1rem',
																			marginBottom: '1rem',
																		}}
																		onClick={() => setShowAddUserFields(false)}
																	>
																		{t('cancelUser')}
																	</Box>
																</Grid>
															</>
														)}

														{!showAddUserFields && values?.user && (
															<>
																<Grid item xs={12}>
																	<Typography variant="subtitle1" gutterBottom className="label">
																		{t('doYouHaveCorporateCode')}
																	</Typography>

																	<Stack direction="row" spacing={1} alignItems="center">
																		<Typography sx={{ fontSize: '13px' }}>{t('No')}</Typography>
																		<AntSwitch
																			checked={haveCorporateCode}
																			onChange={(e: any) => {
																				setHaveCode((prevState) => !prevState);
																			}}
																		/>
																		<Typography sx={{ fontSize: '13px' }}>{t('Yes')}</Typography>
																	</Stack>
																</Grid>

																{haveCorporateCode && (
																	<Grid item xs={12}>
																		<Typography
																			gutterBottom
																			className="uppercase label"
																			sx={{ fontSize: '14px', marginTop: '1rem' }}
																		>
																			{t('redeemCode')}
																		</Typography>
																		<Box>
																			<FormTextField
																				fullWidth
																				name="casaCode"
																				autoComplete="off"
																				onChange={(e: any) => {
																					setFieldValue('casaCode', e?.target?.value);
																				}}
																				placeholder={t('enterCode')}
																				sx={{ marginTop: '15px' }}
																			/>
																		</Box>
																		<Box>
																			{values?.casaCode && (
																				<Box
																					sx={{
																						marginTop: '1rem',
																						display: 'flex',
																					}}
																				>
																					<SubmitButton
																						type="button"
																						onClick={() => {
																							verifyRedeemCode(values, setFieldValue);
																						}}
																					>
																						{t('verify')}
																					</SubmitButton>

																					<Typography align="right">
																						<Button
																							type="button"
																							onClick={() => {
																								setHaveCode(false);
																								setFieldValue('casaCode', '');
																							}}
																							sx={{
																								textDecoration: 'none',
																								textTransform: 'uppercase',
																								marginLeft: '1rem',
																							}}
																						>
																							{t('cancel')}
																						</Button>
																					</Typography>
																				</Box>
																			)}
																		</Box>
																	</Grid>
																)}
															</>
														)}

														{!haveCorporateCode && (
															<>
																<Grid item xs={12}>
																	<Box>
																		<FormSelectField
																			label={t('package')}
																			name="packageid"
																			options={
																				sessionPackages?.data?.length > 0
																					? sortBy(
																							sessionPackages?.data
																								?.filter(
																									(x: any) =>
																										(x?.status === 'active' &&
																											values?.type === 'corporate' &&
																											x?.isCorporateApplicable) ||
																										(x?.status === 'active' &&
																											values?.type === 'user' &&
																											x?.isUserApplicable)
																								)
																								?.map((x: any) => ({
																									...x,
																									label: x?.name,
																									value: x?._id,
																								})),
																							(item) => item?.name?.toLowerCase() || '' // Ensure sorting is case-insensitive
																					  )
																					: []
																			}
																			onChangeCallback={(value: any, sessionPackageObj: any) => {
																				let findSessionPackage: any = sessionPackages?.data?.find(
																					(x: any) => x?._id === value
																				);

																				setFieldValue(
																					'selectedSessionPackageObj',
																					findSessionPackage
																				);

																				setFieldValue('packageid', value);

																				if (findSessionPackage?.price && values?.noOfPackages) {
																					setFieldValue(
																						'amountToPay',
																						values?.noOfPackages * (findSessionPackage?.price || 0)
																					);
																					setFieldValue(
																						'paidAmount',
																						values?.noOfPackages * (findSessionPackage?.price || 0)
																					);

																					setFieldValue('balanceAmount', 0);
																				}
																			}}
																			disabled={formData?._id}
																		/>
																	</Box>
																</Grid>
																<Grid item xs={12}>
																	<FormTextField
																		fullWidth
																		label={t('countOfPackage')}
																		name="noOfPackages"
																		type="number"
																		value={values?.noOfPackages}
																		disabled={formData?._id}
																		onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
																			handlePackageCount(e, setFieldValue)
																		}
																		onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
																			const value = parseInt(e.target.value, 10);
																			let newValue = isNaN(value) || value <= 0 ? 1 : value;
																			setFieldValue('noOfPackages', newValue);
																			setFieldValue(
																				'amountToPay',
																				newValue * (values?.selectedSessionPackageObj?.price || 0)
																			);
																			setFieldValue(
																				'paidAmount',
																				newValue * (values?.selectedSessionPackageObj?.price || 0)
																			);
																		}}
																	/>
																</Grid>
																<Grid item xs={12}>
																	<Grid container spacing={2}>
																		<Grid item xs={6}>
																			<FormTextField
																				name="amountToPay"
																				label={t('amountToPay')}
																				fullWidth
																				customCallback={(value) => {
																					setFieldValue('amountToPay', value);
																					calculateAmount(
																						{
																							...values,
																							paidAmount: value,
																						},
																						setFieldValue
																					);
																				}}
																				//disabled={true}
																				showPriceSymbol={true}
																			/>
																		</Grid>{' '}
																		<Grid item xs={6}>
																			<FormTextField
																				name="paidAmount"
																				label={t('paidAmount')}
																				fullWidth
																				customCallback={(value) => {
																					setFieldValue('paidAmount', value);
																					calculateAmount(
																						{
																							...values,
																							paidAmount: value,
																						},
																						setFieldValue
																					);
																				}}
																				//disabled={formData?._id}
																				showPriceSymbol={true}
																			/>
																		</Grid>{' '}
																	</Grid>
																</Grid>
																<Grid item xs={12}>
																	<Grid container spacing={2}>
																		<Grid item xs={6} sx={{ marginTop: '1rem' }}>
																			<FormTextField
																				name="balanceAmount"
																				label={t('balanceAmount')}
																				fullWidth
																				showPriceSymbol={true}
																			/>
																		</Grid>

																		<Grid item xs={6}>
																			<Grid
																				item
																				xs={
																					values?.paymentType === BookingPaymentType?.GYMLIB
																						? 6
																						: 12
																				}
																			>
																				<FormControl
																					fullWidth
																					style={{
																						marginTop: '15px',
																					}}
																				>
																					<Typography
																						variant="subtitle1"
																						gutterBottom
																						className="uppercase label"
																						sx={{
																							fontSize: '12px',
																							marginBottom: '13px',
																						}}
																					>
																						{t('paymentType')}
																					</Typography>
																					<Select
																						name="paymentType"
																						value={values?.paymentType || formData?.paymentType}
																						onChange={(e: any) => {
																							setFieldValue('paymentType', e?.target?.value || '');
																						}}
																						sx={{
																							height: '50px!important',
																							'& .MuiInputBase-root': { height: '50px!important' },
																						}}
																					>
																						<MenuItem value={BookingPaymentType?.CASH}>
																							{t('cash')}
																						</MenuItem>
																						<MenuItem value={BookingPaymentType?.CARD}>
																							{t('creditCard')}
																						</MenuItem>
																						{/* Add more player options */}
																					</Select>
																				</FormControl>
																			</Grid>
																		</Grid>
																	</Grid>
																</Grid>
															</>
														)}
													</Grid>

													{!haveCorporateCode && (
														<div
															style={{
																display: 'flex',
																justifyContent: 'space-between',
															}}
														>
															<Grid container spacing={2} style={{ marginTop: '15px' }}>
																<Grid item xs={12} container justifyContent="flex-end">
																	<SubmitButton type="submit" disabled={isLocked || isSubmitting}>
																		{formData?._id ? t('saveChanges') : t('saveNewCreditPurchase')}
																	</SubmitButton>
																</Grid>
															</Grid>
														</div>
													)}
												</Form>
											</>
										);
									}}
								</Formik>
							</div>
						</Box>
					</Paper>
				</Slide>
			</Modal>

			{/* Confirmation dialog for unsaved changes */}
			<ConfirmDialog
				open={confirmOpen}
				onClose={handleCancelClose}
				onConfirm={handleConfirmClose}
			/>
		</>
	);
};

export default AddCreditModalForm;
